<template>
  <div>
    <Navbar night="true" />

    <div class="modal-add-plano">
      <div class="container-modal-plano">
        <div class="itens-modal">
          <div class="title-close">
            <h1>dados do titular</h1>
            <img class="close-modal-add" src="../assets/img/header/header-05.png" alt />
          </div>

          <div class="container-fomr-add">
            <form action>
              <div class="form-group">
                <label for="email">Nome</label>
                <input type="text" id="email" />
              </div>

              <div class="form-group">
                <label for="email">Email</label>
                <input type="text" id="email" />
              </div>

              <div class="form-group">
                <label for="email">Telefone</label>
                <input type="text" id="email" />
              </div>

              <div class="form-group">
                <label for="email">CPF</label>
                <input type="text" id="email" />
              </div>

              <div class="container-group">
                <div class="form-group">
                  <label for="email">campo 01</label>
                  <input type="text" id="email" />
                </div>

                <div class="form-group">
                  <label for="email">campo 02</label>
                  <input type="text" id="email" />
                </div>
              </div>

              <div class="btn-cadastro">
                <button>Adicionar Beneficiário</button>
                <img src="../assets/img/cadastro/cadastro-02.png" alt />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div id="duvidas">
      <section class="s-banner-duvida">
        <div class="container">
          <div class="container-text-contato">
            <h1>Tire suas dúvidas</h1>
            <p>Encontre abaixo as respostas as principais dúvidas.</p>
          </div>
        </div>
      </section>

      <section class="duvidas">
        <div class="container">
          <div class="container-duvidas"  @click="verificaClick('duvida_1')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Como posso comprar meu plano?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                Basta você seguir os passos abaixo:
                <br />-Acessar a tela inicial do nosso site e escolher sua cidade;
                <br />-Escolha o plano desejado e quantidade correta;
                <br />-Preencha as informações corretamente e pronto.
                <br />
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_2')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Como obter Atendimento?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                Basta acessar o site da
                <a
                  href="http://www.uniodonto.coop.br/encontre-um-dentista/"
                  target="_blank"
                >Uniodonto</a> os telefones e endereços dos dentistas cooperados na localidade em que você deseja o atendimento. Depois é só marcar uma consulta, levando sempre seu cartão Uniodonto e uma identidade, com foto.
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_3')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Estou viajando e fora da área de atuação da Uniodonto Poços de Caldas. Eu consigo atendimento?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Nosso plano é nacional! O atendimento é permitido e acontece em forma de “intercâmbio”.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_4')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Como encontro dentistas fora da minha cidade?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                Pelo site
                <a
                  href="http://www.uniodonto.coop.br/encontre-um-dentista/"
                  target="_blank"
                >Uniodonto</a> ou pelo aplicativo Uniodonto Poços disponível para iOS e Android.
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_5')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Posso mudar de dentista?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Sim. Por ser um direito do consumidor e por ser o sistema de “livre escolha”, pode ser cancelado um tratamento e solicitar ao novo dentista uma nova guia de atendimento e procurar o mesmo perito para que ele transcreva a sua primeira perícia para esta nova guia de atendimento.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_6')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Tenho empresa com CNPJ (PME ou PJ) e gostaria de ter um plano Uniodonto! Como posso fazer?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                Empresas com no mínimo três pessoas (podendo ser funcionários ou familiares) poderão se inscrever como plano empresarial! Entre em
                <a href @click="$router.replace({ path: '/contato'})">contato</a> conosco informando os dados da sua empresa e quantidade de pessoas. Iremos retornar assim que possível.
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_7')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Quem são as pessoas que podem ser incluídas como dependentes do plano?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                Podem ser inscritos como beneficiários dependentes as pessoas que façam parte do grupo familiar do beneficiário titular, assim entendidos:
                <br>- O cônjuge;<br>
                -   Os filhos.
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_8')">
            <div class="container-img-text">
              <div class="container-text">
                <p>O que é CNS?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>CNS é a sigla utilizada para o Cartão Nacional de Saúde. O número do CNS passou a ser informado nos cartões de identificação e no cadastro do beneficiário, tanto titular como dependente, conforme exigência da ANS – Agência Nacional de Saúde Suplementar.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_9')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Como encontro o meu número do CNS?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>
                O número do CNS pode ser consultado pelo
                <a href="https://meudigisus.saude.gov.br/pwa/onboard" target="_blank">site</a>.
              </p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_10')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Há reembolso para procedimentos não cobertos?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Não. Os atos complementares (procedimentos que não são cobertos pelo plano e que podem ser indicados por seu cirurgião-dentista) dependem de livre negociação entre você e o cirurgião-dentista que lhe prestar atendimento, sem a participação da Uniodonto no processo.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_11')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Posso fazer um plano apenas para uma terceira pessoa, como meu/minha filho(a) ou cônjuge?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Sim. Nesse caso, você poderá se tornar responsável financeiro ou estipulante desse contrato. Você terá todas as responsabilidades contratuais, mas não terá os direitos de um beneficiário do plano. Os terceiros, no caso, terão acesso a todos os benefícios e atendimentos do plano.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_12')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Em quanto tempo poderei utilizar meu plano após a contratação online?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Após a contratação online e aprovação do pagamento, seus documentos serão analisados pela Uniodonto contratada em até 48 horas úteis. Se estiver tudo certo, você receberá a aprovação do seu plano junto ao número de carteirinha diretamente no seu e-mail. Pronto! Você já é um cliente Uniodonto.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_13')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Como é feita a aprovação do meu tratamento de rotina?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Esta é uma facilidade da Comprodonto. Após a solicitação, nossa equipe irá verificar quais as necessidades de tratamento e solicitará autorização dos procedimentos via Web. Caso o beneficiário não possua guia em aberto com outro prestador ou o procedimento nunca foi realizado, o tratamento será autorizado automaticamente.</p>
            </div>
          </div>

          <div class="container-duvidas"  @click="verificaClick('duvida_14')">
            <div class="container-img-text">
              <div class="container-text">
                <p>Há limites para o número de tratamentos?</p>
              </div>

              <div class="container-img-duvidas">
                <img src="../assets/img/duvidas/duvidas-01.png" alt />
              </div>
            </div>

            <div class="duvidas-detalhes">
              <p>Você poderá realizar, sem limites, os tratamentos que possuírem indicação clínica, conforme as condições contratadas.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: "Duvidas",
  data(){
    return{
      qtd: 0,
    }
  },
  methods: {
    verificaClick(local){
      let self = this;
      let id_lead = JSON.parse(localStorage.getItem("id_lead"));
      const objEvento = {
        acao: 'CLICK',
        leads_id: id_lead,
        identificacao: local
      }
      self.$http.post("eventos_sites", objEvento)
        .then((res) => {
        }).catch((e) => {
        })
    }
  },
  mounted() {
        this.qtd = this.$store.getters.getCarrinho.length;

    let duvidas = Array.from(document.querySelectorAll(".container-duvidas"));
    duvidas.forEach(function(duvida) {
      duvida.addEventListener("click", function() {
        let height =
          duvida.querySelector(".duvidas-detalhes p").offsetHeight + 20;

        duvida.classList.toggle("active");

        if (duvida.classList.value.includes("active")) {
          duvida.querySelector(".duvidas-detalhes").style.height =
            height + "px";
        } else {
          duvida.querySelector(".duvidas-detalhes").style.height = "0px";
        }
      });
    });
  }
};
</script>