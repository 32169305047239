<template>
  <div class="cookie-bar" v-if="show">
    <p>
      Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a
      nossa <strong @click="showPrivacePolicies()">Política de Privacidade</strong> e, ao
      continuar navegando, você concorda com estas condições.
    </p>
    <button @click="acceptTerms">Concordar</button>
  </div>
</template>
<script>
export default {
  name: "cookie",
  data: () => ({
      show: true
  }),
  mounted(){
    this.show = localStorage.getItem("accept_terms") == "1" ? false : true;
  },
  methods: {
   showPrivacePolicies(){
      let self = this;
      let options = {
        title: "Políticas de Privacidade",
        okLabel: "Fechar",
        cancelLabel: ""
      };
      self.$dialogs
        .confirm(
          `<p>POL&Iacute;TICA DE PRIVACIDADE</p>
<p><br></p>
<p>A COMPRODONTO est&aacute; comprometida em resguardar a privacidade de seus</p>
<p>usu&aacute;rios. O objetivo dessa pol&iacute;tica &eacute; esclarecer quais dados e informa&ccedil;&otilde;es s&atilde;o coletadas</p>
<p>dos usu&aacute;rios atrav&eacute;s da utiliza&ccedil;&atilde;o do site da COMPRODONTO</p>
<p>(www.comprodonto.com.br), e de que forma essas informa&ccedil;&otilde;es s&atilde;o obtidas, usadas,</p>
<p>divulgadas, transferidas e/ou armazenadas. Essa pol&iacute;tica de privacidade &eacute; parte</p>
<p>integrante dos Termos de Uso do site da COMPRODONTO.</p>
<p>&Eacute; importante destacar que n&atilde;o fa&ccedil;a uso de nossos servi&ccedil;os caso n&atilde;o concorde com o</p>
<p>conte&uacute;do dessa pol&iacute;tica.</p>
<p>1. Coleta de dados e informa&ccedil;&otilde;es pessoais</p>
<p>1.1. A coleta de dados dos internautas e dos usu&aacute;rios, tais como endere&ccedil;o de IP (internet</p>
<p>protocol) ou ID de celulares, tipo de navegador, idioma, tempo de acesso e pesquisas</p>
<p>s&atilde;o automaticamente coletadas e gravadas, bem como as sugest&otilde;es, perguntas ou</p>
<p>reclama&ccedil;&otilde;es.</p>
<p>1.2. As informa&ccedil;&otilde;es pessoais ser&atilde;o coletadas para a efetiva&ccedil;&atilde;o do cadastro dos usu&aacute;rios</p>
<p>nos servidores do site da COMPRODONTO, sendo consideradas como informa&ccedil;&otilde;es</p>
<p>pessoais qualquer dado que possa ser utilizado para identificar individualmente ou</p>
<p>entrar em contato com uma pessoa em espec&iacute;fico, n&atilde;o se limitando a nome, e-mail e/ou</p>
<p>n&uacute;mero de documento.</p>
<p>1.3. O usu&aacute;rio poder&aacute; ser solicitado a fornecer suas informa&ccedil;&otilde;es pessoais a qualquer</p>
<p>momento, enquanto estiver em contato com o site da COMPRODONTO ou com os</p>
<p>Parceiros. A COMPRODONTO e os Parceiros poder&atilde;o compartilhar essas</p>
<p>informa&ccedil;&otilde;es pessoais e utiliz&aacute;-las de acordo com esta Pol&iacute;tica de Privacidade. A</p>
<p>COMPRODONTO e os Parceiros poder&atilde;o, ainda, combinar essas informa&ccedil;&otilde;es</p>
<p>pessoais com outras informa&ccedil;&otilde;es para fornecer e aperfei&ccedil;oar nossos produtos, servi&ccedil;os,</p>
<p>conte&uacute;do e publicidade.</p>
<p>2. Utiliza&ccedil;&atilde;o dos dados pessoais coletados</p>
<p>2.1. As informa&ccedil;&otilde;es pessoais ser&atilde;o mantidas sob o mais completo sigilo pela</p>
<p>COMPRODONTO, vinculando-se unicamente ao seu registro e utilizando-as</p>
<p>unicamente para:</p>
<p>a. Realizar a entrega de documenta&ccedil;&atilde;o e a cobran&ccedil;a dos planos odontol&oacute;gicos</p>
<p>contratados atrav&eacute;s do site da COMPRODONTO, incluindo os produtos e servi&ccedil;os</p>
<p>ofertados pelos Parceiros, bem como a identifica&ccedil;&atilde;o do respectivo destinat&aacute;rio;</p>
<p>b. Cumprir obriga&ccedil;&otilde;es legais de qualquer natureza;</p>
<p>c. Obter estat&iacute;sticas gen&eacute;ricas para identifica&ccedil;&atilde;o do perfil dos nossos usu&aacute;rios e</p>
<p>desenvolvimento de nossas campanhas;</p>
<p>d. Manuten&ccedil;&atilde;o de dados e a&ccedil;&otilde;es de relacionamento com o usu&aacute;rio, bem como a</p>
<p>melhoria cont&iacute;nua do servi&ccedil;o prestado pela COMPRODONTO;</p>
<p>e. Resolu&ccedil;&atilde;o de eventuais quest&otilde;es legais relacionadas ao site da</p>
<p>COMPRODONTO;</p>
<p><br></p>
<p>f. Melhoria da experi&ecirc;ncia do usu&aacute;rio no site da COMPRODONTO, por meio de</p>
<p>ofertas espec&iacute;ficas e direcionadas, al&eacute;m da pr&aacute;tica de marketing direcionado, de</p>
<p>acordo com o seu perfil;</p>
<p>g. Fornecer, manter, proteger, desenvolver e melhorar o site da COMPRODONTO e</p>
<p>sua seguran&ccedil;a;</p>
<p>h. Enviar comunicados aos usu&aacute;rios sobre ofertas, novidades ou novos servi&ccedil;os</p>
<p>pr&oacute;prios e de terceiros da COMPRODONTO;</p>
<p>i. Manter atualizados os cadastros dos usu&aacute;rios para fins de contato telef&ocirc;nico, por</p>
<p>correio eletr&ocirc;nico, via mala direta, SMS ou outros meios de comunica&ccedil;&atilde;o;</p>
<p>j. Aperfei&ccedil;oar a usabilidade e a experi&ecirc;ncia interativa durante a navega&ccedil;&atilde;o do usu&aacute;rio</p>
<p>no Portal;</p>
<p>k. Elaborar estat&iacute;sticas gerais, sem que haja identifica&ccedil;&atilde;o dos usu&aacute;rios;</p>
<p>l. Garantir a seguran&ccedil;a dos Internautas;</p>
<p>m. Responder a suas eventuais d&uacute;vidas ou solicita&ccedil;&otilde;es;</p>
<p>n. Armazenar as prefer&ecirc;ncias dos usu&aacute;rios;</p>
<p>o. Constituir, defender ou exercer regularmente direitos em &acirc;mbito judicial ou</p>
<p>administrativo;</p>
<p>p. Realizar pesquisas e campanhas de comunica&ccedil;&atilde;o e marketing de relacionamento;</p>
<p>q. Prestar todos os servi&ccedil;os ofertados em seu site, nas condi&ccedil;&otilde;es previstas nos Termos</p>
<p>e Condi&ccedil;&otilde;es de Uso do site da COMPRODONTO.</p>
<p>2.2. As informa&ccedil;&otilde;es solicitadas aos usu&aacute;rios s&atilde;o necess&aacute;rias para melhorar e</p>
<p>personalizar a experi&ecirc;ncia de compra junto ao site da COMPRODONTO. Essas</p>
<p>informa&ccedil;&otilde;es n&atilde;o ser&atilde;o utilizadas al&eacute;m das hip&oacute;teses previstas neste documento e</p>
<p>qualquer uso estar&aacute; sempre vinculado com as diretrizes tra&ccedil;adas pela</p>
<p>COMPRODONTO nesta Pol&iacute;tica de Privacidade.</p>
<p>2.3. O usu&aacute;rio garante a veracidade e exatid&atilde;o das informa&ccedil;&otilde;es e dados que fornecer ao</p>
<p>site da COMPRODONTO, assumindo a correspondente responsabilidade pela sua</p>
<p>inexatid&atilde;o, bem como se compromete a mant&ecirc;-los devidamente atualizadas. O site da</p>
<p>COMPRODONTO n&atilde;o assume qualquer responsabilidade em caso de danos causados</p>
<p>pela inexatid&atilde;o dos dados informados.</p>
<p>2.4. O usu&aacute;rio que se cadastrar no site da COMPRODONTO utilizando dados falsos</p>
<p>ou de terceiros poder&aacute; incorrer nos crimes de falsidade ideol&oacute;gica, estelionato ou outros</p>
<p>delitos mais graves, podendo responder civil ou criminalmente por tal conduta, nos</p>
<p>termos da lei.</p>
<p>2.5. O usu&aacute;rio poder&aacute;, a qualquer momento, solicitar altera&ccedil;&atilde;o, retifica&ccedil;&atilde;o e/ou exclus&atilde;o</p>
<p>de seus dados, bastando para tanto, utilizar sua Conta de Acesso no site da</p>
<p>COMPRODONTO ou atrav&eacute;s dos canais de contatos informados nessa Pol&iacute;tica de</p>
<p>Privacidade.</p>
<p>2.6. A COMPRODONTO poder&aacute; compartilhar com terceiros as informa&ccedil;&otilde;es pessoais</p>
<p>e informa&ccedil;&otilde;es de acesso para presta&ccedil;&atilde;o dos servi&ccedil;os de Marketplace da</p>
<p>COMPRODONTO. Para tanto, a COMPRODONTO compartilhar&aacute; as informa&ccedil;&otilde;es</p>
<p>de seus usu&aacute;rios com Parceiros na medida necess&aacute;ria para que estes terceiros possam</p>
<p>cumprir integralmente com suas obriga&ccedil;&otilde;es decorrentes de Lei e dos Termos e</p>
<p>Condi&ccedil;&otilde;es de Uso do site da COMPRODONTO.</p>
<p>2.7. O usu&aacute;rio tem ci&ecirc;ncia e concorda que para fins de presta&ccedil;&atilde;o de servi&ccedil;os e entrega</p>
<p>dos produtos e servi&ccedil;os ofertados pelos Parceiros e/ou pelo site da</p>
<p>COMPRODONTO, pode ser necess&aacute;ria a contrata&ccedil;&atilde;o de terceiros, que ter&atilde;o acesso &agrave;s</p>
<p>informa&ccedil;&otilde;es e dados do usu&aacute;rio exclusivamente para os fins necess&aacute;rios.</p>
<p><br></p>
<p>2.8. A COMPRODONTO poder&aacute; tamb&eacute;m compartilhar com terceiros as informa&ccedil;&otilde;es</p>
<p>pessoais e informa&ccedil;&otilde;es de acesso dos usu&aacute;rios para presta&ccedil;&atilde;o dos servi&ccedil;os oferecidos</p>
<p>pela COMPRODONTO.</p>
<p>2.9. A COMPRODONTO tamb&eacute;m poder&aacute; compartilhar com terceiros as informa&ccedil;&otilde;es</p>
<p>pessoais e informa&ccedil;&otilde;es de acesso para cumprir qualquer lei, regulamenta&ccedil;&atilde;o,</p>
<p>determina&ccedil;&atilde;o judicial, solicita&ccedil;&atilde;o governamental, ou para detectar, impedir ou abordar</p>
<p>de qualquer forma quest&otilde;es t&eacute;cnicas, de fraude ou de seguran&ccedil;a, proteger os direitos, as</p>
<p>propriedades ou a seguran&ccedil;a da COMPRODONTO, do site da COMPRODONTO,</p>
<p>seus usu&aacute;rios, parceiros ou quaisquer terceiros.</p>
<p>2.9.1. Nesse sentido, a COMPRODONTO informa que est&aacute; legalmente obrigado a</p>
<p>divulgar os dados cadastrais que informem qualifica&ccedil;&atilde;o pessoal, filia&ccedil;&atilde;o e endere&ccedil;o &agrave;s</p>
<p>autoridades administrativas legalmente habilitadas, tais como os Delegados da Pol&iacute;cia</p>
<p>Civil e o Minist&eacute;rio P&uacute;blico, nos termos do artigo 10, &sect; 3&ordm;, da Lei 12.965/14.</p>
<p>3. Prote&ccedil;&atilde;o e privacidade das informa&ccedil;&otilde;es coletadas</p>
<p>3.1. A COMPRODONTO far&aacute; todo o poss&iacute;vel para manter a privacidade das</p>
<p>informa&ccedil;&otilde;es pessoais e informa&ccedil;&otilde;es de acesso armazenados em seus servidores,</p>
<p>comprometendo-se a utilizar tecnologia que seja suficientemente adequada para a</p>
<p>prote&ccedil;&atilde;o de tais dados, procurando manter o ambiente seguro, com uso de ferramentas</p>
<p>apropriadas e controles eficientes de seguran&ccedil;a das informa&ccedil;&otilde;es coletadas, sempre</p>
<p>observando o estado da t&eacute;cnica dispon&iacute;vel. Contudo, considerando que nenhum sistema</p>
<p>de seguran&ccedil;a &eacute; absolutamente seguro, COMPRODONTO se exime de quaisquer</p>
<p>responsabilidades por eventuais danos e/ou preju&iacute;zos decorrentes de invas&otilde;es no site da</p>
<p>COMPRODONTO, em seus servidores e demais falhas relacionadas, salvo se houver</p>
<p>dolo ou culpa da COMPRODONTO.</p>
<p>3.2. Os profissionais da COMPRODONTO t&ecirc;m conhecimento da presente Pol&iacute;tica de</p>
<p>Privacidade e apenas o pessoal qualificado e autorizado tem permiss&atilde;o para acessar as</p>
<p>informa&ccedil;&otilde;es coletadas pela COMPRODONTO, sob pena de sofrerem san&ccedil;&atilde;o</p>
<p>disciplinar em caso de viola&ccedil;&atilde;o das normas aqui previstas.</p>
<p>3.3. As empresas parceiras e prestadores de servi&ccedil;os da COMPRODONTO tem ci&ecirc;ncia</p>
<p>e est&atilde;o comprometidas em respeitar a prote&ccedil;&atilde;o e privacidade de dados assegurados</p>
<p>nesta pol&iacute;tica, quando houver necessidade de compartilhamento destes dados, nos</p>
<p>termos desta pol&iacute;tica.</p>
<p>3.4. O usu&aacute;rio reconhece e concorda que a COMPRODONTO pode disponibilizar</p>
<p>links para outros sites que, por sua vez, podem coletar informa&ccedil;&otilde;es pessoalmente</p>
<p>identific&aacute;veis do usu&aacute;rio. O usu&aacute;rio reconhece e concorda que a COMPRODONTO</p>
<p>n&atilde;o &eacute; respons&aacute;vel pelas pr&aacute;ticas de privacidade de outros sites; e &eacute; recomendado ao</p>
<p>Internauta ler atentamente as declara&ccedil;&otilde;es de privacidade de todo e qualquer site que</p>
<p>colete informa&ccedil;&otilde;es pessoalmente identific&aacute;veis. Esta Pol&iacute;tica de Privacidade se aplica</p>
<p>somente &agrave;s informa&ccedil;&otilde;es coletadas e processadas pela COMPRODONTO e pelo site da</p>
<p>COMPRODONTO.</p>
<p>3.5. A COMPRODONTO declara que respeita toda e qualquer legisla&ccedil;&atilde;o vigente sobre</p>
<p>prote&ccedil;&atilde;o de dados e privacidade dos nossos usu&aacute;rios</p>
<p>3.6. A COMPRODONTO possui sistema moderno de seguran&ccedil;a de decodifica&ccedil;&atilde;o</p>
<p>atrav&eacute;s de criptografia devidamente validado pelas autoridades certificadoras e tamb&eacute;m</p>
<p>trabalha com um firewall com o objetivo de impedir o acesso a seus servidores por</p>
<p>pessoas n&atilde;o autorizadas.</p>
<p>4. Envio de comunicados</p>
<p><br></p>
<p>4.1. A seu exclusivo crit&eacute;rio, a COMPRODONTO poder&aacute; enviar comunicados e</p>
<p>mensagens publicit&aacute;rias diariamente aos usu&aacute;rios cadastrados no site da</p>
<p>COMPRODONTO, ou Internautas em geral, fazendo uso de todos os tipos de</p>
<p>tecnologias e meios de comunica&ccedil;&atilde;o dispon&iacute;veis, seja por e-mail, SMS, MMS, mala-</p>
<p>direta ou outros.</p>
<p>4.2. Os comunicados, newsletters, avisos de ofertas e mensagens publicit&aacute;rias enviadas</p>
<p>por e-mail trar&atilde;o, obrigatoriamente, op&ccedil;&atilde;o de cancelamento (opt-out) do envio daquele</p>
<p>tipo de mensagem por parte do site da COMPRODONTO. O cancelamento do envio</p>
<p>tamb&eacute;m pode ser solicitado pelos canais de atendimento dispon&iacute;veis no site da</p>
<p>COMPRODONTO.</p>
<p>4.2.1. A COMPRODONTO se esfor&ccedil;ar&aacute; para atender a solicita&ccedil;&atilde;o no menor tempo</p>
<p>poss&iacute;vel. No entanto, tendo em vista o procedimento envolve atualiza&ccedil;&atilde;o das bases de</p>
<p>dados da COMPRODONTO e eventualmente de empresas terceiras, o usu&aacute;rio</p>
<p>compreende que a solicita&ccedil;&atilde;o poder&aacute; demorar alguns dias at&eacute; ser concretizada.</p>
<p>4.3. O processo de cancelamento de envio &eacute; vinculado ao e-mail do usu&aacute;rio. Portanto</p>
<p>usu&aacute;rios que possuam mais de um e-mail cadastrado poder&atilde;o continuar a receber os</p>
<p>comunicados ou as mensagens publicit&aacute;rias no e-mail que n&atilde;o foi descadastrado.</p>
<p>4.4. Os servi&ccedil;os de envio de e-mails podem ser realizados pela COMPRODONTO ou</p>
<p>por empresa contratada, que utilizar&aacute; seus pr&oacute;prios servidores para realizar o envio.</p>
<p>4.5. A empresa contratada n&atilde;o armazena nem utiliza de nenhuma forma os e-mails de</p>
<p>cadastro do site da COMPRODONTO para qualquer outro fim que n&atilde;o o envio das</p>
<p>mensagens, de acordo com as prefer&ecirc;ncias de cada usu&aacute;rio e as disposi&ccedil;&otilde;es previstas na</p>
<p>presente Pol&iacute;tica de Privacidade e nos Termos e Condi&ccedil;&otilde;es de Uso do site da</p>
<p>COMPRODONTO.</p>
<p>5. Cookies e informa&ccedil;&otilde;es sobre localiza&ccedil;&atilde;o</p>
<p>5.1. Ao visitar o site da COMPRODONTO, podemos enviar cookies para seu</p>
<p>computador objetivando facilitar nosso site ou dar acesso a recursos adicionais e</p>
<p>acompanhamento de atividade de marketing. Os cookies nos permitem coletarmos suas</p>
<p>atividades online, tais como a visita&ccedil;&atilde;o de p&aacute;ginas virtuais, sendo de responsabilidade</p>
<p>dos usu&aacute;rios desativarem os cookies.</p>
<p>5.2. Ao come&ccedil;ar a utilizar o site da COMPRODONTO, podemos pedir sua autoriza&ccedil;&atilde;o</p>
<p>para ter acesso a sua localiza&ccedil;&atilde;o, que ser&aacute; obtida pelo site em caso de anu&ecirc;ncia.</p>
<p>Usaremos essas informa&ccedil;&otilde;es para poder tornar as funcionalidades de acordo com a sua</p>
<p>localiza&ccedil;&atilde;o, informando sobre eventuais informa&ccedil;&otilde;es sobre produtos e/ou servi&ccedil;os</p>
<p>pr&oacute;ximos &agrave; sua localiza&ccedil;&atilde;o. Podemos armazenar esses dados, por&eacute;m n&atilde;o ser&aacute; associado</p>
<p>a sua pessoa, permanecendo o usu&aacute;rio an&ocirc;nimo.</p>
<p>6. Modifica&ccedil;&otilde;es dessa Pol&iacute;tica de Privacidade</p>
<p>6.1. A presente Pol&iacute;tica de Privacidade est&aacute; sujeita a constante melhoria e</p>
<p>aprimoramento para garantir mais transpar&ecirc;ncia e seguran&ccedil;a aos Internautas. Assim, a</p>
<p>COMPRODONTO se reserva o direito de modificar a qualquer momento, de forma</p>
<p>unilateral, o presente documento. A nova Pol&iacute;tica de Privacidade entrar&aacute; em vigor ap&oacute;s</p>
<p>10 (dez) dias contados de sua publica&ccedil;&atilde;o no site da COMPRODONTO.</p>
<p>6.1.1. A COMPRODONTO se compromete a comunicar os usu&aacute;rios sobre eventuais</p>
<p>altera&ccedil;&otilde;es dessa Pol&iacute;tica de Privacidade atrav&eacute;s do e-mail cadastrado no site da</p>
<p><br></p>
<p>COMPRODONTO, sendo que a vig&ecirc;ncia da nova pol&iacute;tica respeitar&aacute; o prazo previsto</p>
<p>no item 6.1, independente da data de recebimento do e-mail pelo usu&aacute;rio.</p>
<p>6.2. Ao continuar acessando o site da COMPRODONTO ap&oacute;s a entrada em vigor das</p>
<p>modifica&ccedil;&otilde;es, o usu&aacute;rio automaticamente concorda e aceita o teor das modifica&ccedil;&otilde;es</p>
<p>realizadas. Portanto, entende que deve verificar regularmente eventuais mudan&ccedil;as ao</p>
<p>presente documento.</p>
<p>7. Lei aplic&aacute;vel</p>
<p>7.1. A presente Pol&iacute;tica de Privacidade &eacute; regida e interpretada segundo as leis da</p>
<p>Rep&uacute;blica Federativa do Brasil.</p>
<p>8. D&uacute;vidas</p>
<p>8.1. Qualquer d&uacute;vida ou eventuais problemas quanto a esta pol&iacute;tica de privacidade</p>
<p>dever&atilde;o ser encaminhadas diretamente em campo adequado dispon&iacute;vel na p&aacute;gina</p>
<p>eletr&ocirc;nica www.comprodonto.com.br, atrav&eacute;s do e-mail: contato@comprodonto.com.br</p>
<p>que ser&atilde;o respondidas de maneira objetiva a tentar sanar qualquer quest&atilde;o de</p>
<p>usabilidade.</p>
<p>8.2. Os Parceiros possuem canais e hor&aacute;rios de atendimento pr&oacute;prios que s&atilde;o</p>
<p>divulgados aos usu&aacute;rios antes da finaliza&ccedil;&atilde;o da aquisi&ccedil;&atilde;o do produto ou servi&ccedil;o.</p>
<p>Pol&iacute;tica de Privacidade elaborada em 02 de janeiro de 2019.</p>`,
          options
        )
        .then(() => {
        });
    },
    acceptTerms(){
        localStorage.setItem("accept_terms", "1");
        this.show = false
    },
  },
};
</script>
<style>
.cookie-bar {
  height: 12vh;
  background-color: #ffffff;
  position: fixed;
  width: 100vw;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cookie-bar p {
  font-size: 12px;
  font-family: "Poppins";
  color: black;
}

.cookie-bar p strong {
  cursor: pointer;
}

.cookie-bar button {
  border: none;
  font-size: 12px;
  font-weight: bold;
  font-family: "Poppins";
  color: white;
  margin: 0px 20px;
  padding: 10px 20px;
  outline: 0;
  background-color: #e91e63;
}
</style>