<template>
  <div id="app">
    <Modal />
    <router-view></router-view>
  </div>
</template>

<script>
require('./assets/css/font-awesome/css/font-awesome.min.css')
import Modal from './components/modal'
export default {
  components:{
    Modal,
  },
  name: 'app',
  created() {
    let planos = JSON.parse(localStorage.getItem('planos'));
    if(planos)
      this.$store.commit("setCarrinho", planos);
  },
  destroyed() {
    let planos = this.$store.getters.getCarrinho
    if(planos) {
      localStorage.setItem("planos", [JSON.stringify(planos)]);
    } else {
      localStorage.setItem("planos", [JSON.stringify([])]);
    }
  }
}
</script>

<style>

</style>
