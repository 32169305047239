<template>
  <div>
    <Modal :active="ActiveModal ? true : false" @state="changeStateModal" />
    <Carrinho :active="ActiveCarrinho ? true : false" @state="changeStateCarrinho" @atualizaTotal="changeTotal" />

    <header v-bind:class="night ? 'color': ''">
      <div class="container">
        <div class="comproodonto-header">
          <img @click="$router.push({ path: '/' })" src="../assets/img/header/header-01.png" alt />
        </div>

        <div class="social-header">
          <a
            href="https://api.whatsapp.com/send?phone=5535933007935&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20odontologicos%20prestados."
            target="_blank"
          >
            <img src="../assets/img/footer/footer-03.png" alt />
          </a>
          <a href="https://www.facebook.com/Comprodonto-2276895712632985/" target="_blank">
            <img src="../assets/img/footer/footer-04.png" alt />
          </a>
          <a href="https://www.instagram.com/comprodontooficial/" target="_blank">
            <img src="../assets/img/footer/footer-05.png" alt />
          </a>
        </div>

        <div class="menus cursor-pointer">
          <div class="user" @click="gotoProfile">
            <img src="../assets/img/header/header-02.png" alt />
          </div>

          <div class="loja cursor-pointer" @click="toggleCarrinho">
            <img src="../assets/img/header/header-03.png" alt />
            <span class="total-card">{{ total ? total : qtdTotal }}</span>
          </div>

          <div @click="toggleModal" class="toggle-mobile">
            <span></span>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>

import myFunction from '../assets/js/my_functions'

export default {
  props: ["night", "total"],
  mixins: [myFunction],
  name: "Navbar",

  data() {
    return {
      ActiveModal: false,
      ActiveCarrinho: false,
    };
  },
  computed: {
    qtdTotal : function() {

      let quantidade = 0;
      let carrinho = 0;

      if(this.$store.getters.getCarrinho) {
        carrinho = this.$store.getters.getCarrinho
      }

      for(let item of carrinho) {
        quantidade += item.qtd
      }

      return quantidade;
    }
  },
  methods: {
    verificaClick(local){
      let self = this;
      let id_lead = JSON.parse(localStorage.getItem("id_lead"));
      const objEvento = {
        acao: 'CLICK',
        leads_id: id_lead,
        identificacao: local
      }
      self.$http.post("eventos_sites", objEvento)
        .then((res) => {
        }).catch((e) => {
        })
    },
    gotoProfile() {
      let self = this;
      if (!localStorage.getItem("t")) {
        self.$router.push({ path: "/login" });
      }else{
        self.$router.push({ path: "/minha-area" });
      }
    },
    toggleModal() {
      this.ActiveModal = !this.ActiveModal;
      this.verificaClick('menu_lateral');
    },
    changeTotal(){
      this.qtdTotal = this.$store.getters.getCarrinho.length;
    },
    toggleCarrinho() {
      this.ActiveCarrinho = !this.ActiveCarrinho;
    },
    changeStateModal() {
      this.ActiveModal = false;
    },
    changeStateCarrinho() {
      this.ActiveCarrinho = false;
    }
  }
};
</script>