<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <Navbar night="true" />

    <div v-if="toggle_alterar_cartao" class="modal-add-plano active">
      <div class="container-modal-plano">
        <div class="itens-modal">
          <div class="title-close">
            <h1>Alterar Dados do Cartão</h1>
            <img class="close-modal-add" @click="() => toggle_alterar_cartao = false" src="../assets/img/header/header-05.png" alt />
          </div>
          <div class="container-fomr-add">
            <form action="#">

              <div class="row">
                <div class="col-md-12 mb-5">
                  <div class="form-group">
                    <label>Nº do Cartão</label>
                    <input 
                      v-mask="'#### #### #### ####'" 
                      v-model="cartao_temp.cardNumber" 
                      inputmode="numeric"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Nome do Titular Impresso no cartão</label>
                    <input v-model="cartao_temp.holder" type="text"/>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Validade</label>
                    <input v-mask="['##/##']" v-model="cartao_temp.expirationDate"/>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>Codigo de Segurança</label>
                    <input type="tel" v-model="cartao_temp.securityCode" v-mask="'###'"/>
                  </div>
                </div>
              </div>

              <div class="btn-cadastro">
                <button @click.prevent="alteraCartao">Salvar Dados</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="toggle_alterar_senha" class="modal-add-plano active">
      <div class="container-modal-plano">
        <div class="itens-modal">
          <div class="title-close">
            <h1>Alterar Senha</h1>
            <img class="close-modal-add" @click="() => toggle_alterar_senha = false" src="../assets/img/header/header-05.png" alt />
          </div>
          <div class="container-fomr-add">
            <form action="#">

              <div class="form-group">
                <label>Digite a nova senha</label>
                <input type="password" v-model="nova_senha"/>
              </div>

              <div class="form-group">
                <label>Confirme a nova senha</label>
                <input type="password" v-model="confirma_nova_senha"/>
              </div>

              <div class="btn-cadastro" @click="editar_senha">
                <button>Cadastrar nova Senha</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-if="toggle_edicao" class="modal-add-plano active"> 
      <div class="container-modal-plano">
        <div class="itens-modal">
          <div class="title-close">
            <h1>dados do titular</h1>
            <img class="close-modal-add" @click="() => toggle_edicao = false" src="../assets/img/header/header-05.png" alt />
          </div>
          <div class="container-fomr-add">
            <form action="#">
              <div class="form-group">
                
                <label>Telefone</label>
                <input type="tel" v-mask="['(##) #####-####', '(##) ####-####']" v-model="novo_telefone" />
              </div>

              <div class="form-group">
                <label>CEP</label>
                <input type="tel" @change="getCep" v-mask="['#####-###']" v-model="endereco.cep" />
              </div>

              <div class="form-group row">

                <div class="col-md-6 margin-input">
                  <label>Rua</label>
                  <input readonly="true" type="text" v-model="endereco.rua" />
                </div>
                <div class="col-md-6 margin-mobile">
                  <label>Bairro</label>
                  <input type="text" readonly="true" v-model="endereco.bairro" />
                </div>

              </div>

              <div class="form-group row">

                <div class="col-md-6">
                  <label>Cidade</label>
                  <input readonly="true" type="text" v-model="endereco.cidade" />
                </div>

                <div class="col-md-6 margin-mobile">
                  <label>Estado</label>
                  <input type="text" readonly="true" v-model="endereco.estado" />
                </div>

              </div>

              <div class="form-group row">

                <div class="col-md-6">
                  <label>Nº Endereco</label>
                  <input type="text" v-model="endereco.numero" />
                </div>
                <div class="col-md-6 margin-mobile">
                  <label>Complemento</label>
                  <input type="text" v-model="endereco.complemento" />
                </div>
                
              </div>

              <div class="btn-cadastro" @click="editar_info">
                <button>Editar Informações</button>
              </div>

            </form>

          </div>
        </div>
      </div>
    </div>

    <div id="minha-area">
      <section class="minha-area">
        <div class="container">
          <!-- Menu-Mobile procurar forma de substituir dps -->
          <div class="menu-mobile"> 
            <p>
              <img src="../assets/img/area/area-01.png">
                olá
              <strong class="ml"> {{user.name}} {{user.last_name}} </strong>
            </p>
            <div class="target-plano" @click="dropMenu()">
              <p>{{item_ativo}}</p>
              <img src="../assets/img/area/area-06.png" alt />
            </div>
          </div>

          <div class="menu-area">
              <div class="nome-cli">
                <img src="../assets/img/area/area-01.png" alt />
                <h3>olá {{user.name}} {{user.last_name}}</h3>
              </div>
            <div class="list-menu" id="menu">
              <ul>
                <li :class="active_menu == 1 ? 'active' : ''" data-id="plano">
                  <p @click=" active_menu = 1" >Meus Planos</p>
                </li>
                                
                <li :class="active_menu == 2 ? 'active' : ''" data-id="dados">
                  <p @click="active_menu = 2" >Meus dados</p>
                </li>

                <li v-if="!isBoleto" :class="active_menu == 3 ? 'active' : ''" data-id="faturas">
                  <p @click="active_menu = 3" >Minhas Faturas</p>
                </li>

                <li @click="active_menu = 4" :class="active_menu == 4 ? 'active' : ''">
                  <p @click="logout()">Sair</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="container-menu-itens">
            <div v-if="active_menu == 1" id="plano" :class="active_menu == 1 ? 'meus-planos itens-menu active' : 'meus-planos itens-menu'">
            <h1>Meus Planos</h1>
              <div class="container-card" v-if="dependentes">
                <div class="card-plano"
                @click="openDetail(plano.id, index)"
                v-for="(plano, index) in dependentes"
                :key="index">
                  <div class="container-img-text">
                    <div class="img-card">
                      <img :src="plano.foto_plano" alt="Imagem do plano" />
                    </div>
                    <div class="text-card">
                      <h3>Plano</h3>
                        <h2>{{plano.name}}</h2>
                        <span v-html="plano.description"></span>
                        <div :class="situacao == 'Ativo' ?  'status' : 'status plano-desativado'">
                          <p>{{situacao}}</p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="boletos-minha-area" v-if="dependentes && isBoleto && situacao == 'Pendente'">
                <h1>Boletos Pendentes</h1>
                <div class="botoes-boleto">
                  <button @click="showBoleto" class="btn">Ver Boleto</button>
                  <button @click="gera2via" class="ml-3 btn">Gerar 2º via boleto</button>
                </div>
              </div>

              <div class="empty-store" v-if="!dependentes">
                <img src="../assets/img/area/shopping-cart.svg" />
                <p>Você não possui nenhum plano.</p>
              </div>
            </div>

            <div id="dados" :class="active_menu == 2 ? 'meus-dados itens-menu active' : 'meus-dados itens-menu'" v-if="active_menu == 2">
              <h1>Meus Dados</h1>
              <div class="container-card-dados">
                <div class="card-dados">
                  <div class="container-dados">
                    <div class="dados-titular">
                      <h1>
                        <img src="../assets/img/area/area-04.png" alt /> dados do titular
                      </h1>
                      <p>{{user.name}} {{user.last_name}}</p>
                      <p>{{user.email}}</p>
                      <p>CPF - {{cpf}}</p>
                      <p>RG - {{info_user.rg}}</p>
                      <p>+{{telefone.country_code}} ({{telefone.area_code}}) {{telefone.number}}</p>
                    </div>

                    <div class="dados-endereco">
                      <h1>Endereço do Titular</h1>
                      <p>{{endereco.rua}}</p>
                      <p>Número: {{endereco.numero}}</p>
                      <p>{{endereco.bairro}}</p>
                      <p>{{endereco.cidade}} - {{endereco.estado}}</p>
                      <p>NACIONALIDADE - {{info_user.country}}</p>
                      <p>CEP - {{endereco.cep}}</p>
                    </div>
                    <div id="botoes-alteracao">
                      <button id="alterar" @click="() => toggle_alterar_senha = !toggle_alterar_senha" class="mr-2">Alterar Senha</button>
                      <button id="alterar" @click="() => toggle_edicao = !toggle_edicao" href>alterar</button>
                    </div>
                  </div>
                </div>

                <div class="card-cobranca" v-if="cartao.numbers">
                  <div class="container-dados">
                    <h1>Dados da Cobrança</h1>
                    <div class="cartao-credito">
                      <div class="cartao-img">
                        <img src="../assets/img/area/area-05.png" alt />
                      </div>

                      <div class="cartao-cred">
                        <h3>{{cartao.bandeira}}</h3>
                        <p>{{user.name}} {{user.last_name}}</p>
                        <span>**** **** **** {{cartao.numbers}}</span>
                      </div>
                    </div>

                    <div class="plano-cobranca">
                      <p>Plano anual, cobranças mensais</p>
                      <p> O pagamento está programado para todo
                        <strong>dia {{cartao.next}}</strong>
                      </p>
                    </div>
                    <div class="container-alterar-cartao">
                      <button 
                        @click="podeEditarCartao ? toggle_alterar_cartao = !toggle_alterar_cartao 
                        : showAlert('Atenção', 
                          `Para alterar seu cartão entre em contato com a uniodonto pelo telefone
                           ${subscriptions[0].plan.provider.telefone} 
                           ou pelo e-mail ${subscriptions[0].plan.provider.email}`)" 
                        class="btn-alterar-cartao mt-2">Alterar Cartão
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="faturas" :class="active_menu == 3 ? 'meus-dados itens-menu active' : 'meus-dados itens-menu'" v-if="active_menu == 3 && !isBoleto">
              <div v-if="tem_fatura">
                <h1>Minhas Faturas</h1>
                <md-table>
                  <md-table-row>
                    <md-table-head>Data</md-table-head>
                    <md-table-head>Status</md-table-head>
                    <md-table-head>Valor</md-table-head>
                  </md-table-row>
                  <md-table-row :key="transacao.id" v-for="transacao in transacoes">
                    <md-table-cell>{{ padronizaData(transacao.created_at) }}</md-table-cell>
                    <md-table-cell>{{transacao.status_description}}</md-table-cell>
                    <md-table-cell>{{ transacao.amount | money }}</md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
                <div class="empty-store" v-else>
                    <img src="../assets/img/area/file.svg" />
                    <p>Você não possui nenhuma fatura.</p>
                </div>
              </div>

              <div id="detalhe-plano" v-if="active_menu == 5"
              :class="active_menu == 5 ? 'detalhe-plano-area itens-menu active': 'detalhe-plano-area itens-menu'">
                <h1>Detalhes do Plano</h1>
                <div class="container-card-detalhe">
                  <div class="container-detalhe-left">
                    <div class="card-detalhe-plano">
                      <div class="img-card-plano">
                        <img :src="foto_plano" alt />
                      </div>
                      <div class="text-card-plano">
                        <h1>{{temp_plan.name}}</h1>
                        <div :class="situacao == 'Ativo' ? 'status' : 'status plano-desativado'">
                          <p>{{situacao}}</p>
                        </div>
                        <span>{{`Data de Ativação: ${padronizaData(data_ativacao)}`}}</span>
                        <span><a href="#" @click="cancelarPlano">Cancelar o plano</a></span>
                      </div>

                      <div class="add-beneficio-mobile">
                        <div class="beneficio-img-text">
                          <img src="../assets/img/area/area-03.png" alt />
                          <p>beneficiario do plano</p>
                        </div>
                        <div class="text-beneficios">
                          <p>{{temp_desc.nome_dependente}}</p>
                          <p v-if="temp_desc.cpf">CPF - {{ (temp_desc.cpf)}}</p>
                          <p v-if="temp_desc.birth_date">
                            Idade - {{ new Date().getFullYear() - parseInt(temp_desc.birth_date)}} anos
                          </p>
                          <p>Parentesco - {{temp_desc.parentesco}}</p>
                          <p><a href="#" @click="cancelarPlano" style="color: '#cb3f53'">Cancelar Plano</a></p>
                        </div>
                      </div>
                    </div>
                    <div
                    class="container-planos-uni"
                    v-for="servico in temp_servicos"
                    :key="servico.id"
                    >
                     <h1>{{servico.nome_servico}}</h1>
                     <ul>
                      <li v-for="subservico in servico.sub_servico" :key="subservico.id">
                        <p>{{subservico.nome_sub_servico}}</p>
                      </li>
                      </ul>
                    </div>
                    <h1>Pós vendas</h1>
                    <div class="texto-pos-vendas-perfil " v-html="subscriptions[0].plan.provider.texto_email_pos_venda">
                    </div>
                  </div>
                  <div class="container-detalhes-right">
                    <div class="add-beneficio">
                      <div class="beneficio-img-text">
                        <img src="../assets/img/area/area-03.png" alt />
                        <p>beneficiario do plano</p>
                      </div>
                      <div class="text-beneficios">
                        <p>{{temp_desc.nome_dependente}}</p>
                        <p>CPF - {{temp_desc.cpf}}</p>
                        <p>Idade - {{ new Date().getFullYear() - parseInt(temp_desc.birth_date)}} anos</p>
                        <p>Parentesco - {{temp_desc.parentesco}}</p>
                      </div>
                    </div>
                      <div class="container-documentacao-cobertura">
                        <h3>Documentação</h3>
                          <ul>
                            <li v-for="doc in temp_arquivos" :key="doc.id">
                              <p>
                                <a :href="doc.path" class="not-link-style" target="_blank">{{doc.nome}}</a>
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div class="container-documentacao-cobertura">
                          <h3>Área de Cobertura</h3>
                          <ul>
                            <li v-for="cidade in temp_abrangencia" :key="cidade.id">
                              <p>{{cidade.nome}}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
      <Footer />
    </div> 
</template>

<script>

import jwtDecode from 'jwt-decode';
import myFunction from "../assets/js/my_functions";
import moment from 'moment'
import axios from 'axios';

export default {
    mixins: [myFunction],
    data() {
        return {
            isLoading: false,
            user: [],
            subscriptions: [],
            info_user: [],
            cpf: '',
            telefone: [],
            endereco: {},
            dependentes: [],
            compra: [],
            faturas: [],
            cartao: [],
            qtd: 0,
            active_menu: 1,
            temp_plan: {},
            temp_abrangencia: [],
            temp_operadora: {},
            temp_servicos: [],
            temp_arquivos: [],
            temp_desc: "",
            toggle_edicao: false,
            toggle_alterar_senha: false,
            toggle_alterar_cartao: false,
            novo_telefone: '',
            item_ativo: 'Meus planos',
            foto_plano: '',
            data_ativacao: '',
            senha_atual: '',
            nova_senha: '',
            confirma_nova_senha: '',
            isBoleto: false,
            situacao: '',
            link_boleto: '',
            hash: '',
            tem_fatura: false,
            recorrencia: {
              dia: '',
              transacoes: {}
            },
            transacoes: [],
            cartao_temp: {
              brand: '',
              holder: '',
              cardNumber: '',
              expirationDate: '',
              securityCode: '',
            },
            podeEditarCartao: false,
            destroy_local_storage: false,
        }
    },
    watch: {
      active_menu(val) {
        let self = this;
          if(val ==1) self.item_ativo = 'Meus Planos'
          else if(val == 2) self.item_ativo = 'Meus Dados'
          else if (val == 3) self.item_ativo = 'Minhas Faturas'
      }
    },

    filters: {
      money: function(valor) {
        return `R$ ${parseFloat(valor/100).toFixed(2)}`
      }
    },
    created() {
        let self = this;
        self.qtd = self.$store.getters.getCarrinho.length;
        
        let token = JSON.parse(localStorage.getItem("t"));
        if(token) {
            var decoded = jwtDecode(token);
            self.isLoading = true;
            self.$http.get(`perfil/${decoded.sub}`)
            .then(response => {

                let cartao;

                self.isLoading = false;
                self.user = response.data.usuario;
                self.subscriptions = response.data.subscriptions;
                self.info_user = response.data.subscriber;
                cartao = response.data.cartao;

                self.user.verified_at === null && self.$router.replace("/verifica-email");

                try {
                  if (self.subscriptions[0].plan.recorrencia) self.podeEditarCartao = true;
                }catch(e) {
                  self.podeEditarCartao = false;
                }


                self.cpf = self.info_user.cpf.replace(
                 /(\d{3})(\d{3})(\d{3})(\d{2})/,
                 "$1.$2.$3-$4"
                );

                self.telefone = response.data.phones
                self.endereco = {
                    rua: self.info_user.street,
                    numero: self.info_user.street_number,
                    bairro: self.info_user.district,
                    cidade: self.info_user.city,
                    estado: self.info_user.state,
                    cep: self.info_user.zip_code,
                    complemento: self.info_user.complement
                }

                self.novo_telefone = self.telefone.area_code + self.telefone.number
                
                //self.dependentes = self.subscriptions.dependentes

                self.cartao = cartao;

                for(let sub of self.subscriptions) {
                  let id_subs = sub.id_subs ? sub.id_subs : '';
                  for(let dep of sub.dependentes) {
                    dep.id_subs = id_subs
                    self.dependentes.push(dep);
                  }
                }
                
                if(self.subscriptions[0].status == 'pending') {
                  self.situacao = 'Aguardando Pagamento';
                } else if (self.subscriptions[0].status == 'active') {
                  self.situacao = 'Ativo';
                } else if (self.subscriptions[0].status == 'suspended') {
                  self.situacao = 'Suspenso';
                } else if (self.subscriptions[0].status == 'overdue') {
                  self.situacao = 'Atrasado';
                } else if (self.subscriptions[0].status == 'expired') {
                  self.situacao = 'Expirado';
                } else if (self.subscriptions[0].status == 'canceled') {
                  self.situacao = 'Cancelado';
                } else if (self.subscriptions[0].status == 'error') {
                  self.situacao = 'Erro';
                }

                if(self.subscriptions[0].boleto) {
                  self.isBoleto = true;
                }


                if(self.subscriptions[0].transactions && self.subscriptions[0].transactions.length > 0) {
                  self.tem_fatura = true;
                  self.transacoes = self.subscriptions[0].transactions;
                }

                // if(self.subscriptions[0].recurrency) {
                //   let dia_inicio;
                //   let transacoes = [];
                  
                //   if(self.subscriptions[0].transactions[0].status_code == 20) {
                //     self.tem_fatura = true
                //     dia_inicio = moment(self.subscriptions[0].recurrency[0].start_date, 'YYYY-MM-DD');
                //     self.recorrencia.dia = dia_inicio.format('D');
                //     for(let i in self.subscriptions[0].transactions) {
                //       if(i != 0) {
                //         self.subscriptions[0].transactions[i].dia = dia_inicio.add(i-1, 'M').format('DD-MM-YYYY')
                //         transacoes.push(self.subscriptions[0].transactions[i])
                //       }
                //     }
                //   }

                //   self.recorrencia.transacoes = transacoes;
                // }
            })
            .catch(() => {
              self.isLoading = false;
              self.showAlert("Erro", "Não foi possível recuperar seus dados")
              localStorage.clear();
              self.$router.replace("/");
            })
        }  else {
            localStorage.removeItem("t");
            self.showAlert(
              "Atenção!",
              "Parece que você não tem permissão para acessar essa página!"
            );
            self.$router.replace(self.$route.query.redirect || "/login");
      } 
    },

    // destroyed() {
    //   if(this.destroy_local_storage) {
    //     localStorage.setItem("planos", []);
    //     this.$store.commit("setCarrinho", []);
    //   }
    // },
    methods: {

        alteraCartao() {
          let self = this;
          self.isLoading = true;

          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          let expirationDateValidate = false;
          if(Number(`20${self.cartao_temp.expirationDate.split("/")[1]}`) === currentYear) {
            expirationDateValidate = self.cartao_temp.expirationDate.split("/")[0] >= currentMonth && self.cartao_temp.expirationDate.split("/")[0] < 13 && self.cartao_temp.expirationDate.length === 5 ? true : false;
          } else if(Number(`20${self.cartao_temp.expirationDate.split("/")[1]}`) > currentYear) {
            expirationDateValidate = self.cartao_temp.expirationDate.split("/")[0] > 0 && self.cartao_temp.expirationDate.split("/")[0] < 13 && self.cartao_temp.expirationDate.length === 5 ? true : false;
          }
          const numberValidate = self.cartao_temp.cardNumber.replaceAll(' ', '').length === 16 ? true : false;
          const holderValidate = self.cartao_temp.holder !== '' ? true : false;
          const securityCodeValidate = self.cartao_temp.securityCode.length === 3 ? true : false;

          if(numberValidate, holderValidate, expirationDateValidate, securityCodeValidate) {
            let mes = self.cartao_temp.expirationDate.split("/")[0]
            let ano = `20${self.cartao_temp.expirationDate.split("/")[1]}`
            const creditcard = {
              brand: self.getCardType(self.cartao_temp.cardNumber),
              cardNumber: self.cartao_temp.cardNumber.replaceAll(' ', ''),
              expirationDate: `${mes}/${ano}`,
              holder: self.cartao_temp.holder,
              securityCode: self.cartao_temp.securityCode
            };
            
            axios.post(`atualizar_recorrencia/${self.subscriptions[0].id_subs}`, {creditcard: creditcard})
            .then(() => {
              self.cartao.numbers = creditcard.cardNumber.substring(creditcard.cardNumber.length - 4);
              self.bandeira = creditcard.brand;
              self.cartao_temp = {};
              self.toggle_alterar_cartao = false;
              self.isLoading = false;
              self.showAlert('Sucesso', 'Dados do cartão alterados com sucesso!');
            })
            .catch( e => {
              self.isLoading = false;
              self.showAlert('Erro', 'Não foi possível alterar os dados do cartão. Tente novamente mais tarde!');
            })
          } else {
            if(!numberValidate) self.showAlert('Atenção', 'Número do cartão inválido.')
            else if(!holderValidate) self.showAlert('Atenção', 'Preencha o nome do titular.')
            else if(!expirationDateValidate) self.showAlert('Atenção', 'Data de expiração inválida.')
            else if(!securityCodeValidate) self.showAlert('Atenção', 'Código de segurança inválido.')
            self.isLoading = false;
          }
        },

        getCardType(number) {
          let re = new RegExp("^4");
          if (number.match(re) != null) return "visa";

          re = new RegExp("^(34|37)");
          if (number.match(re) != null) return "amex";

          re = new RegExp("^5[1-5]");
          if (number.match(re) != null) return "Master";

          re = new RegExp("^6011");
          if (number.match(re) != null) return "discover";

          return "visa"; // default type
        },

        showBoleto() {
          let self = this;

          window.open(self.subscriptions[0].boleto, "_blank");
        },

        gera2via() {
          let self = this;
      
          axios
          .get(`pagseguro/get_session_id/${self.dependentes[0].provider_id}`)
          .then((res) => res.data)
          .then((res) => {
            let token = res;
            let hash;
            PagSeguroDirectPayment.setSessionId(token);
            PagSeguroDirectPayment.onSenderHashReady(function (response) {
              if (response.status == "error") {
                return false;
              }
              self.hash = response.senderHash;
              self.gerarNovoBoleto();

            });
          });
        },

        gerarNovoBoleto(){
          let self = this;

          self.isLoading = true;
          axios.post(`segunda_via/subscription/${self.subscriptions[0].id_subs}`, {
            "senderHash": self.hash
          }).then(res => {
            self.isLoading = false;
            window.open(res.success.boleto.transaction.paymentLink, "_blank");
            self.$router.go(-1)
            
          }).catch(() => {
            self.isLoading = false;
            self.showAlert("Erro", "Não foi possível gerar a segunda via");
          })
        },

        cancelarPlano() {
          let self = this;
          let info = self.subscriptions[0].plan.provider
          self.showAlert("Atenção", `Para cancelamento do plano, ligue para ${info.telefone} ou envie um e-mail para ${info.email}` )
        },

        editar_senha() {
          let self = this;

          if(self.nova_senha == self.confirma_nova_senha && self.nova_senha.length >= 6) {
            self.isLoading = true;
            axios.put(`usuarios/${self.user.id}`, {'password': self.nova_senha})
            .then(() => {
              self.isLoading = false;
              self.showAlert("Sucesso", "Senha alterada com sucesso");
            }).catch((e) => {
              self.isLoading = false;
              self.showAlert("Erro", 'Desculpe, não foi possível alterar sua senha, tente novamente mais tarde');
            })
          } else if (self.nova_senha.length < 6) {
            self.showAlert("Atenção", "A senha deverá conter no mínimo 6 dígitos");
          } else {
            self.showAlert("Atenção", "As senhas não conferem");
          }

        },
        padronizaData(data) {
          return (moment(data).format('DD/MM/YYYY'))
        },

        openDetail(id, index) {
            let self = this;

            if(!self.cartao.numbers && !self.isBoleto) {
              self.$router.push({ path: "/finalizar-compra/pagamento" });
            }

            if(self.isBoleto && self.situacao == 'Pendente') {
              return 1;
            }

            self.data_ativacao = self.subscriptions[0].created_at;
        
            self.temp_plan = {}
            self.active_menu = 5;
            self.isLoading = true;
            self.$http.get(`detalhes/plano/${id}`)
            .then(response => {
                self.isLoading = false;
                self.temp_plan = response.data.plano
                self.temp_abrangencia = response.data.abrangencia;
                self.temp_operadora = response.data.operadora;
                self.temp_servicos = response.data.servicos;
                self.temp_arquivos = response.data.arquivos;
                self.temp_desc = self.dependentes[index];
                self.temp_desc.cpf = self.temp_desc.cpf.replace(
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                "$1.$2.$3-$4")
                let date = self.temp_desc.birth_date;
                date = date.toString();
                date = date.split("-")[0];
                self.temp_desc.birth_date = date;
                self.foto_plano = response.data.plano.foto_plano;
            })
            .catch(err => {
                self.isLoading = false;
                self.showAlert("Erro", err.body.message);
            })

				},
				
				logout() {
          let self = this;
          //self.destroy_local_storage = true;
          self.$store.commit("setCarrinho", []);
          localStorage.clear();
          // localStorage.removeItem("t");
          // localStorage.removeItem("planos");
					// localStorage.removeItem("user");
					self.$router.push({ path: "/" });
				},

        dropMenu() {
          document.querySelector(".menu-area").classList.toggle('active');
        },

        getCep() {
          let self = this;
          if (self.endereco.cep.length > 8) {
            self.isLoading = true;
            self.$http
              .get(`https://viacep.com.br/ws/${self.endereco.cep}/json/`)
              .then(
                response => {
                  self.isLoading = false;

                  if (response.data.erro == true) {
                    self.showAlert("CEP não encontrado", "Por favor, verifique o CEP inserido")
                  }
                  
                  else {
                    self.endereco.rua = response.data.logradouro;
                    self.endereco.cidade = response.data.localidade;
                    self.endereco.estado = response.data.uf;
                    self.endereco.bairro = response.data.bairro;
                  }

                })
              .catch(() => {
                self.isLoading = false;
                self.showAlert("Erro", "Houve um erro ao buscar um CEP")
              })
          }
        },
        editar_info() {
          let self = this;
          let token = JSON.parse(localStorage.getItem("t"));
          let decoded = jwtDecode(token);
          if(self.novo_telefone != "" && self.endereco.cep != "" && self.endereco.rua != "" &&
             self.endereco.bairro != "" && self.endereco.cidade != "" && self.endereco.estado != "" &&
             self.endereco.numero != "" && self.endereco.complemento != "") {
            
            let number = self.novo_telefone.split(")")[1].replace(/\D/g, "");
            let area_code = self.novo_telefone.split(")")[0].replace(/\D/g, "");
            self.isLoading = true;
            self.$http.put(`usuarios/${decoded.sub}`, {
              "phones": {
                "number": number,
                "area_code": area_code
              },
              "subscriber": {
                "street": self.endereco.rua,
                "zip_code": self.endereco.cep,
                "district": self.endereco.bairro,
                "city": self.endereco.cidade,
                "state": self.endereco.estado,
                "street_number": self.endereco.numero,
                "complement": self.endereco.complemento
              }
            })
            .then(() => {
              self.isLoading = false;
              self.telefone.number = number;
              self.telefone.area_code = area_code;
              self.showAlert("Atualizado", "Dados atualizados com sucesso!");
            })
            .catch(err => {
              self.isLoading = false;
              self.showAlert("Erro!", err.body.message);
            })

          }
          else {
            self.showAlert("Erro", "Preencha todos os campos");
          }
        }
      }
  }
</script>

<style>

</style>