<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <NavBarPayment cadastro="true" dependentes="true" pagamento="true" />

    <div id="cadastro-dependente">
      <section class="cadastro-dependente">
        <div class="container d-flex justify-content-between">
          <div class="container-forms">
            <h3>Pagamento</h3>
            <div class="cards">
              <div v-if="boleto_option"
                @click="mudaParaBoleto"
                :class="`${type == 'boleto' ? 'card selected' : 'card'} `"
              >
                <img src="../assets/img/opcoes/fatura.svg" />
                <span>{{`Boleto- R$ ${(valor_total_boleto/ 100).toFixed(2).replace(".",",")}`}}</span>
              </div>
              <span v-if="boleto_option">OU</span>
              <div
                @click="mudaParaCartao"
                :class="`${type == 'cartao' ? 'card selected' : 'card'} `"
              >
                <img src="../assets/img/opcoes/cartao-de-credito.svg" />
                <span>Cartão de Crédito</span>
              </div>
            </div>
            <template v-if="type == 'cartao'">
              <form action v-if="renderComponent">
                <div class="container-meus-dados" v-if="user">
                  <input v-model="titular" @change="setCPF" type="checkbox" name id />
                  <span>Sou o(a) titular do cartão</span>
                </div>
                <div class="container-cadastro">
                  <div class="form">

                    <div class="form-group d-flex flex-row">
                      <label>Cupom de desconto</label>
                      <input
                        type="text"
                        v-model="cupom"
                        class="text-uppercase"
                        @blur="validaCupom"
                        :class="cupom_class"
                      />
                    </div>
                      <div class="mb-5" v-if="tem_cupom">
                      <div
                          class="alert-card error-alert"
                          v-if="!cupom_valido"
                        >*Cupom inválido!</div>
                        <div
                          class="alert-card success-alert"
                          v-else
                        >{{`${msg_cupom} ${valor_cupom}!`}}</div>
                      </div>

                    <div class="form-group">
                      <label>CPF</label>
                      <input
                        type="text"
                        v-mask="'###.###.###-##'"
                        :class="errors[4]"
                        v-model="cpf"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[4]"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">Numero do Cartão</label>
                      <input
                        type="text"
                        v-mask="'#### #### #### ####'"
                        id="email"
                        :class="errors[0]"
                        v-model="number"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[0]"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">Nome Impresso no Cartão</label>
                      <input type="text" id="email" :class="errors[1]" v-model="holder_name" />
                      <span
                        class="msgErro"
                        v-if="errors[1]"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                      <label for="email">Data de Expiração </label>
                      <input
                        type="text"
                        v-mask="'##/##'"
                        placeholder="ex: MM/YY"
                        :class="errors[2]"
                        id="email"
                        v-model="exp_date"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[2]"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>
                    </div>
                    <div id="cod-sec" class="col-md-6">
                      <div class="form-group">
                        <label for="email">Cód. Segurança</label>
                        <input @focus="flipCard = true" @blur="flipCard = false" type="text" v-mask="'###'" id="email" :class="errors[3]" v-model="cvv" />
                        <span
                          class="msgErro"
                          v-if="errors[3]"
                        >*Verifique se o campo está preechido com dados válidos!</span>
                      </div>
                    </div>

                      
                    </div>
                  </div>
                </div>

                          <Card :expiration_month="exp_date.split('/')[0]" :expiration_year="exp_date.split('/')[1]" :isCardFlipped="flipCard" :cardCvv="cvv" :cardNumber="number" :cardName="holder_name" />


                <div class="bandeira-card">
                  <img src="../assets/img/pagamento/pagamento-03.png" alt />
                </div>
              </form>
            </template>
            <template v-else>
              <div class="container-cadastro">
                <form action v-if="renderComponent">
                <div class="form">
                  <div class="form-group d-flex flex-row">
                    <label>Cupom de desconto</label>
                    <input
                      type="text"
                      v-model="cupom"
                      class="text-uppercase"
                      :class="errors[5]"
                      @blur="validaCupom"
                    />
                      <div v-if="tem_cupom">
                        <span
                            class="msgErro row"
                            v-if="!cupom_valido"
                          >*Cupom inválido
                        </span>

                        <span
                            class="msg-success row"
                            v-else
                          >{{`${msg_cupom} ${valor_cupom}!`}}
                          </span>
                      </div>
                  </div>
                </div>
                </form>
              </div>
            </template>
            <div class="btn-cadastro" @click="pagamento">
              <button>
                Efetuar Pagamento
                <img src="../assets/img/cadastro/cadastro-02.png" alt />
              </button>
            </div>
          </div>
          <div class="d-flex flex-column justify-content-between">
          <SubCarrinho  :desconto="valor_cupom" :boleto="isBoleto"/>
          </div>
  
        </div>
      </section>
    </div>

    <FooterPayment />
  </div>
</template>
<script>
import { validate as validateCPF } from "gerador-validador-cpf";
import myFunctions from "../assets/js/my_functions";
import Bugsnag from "@bugsnag/js";
import "../assets/js/Pagseguro";
import axios from "axios";
import Card from '../components/credit_card'

export default {
  name: "pagamento",
  mixins: [myFunctions],
  components:{
    Card
  },
  data() {
    return {
      isBoleto: false,
      planos: this.$store.getters.getCarrinho,
      total: 0,
      type: "cartao",
      cpf: "",
      total_taxa: 0,
      toggleCarrinho: false,
      exp_date: "",
      holder_name: "",
      number: "",
      hash: "",
      flipCard: false,
      cvv: "",
      expiration_month: "",
      expiration_year: "",
      errors: [],
      isLoading: false,
      titular: false,
      renderComponent: true,
      valor_cupom: 0,
      msg_cupom: '',
      user: {},
      cupom: '',
      cupom_valido: '',
      cupom_class: '',
      tem_cupom: false,
      array_planos: [],
      boleto_option: true,
      valor_total_boleto: 0,
      fazendoPagamento: false,
    };
  },
  computed: {
 
    getCardType() {
      let number = this.number;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      return "visa"; // default type
    },
  },
  mounted() {
    /* eslint-disable */
    let self = this;

    for(let plano of self.planos) {
      if(!plano.valor_boleto) {
        self.boleto_option = false;
      }

      for(let i=0; i<plano.qtd; i++) {
        self.array_planos.push(plano.id);
        self.valor_total_boleto += plano.valor_boleto;
      }
    }
    axios
      .get(`pagseguro/get_session_id/${self.planos[0].provider_id}`)
      .then((res) => res.data)
      .then((res) => {
        let token = res;
        let hash;
        PagSeguroDirectPayment.setSessionId(token);
        PagSeguroDirectPayment.onSenderHashReady(function (response) {
          if (response.status == "error") {
            return false;
          }
          self.hash = response.senderHash;
        });
        
      });
  },
  created() {
    let self = this;
    self.user = localStorage.getItem("user");
    let dados = self.$store.getters.getCarrinho;
    dados = [].map.call(self.$store.getters.getCarrinho, (item) => {
      return { id: item.id, qtd: item.qtd };
    });
    self.isLoading = true;
    self.$http
      .post("verificaandamentov3", { plans: dados })
      .then((res) => res.json())
      .then(
        (response) => {
          self.abrangencia = response.abrangencia;
          self.arquivos = response.arquivos;
          self.isLoading = false;
          if (response.rota != "pagamento" && response.rota != "/minha-area") {
            self.$router.replace(
              self.$route.query.redirect || "/finalizar-compra/" + response.rota
            );
          } else if(response.rota == "/minha-area") {
            self.$router.replace("/minha-area");
          }
        },
        /*eslint-disable */

        (err) => {
          self.isLoading = false;
          self.$store.commit("setCarrinho", "");

          localStorage.removeItem("planos");
          if (err.status == 400) {
            self.showAlert(
              "Abrangência Restrita",
              "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
            );
            self.$router.replace(self.$route.query.redirect || "/");
          } else if (err.status == 403) {
            self.showAlert(
              "Usuário não autenticado!",
              "É necessário confirmar o email para prosseguir!"
            );
            self.$router.replace(
              self.$route.query.redirect || '/verifica-email'
            );
          } else {
            self.showAlert(
              "Erro ao verificar cadastro",
              "Não foi possível identificar o motivo do erro. Tente novamente por favor"
            );
            self.$router.replace(self.$route.query.redirect || "/");
          }
          Bugsnag.notify(err);
        }
      );

    self.total_taxa = self.planos.reduce(function (total, item) {
      return total + parseFloat(item.setup_fee) * item.qtd;
    }, 0);

    self.total = self.planos.reduce(function (total, item) {
      return total + parseFloat(item.price) * item.qtd;
    }, 0);
  },
  methods: {
    validaCupom() {
      if(this.cupom != ""){
        let obj = {
          cupons: [this.cupom],
          plans: this.array_planos
        }
      
       this.$http.post('validar_cupons', obj)
       .then(response => {
         let resposta = response.data;
         if(resposta.cupons[0].valido) {
           if (resposta.cupons[0].model.vitalicio == 1) {
             this.msg_cupom = '*Cupom aplicado. O valor das mensalidades serão de R$';
             this.showAlert("Cupom Válido", "O cupom será aplicado nas mensalidades.");
           } else {
             this.msg_cupom = '*Cupom aplicado. O valor da primeira mensalidade será de R$';
             this.showAlert("Cupom Válido", "O cupom será aplicado na mensalidade do primeiro mês.")
           }
           this.valor_cupom = resposta.valor;
           this.cupom_valido = this.cupom;
           this.cupom_class = 'success-class'
         } else {
           this.cupom = '';
           this.showAlert("Cupom Inválido", resposta.cupons[0].mensagem ? resposta.cupons[0].mensagem : 'Cupom Inválido');
           this.cupom_valido = '',
           this.valor_cupom = 0;
           this.cupom_class = 'error-class'
         }
         this.tem_cupom = true;
       }).catch(() => {
       })
      }
      
    },
    mudaParaBoleto() {
      this.type = 'boleto';
      this.isBoleto = true;
    },
    mudaParaCartao(){
      this.type = 'cartao';
      this.isBoleto = false;
    },
    setCPF() {
      let self = this;
      if (self.titular) {
        self.cpf = JSON.parse(localStorage.getItem("user")).cpf;
      } else {
        self.cpf = "";
      }
    },
    removeClass(pos) {
      let self = this;
      self.renderComponent = false;
      self.errors[pos] = "";
      self.forceRerender();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    pagamento() {
      let self = this;

      if(!self.fazendoPagamento){

        self.fazendoPagamento = true;
        if (this.type == "cartao") {
          self.errors = [];
          if (
            self.holder_name &&
            self.number &&
            self.cvv &&
            self.exp_date &&
            validateCPF(self.cpf)
          ) {
            self.isLoading = true;
            let lead_id = JSON.parse(localStorage.getItem("id_lead"));
            let info = {
              cupons: this.cupom_valido ? [this.cupom_valido]: null,
              billing_info: {
                credit_card: {
                  holder_name: self.holder_name,
                  number: self.number.replace(/\D/g, ""),
                  cvv: self.cvv,
                  expiration_month: self.exp_date.split("/")[0],
                  expiration_year: self.exp_date.split("/")[1],
                  bandeira: self.getCardType,
                  cpf: self.cpf.replace(/\D/g, ""),
                },
              },
              plans: this.array_planos,
              lead_id
            };
            self.isLoading = true;
            self.$http
              .post("assinatura/checkout", info)
              .then((res) => res.json())
              .then(
                (response) => {
                  self.isLoading = false;
                  if (response.success) {
                    self.$store.commit("setCarrinho", []);
                    localStorage.removeItem("planos");
                    self.$router.replace(
                      self.$route.query.redirect ||
                        "/finalizar-compra/finalizado/cartao/" +
                          response.success.protocolo
                    );
                  }
                },
                (err) => {
                  self.isLoading = false;
                  self.showAlert("Verifique os dados!", err.body.message);
                  Bugsnag.notify(err);
                }
              );
          } else {
            self.errors = [];
            self.holder_name == ""
              ? self.errors.push("error-class")
              : self.errors.push("");
            self.number == ""
              ? self.errors.push("error-class")
              : self.errors.push("");
            self.cvv == ""
              ? self.errors.push("error-class")
              : self.errors.push("");
            self.exp_date == ""
              ? self.errors.push("error-class")
              : self.errors.push("");
            self.cpf == "" || !validateCPF(self.cpf)
              ? self.errors.push("error-class")
              : self.errors.push("");
          }
        } else {
          self.isLoading = true;
          let lead_id = JSON.parse(localStorage.getItem("id_lead"));
          this.$http
            .post(`assinatura/checkout/boleto`, {
              senderHash: self.hash,
              cupons: this.cupom_valido ? [this.cupom_valido]: [],
              plans: this.array_planos,
              lead_id
            })
            .then((res) => res.data)
            .then((res) => {
              self.isLoading = false;
              self.$store.commit("setCarrinho", []);
              localStorage.removeItem("planos");
              window.open(res.success.boleto.transaction.paymentLink, "_blank");
              self.$router.replace(
                self.$route.query.redirect ||
                  "/finalizar-compra/finalizado/boleto/" + res.success.protocolo
              );
            }).catch(() => {
              self.isLoading = false;
              self.showAlert("Erro", "Ocorreu um erro ao gerar seu boleto, tente novamente mais tarde");
            })
        }
      }
    },
  },
};
</script>
<style  scoped>
.card {
  border: solid 3px rgb(211, 211, 211);
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  #cod-sec{
    margin-top: 30px;
  }

  .btn-cadastro{
    z-index: 999;
  }
}

.alert-card{
  font-size: 12px;
  font-family: "Poppins";
  font-weight: bold;
  word-break: break-word;
  padding: 10px 20px;
  border: solid 2px rgba(0, 0, 0, 0.801);
  border-radius: 5px;
  color: black;
  opacity: .6;
  background-color: rgba(0, 0, 0, 0.219);
}

.success-alert{
  border: solid 2px #34a007;
  color: #34a007;
  background-color: #35a00728;
}

.error-alert{
  border: solid 2px #c90c0c;
  color: #851313;
  background-color: #c90c0c2f;
}

.cards span {
  font-size: 11px;
  font-family: "Poppins";
  font-weight: bold;
}

.card img {
  width: 40px;
}

.cards .card span {
  margin-top: 2rem;
}

.float-right {
  float: right;
}

.footer {
  margin-top: 10rem;
  width: 100%;

  position: absolute;
}

.w-40 {
  width: 60em;
}

.purple {
  color: #c90c53 !important;
}

.selected {
  border: solid 3px #c90c53 !important;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>