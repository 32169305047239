<template>
    <div class="background"> 
        <h1>Ops, parece que essa página não existe.</h1>

        <div class="btn-cadastro white" >
                                          <router-link to="/">

            
                  <button type="button">
                   Ir para tela inicial
                  
                  </button>
                                                        </router-link>

                </div>

    </div>
</template>
<script>
export default {
    name: '404'
}
</script>
<style scoped>
.background{
    background-color: #c90c53;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.white a{
    background-color: white;
    width: 40%;
}



@media only screen and (max-width: 700px) {
  .white {
    width: 80%;
  }
}


.white button{
    color:#c90c53 !important;
}

h1{
  font-size: 5em;
  font-family: "Poppins";
  font-weight: bold;
  color: white;
  margin: 2em;
}
</style>