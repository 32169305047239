<template>
  <div>
    <loading :active.sync="isLoading" name="loading" color="#C90C53"/>
    <Cookie />
    <Navbar :night="scrolled" />
    <div id="home">
      <section class="home">
        <div class="container">
          <div class="text-select">
            <h1>Seu novo plano odontológico sem sair de casa!</h1>

            <h2>
              <img src="../assets/img/home/home-04.png" alt /> EXCLUSIVO OPERADORAS UNIODONTO
            </h2>


            <span>Encontre sua cidade abaixo:</span>
            <!--<div class="container-select">
              <div class="select">
                <span>Selecione sua cidade</span>
                <img src="../assets/img/home/home-02.png" alt />

                <input id="cidade" type="hidden" name="id-cidade" />

                <div class="container-list">
                  <ul class="list">
                    quando for fazer o forEach color id da cidade do data-id 
                    <li v-for="cidade in cidades" :key="cidade.id" :data-id="cidade.id">
                      <p @click="getPlans(cidade)">{{cidade.nome}}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>-->

            <div class="select-cidade" v-if="cidades.length > 0">
              <multiselect 
                placeholder="Procure sua cidade"
                selectLabel=""
                :value="value" 
                :options="cidades" 
                :multiple="false"
                track-by="nome"
                label="nome"
                :searchable="true" 
                :allow-empty="false" 
                :hide-selected="true" 
                :max-height="150" 
                :disabled="isDisabled" 
                @input="onChange"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ option.nome }}</template>
                <span slot="noResult">Cidade não encontrada.</span>
              </multiselect>
            </div>

          </div>
        </div>

        <div class="img-fundo">
          <img src="../assets/img/home/home-01.jpg" alt />
        </div>
      </section>

      <section class="adquir-planos">
        <div class="container">
          <div class="planos">
            <h1>
              Veja como é simples adquirir seu plano!
              <img src="../assets/img/home/home-03.png" alt />
            </h1>

            <div class="card">
              <h1>01</h1>

              <h3>Informe sua Localização</h3>

              <p>Selecione o local desejado e encontre os melhores planos disponíveis para sua região.</p>
            </div>

            <div class="card">
              <h1>02</h1>

              <h3>Consulte os Planos</h3>

              <p>Consulte opções, serviços e veja qual opção mais se encaixa com você.</p>
            </div>

            <div class="card">
              <h1>03</h1>

              <h3>Contrate seu Plano</h3>

              <p>Faça seu cadastro de maneira rápida e segura, sem sair de casa.</p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from '@bugsnag/js'
import Cookie from '../components/cookie'

export default {
  name: "home",
  mixins: [myFunction],
  components: {
    Cookie
  },
 

  data() {
    return {
      isDisabled: false,
      isTouched: false,
      value: '',
      cidades: [],
      cidades_m: [
	{"id":3,"nome":"Poços de Caldas","estado":"MG","provider_id":1,"url":"mg/alfenas"},
	{"id":2,"nome":"Andradas","estado":"MG","provider_id":1,"url":"mg/andradas"},
	{"id":4,"nome":"Bandeira do Sul","estado":"MG","provider_id":1,"url":"mg/bandeira-do-sul"}
],
      isActive: false,
      isLoading: false,
      scrolled: "",
      qtd:0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    let select = document.querySelector(".select");
    let itensSelect = Array.from(document.querySelectorAll(".list li"));

    if (select) {
      select.addEventListener("click", function() {
        select.classList.toggle("active");
      });

      itensSelect.forEach(function(iten) {
        iten.addEventListener("click", function() {
          //capturando data id
          let id = parseInt(iten.dataset.id);

          //set valor no input
          document.getElementById("cidade").value = id;

          //set valor no select
          select.querySelector("span").innerHTML = iten.querySelector(
            "p"
          ).innerHTML;
        });
      });
    }

    let self = this;
    self.isLoading = true;
    self.$http
      .get("cidades")
      .then(res => res.json())
      .then(
        data => {
          self.isLoading = false;
          self.cidades = data;
          if(this.$store.getters.getCarrinho){
      this.qtd = this.$store.getters.getCarrinho.length;
    }
        },
        /*eslint-disable */
        err => {
          Bugsnag.notify(err)
          self.isLoading = false;
          self.showAlert(
            "Erro!",
            "Desculpe, ocorreu um erro ao listar as cidades!"
          );
        }
      );
  },
  methods: {
    nameWithLang ({ name, language }) {
      return `${name} — [${language}]`
    },
    toggleSelect() {
      let self = this;
      self.isActive = !self.isActive;
    },
    getPlans(cidade) {
      let self = this;
      //self.$store.commit("setCidade", cidade.nome);
      //self.$router.push(self.$route.query.redirect || "/planos/" + cidade.url);
    },
    handleScroll() {
      if (window.scrollY > 150) {
        this.scrolled = true;
      } else {
        this.scrolled = "";
      }
    },
    onChange (value) {
      let self = this;
      
      self.$store.commit("setCidade", value.nome);
      self.$router.push(self.$route.query.redirect || "/planos/" + value.url);
    }
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style >
 
</style>