<template>
  <div>
        <loading :active.sync="isLoading" color="#C90C53"></loading>
    <NavBarPayment cadastro="true" pagamento="true" dependentes="true" concluido="true" />
    <div id="compra">
      <section class="cadastrado-compra">
        <div class="container">
          <div class="container-compra">
            <h1>Compra Realizada com Sucesso!</h1>

            <div class="numero-pedido">
              <h3>Pedido - {{subscription.protocolo}}</h3>
              
            </div>

            <template v-if="subscription.cupons != undefined">
              <div
                v-if="subscription.cupons.cupons[0].vitalicio == 1"
                class="alert-card success-alert mt-5 w-75"
              >
                {{`Cupom aplicado nas mensalidades, o valor será de R$ ${parseFloat(subscription.custom_price/100).toFixed(2)}.`}}
              </div>
              <div
                v-else
                class="alert-card success-alert mt-5 w-75"
              >
                {{`Cupom aplicado na primeira mensalidade no valor de R$ ${parseFloat(subscription.cupons.valor/100).toFixed(2)}, as demais mensalidades serão no valor de R$${parseFloat(dependentes.reduce((total, value) => total + (value.price/100), 0)).toFixed(2)}`}}
              </div>
            </template>
                     
            <div class="titular">
              <span>Títular do plano</span>
              <h3>{{nome}}</h3>
              <h4>{{email}}</h4>
            </div>

            <div class="mais-inf">
              
              <p v-html="pos_venda"></p>

              <hr />

              <p>Entre na sua Área para gerenciar seus planos, dados e pagamentos.</p>

              <p>Em caso de dúvida estamos a disposição.</p>
            </div>
            <!--<template v-if="this.$route.params.tipo">
            <div class="numero-pedido mb-3">
              <h3>Informações sobre o boleto:</h3>
            </div>
            <div class="mais-inf">
              
              <p>Um boleto foi gerado e aberto em um nova aba do seu navegador,
                 para receber acesso aos recursos de sua assinatura pague o mesmo e 
                verifique seu <strong> status </strong> na aba de minha área.
              </p>

               <p><strong> Se você não recebeu o boleto, <a :href="subscription.boleto" target="_blank"> clique aqui</a>.</strong></p>

             

              
            </div>
            </template>-->

            <div class="btn-cadastro">
              
              <router-link to="/minha-area">
                <a href>
                  Acessar minha Área
                  <img src="../assets/img/cadastro/cadastro-02.png" alt />
                </a>
              </router-link>
            </div>

            <div class="documentacao-compra">
              <h3>Documentação e contrato</h3>

              <ul>
                <li v-for="arquivo in arquivos" :key="arquivo.id">
                  <a
                    target="_blank"
                    :href="arquivo.path"
                  >{{arquivo.nome}}</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="container-carrinho compra-finalizada active">
            <div class="title-carrinho">
              <p>Meu Plano adquirido</p>
            </div>

            <div class="container-itens-carrinho">
              <div
                class="container-item-plano"
                v-for="item in subscription.itens"
                :key="item.id"
              >
                <div class="container-itens">
                  <h3>{{item.nome_plano}}</h3>
                  <p>{{subscriber.city}} - {{subscriber.state}}</p>
                  <h4>
                    R$ {{ ( item.price / 100).toFixed(2).replace(".",",") }}
                    <span>/ mês</span>
                  </h4>
                </div>

                <div class="card-dependente-plano">
                  <div class="container-img">
                    <img src="../assets/img/pagamento/pagamento-05.png" alt />
                  </div>

                  <div class="container-text">
                    <h3>{{item.nome_dependente}}</h3>
                    <span>{{item.nome_plano}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-uniodonto-compra-finalizada">
              <div class="container-img">
                <img src="../assets/img/pagamento/compra-06.png" alt />
              </div>

              <span>{{operadora.name}}</span>

              <div class="card-endereco">
                <span>{{operadora.end_logradouro}}, {{operadora.end_numero}}. {{operadora.end_bairro}}</span><br/>
                <span>{{operadora.end_cidade}} - {{operadora.end_estado}}</span>
                <span> CEP - {{operadora.end_cep}}</span>
              </div>

              <span>Telefone - {{operadora.telefone}}</span>
            </div>
          </div>
        </div>
      </section>
    </div>
    <FooterPayment />
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from '@bugsnag/js'

export default {
  name: "finalizado",
  mixins: [myFunction],
  data() {
    return {
      protocolo: this.$route.params.protocolo,
      operadora: {},
      arquivos: [],
      subscription: {},
      dependentes: [],
      subscriber: '',
      local: [],
      nome: "",
      email: "",
      isLoading: false,
      pos_venda: ''
    };
  },
  mounted() {
    let self = this;
    self.isLoading = true;
    self.$http
      .get(`assinatura/${self.protocolo}`)
      .then(res => res.json())
      .then(
        response => {
          self.operadora = response.operadora;
          self.arquivos = response.arquivos;
          self.subscription = response.subscription;
          self.dependentes = response.dependentes;
          self.nome = response.subscriber.name;
          self.email = response.subscriber.email;
          self.subscriber = response.subscriber

          self.pos_venda =response.operadora.texto_email_pos_venda;
          
          self.isLoading = false;
        },
        /*eslint-disable */
        err => {
          Bugsnag.notify(err)
          self.showAlert("Erro!", "Desculpe, ocorreu um erro ao buscar algumas informações!");
          self.isLoading = false;
        }
      );
    /*
          

    let dados = self.$store.getters.getCarrinho.length;
    self.isLoading = true
    self.$http
      .post("v3/verificaandamentov3", dados)
      .then(res => res.json())
      .then(response => {
        self.isLoading = false
        if (response.rota != "finalizado")
          self.$router.replace(
            self.$route.query.redirect || "/" + response.rota
          );
      }); */
  }
};
</script>
<style scoped>
.container-itens-carrinho{
  height: 100% !important;
  overflow-y: hidden !important;
}

.title-carrinho{
  padding: 20px 0px;
}

.card-uniodonto-compra-finalizada{
  padding-bottom: 50px !important;
}
.container-carrinho{
  height: 100%  !important;
}

.container-carrinho.compra-finalizada{
  height: 100%  !important;
}
</style>