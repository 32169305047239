import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueResource from 'vue-resource';
import VueTheMask from 'vue-the-mask';
import { store } from './store.js';
import axios from 'axios';
import VueAnalytics from 'vue-analytics';
// import VueGtm from 'vue-gtm';
import Vuelidate from 'vuelidate'
import Loading from 'vue-loading-overlay';
import VueCardFormat from 'vue-credit-card-validation';
import SlimDialog from 'v-slim-dialog'
import Moment from 'vue-moment';
import VueMaterial from 'vue-material'
import Multiselect from 'vue-multiselect'
import VModal from 'vue-js-modal'
import BootstrapVue from 'bootstrap-vue';

// import Bugsnag from '@bugsnag/js'
// import BugsnagPluginVue from '@bugsnag/plugin-vue'


//Componentes
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import NavBarPayment from "./components/navbar_payment";
import FooterPayment from "./components/footer_payment";
import Modal from "./components/modal_lateral";
import Carrinho from "./components/carrinho";
import SubCarrinho from "./components/sub_carrinho"



import 'v-slim-dialog/dist/v-slim-dialog.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-material/dist/vue-material.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';



//Call components
Vue.component('Navbar', Navbar)
Vue.component('Footer', Footer)
Vue.component('Modal', Modal)
Vue.use(VModal, { componentName: "modal-editado" })
Vue.component('SubCarrinho', SubCarrinho)
Vue.component('Carrinho', Carrinho)
Vue.component('NavBarPayment', NavBarPayment)
Vue.component('FooterPayment', FooterPayment)
Vue.component('loading', Loading)
Vue.component('multiselect', Multiselect)
Vue.use(VueMaterial)
Vue.use(VueResource)
Vue.use(Vuelidate);
Vue.use(VueTheMask)
Vue.use(Loading)
Vue.use(router)
Vue.use(VueCardFormat);
Vue.use(SlimDialog)
Vue.use(Moment)
Vue.use(BootstrapVue);




//Vue-resource
Vue.config.productionTip = false
let isProd = process.env.NODE_ENV === 'production'
//let isProd = true;
Vue.http.options.emulateJSON = true;
Vue.http.options.emulateHTTP = true;
Vue.http.options.timeout = 12000;


Vue.http.options.root = (isProd) ? 'https://api.comprodonto.com.br/api/' : 'http://127.0.0.1:8000/api/'
axios.defaults.baseURL = (isProd) ? 'https://api.comprodonto.com.br/api/' : 'http://127.0.0.1:8000/api/'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.timeout = 30000;

Vue.http.interceptors.push((request, next) => {
    var token = JSON.parse(localStorage.getItem("t"));

    if (token) {
        let removeAuthHeaders = request.url.includes("viacep.com.br");

        if (removeAuthHeaders) {
            request.headers.delete('Authorization')
        } else {
            request.headers.set('Authorization', 'Bearer ' + token)
        }
    }
    next()
})

axios.interceptors.request.use(async (config) => {
    var token = JSON.parse(localStorage.getItem("t"));
    if (
      !config.url.endsWith('login') ||
      !config.url.includes('viacep.com.br')
    ) {
        config.headers.Authorization = (token) ? `Bearer ${token}` : '';
    }
    return config;
  }, (error) => {
    // I cand handle a request with errors here
    return Promise.reject(error);
  });


//CSS
require('./assets/css/main.css')
require('./assets/css/maze.css')
require('./assets/css/card-detalhe.css')
require('./assets/css/minha-area.css')
require('./assets/css/noticias.css')
require('./assets/css/plugin.min.css')

router.afterEach((to, from, next) => {
    let queryParams = "";
    if(Object.entries(router.history.current.query).length > 0){
      queryParams += "?";
      let qtdParams = 0;
      for (var [key, value] of Object.entries(router.history.current.query)) {
        queryParams+= key + '=' + value;
        if(Object.entries(router.history.current.query)[qtdParams+1])
          queryParams+="&"
        qtdParams++;
      }
    }
    
    let self = this;
    let id_lead = JSON.parse(localStorage.getItem("id_lead"));
    if(!id_lead){
      let obj = {
        nome: '',
        email: '',
        telefone: '',
      }
      axios.post("leads", obj)
        .then((res) => {
          localStorage.setItem("id_lead", res.data.id);
          id_lead = res.data.id;
          const objEvento = {
            acao: 'VISUALIZACAO',
            leads_id: id_lead,
            identificacao: router.history.current.path+queryParams
          }
          axios.post("eventos_sites", objEvento)
            .then((res) => {
            }).catch((e) => {
            })
        })
        .catch((e) => {
          self.showAlert("Erro!", e.body.message)
        });
    }else {
      const objEvento = {
        acao: 'VISUALIZACAO',
        leads_id: id_lead,
        identificacao: router.history.current.path+queryParams
      }
      axios.post("eventos_sites", objEvento)
        .then((res) => {
        }).catch((e) => {
        })
    }
    
})
//Analytics
Vue.use(VueAnalytics, {
    id: 'UA-144467035-1',
    router,
    debug: {
        enable: !isProd,
        sendHitTask: isProd
    },
    ecommerce: {
        enabled: true,
        enhanced: true
    }
})

// Vue.use(VueGtm, {
//     id: 'GTM-WK426XS', // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
//     enabled: !isProd, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
//     debug: !isProd, // Whether or not display console logs debugs (optional)
//     loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
//     vueRouter: router, // Pass the router instance to automatically sync with router (optional)
// });

// Bugsnag.start({
//     apiKey: '5e60c5753ea24a26924df8facf01ce7e',
//     plugins: [new BugsnagPluginVue(Vue)]
//   })

new Vue({
    router,
    store: store,
    render: h => h(App),
}).$mount('#app')