<template>
		<div :class="toggleCarrinho ? 'container-carrinho active': 'container-carrinho'">
						<div class="title-carrinho" @click="toggleCarrinho = !toggleCarrinho">
							<img src="../assets/img/carrinho/carrinho-08.png" alt />

							<div class="count-item">
								<img src="../assets/img/carrinho/carrinho-10.png" alt />
								<p>{{planos.length}}</p>
							</div>

							<div>
								<p> Meu carrinho </p>
								<p v-if="!toggleCarrinho" class="ml-4 mt-2 total-sub-carrinho">
									R$ {{(total / 100).toFixed(2).replace(".",",")}}</p>
							</div>
							
						</div>
						

						<div class="valor-total">
							<h3>Total</h3>

							<p>
								R$ {{(total / 100).toFixed(2).replace(".",",")}}
								<span>/mês</span>
							</p>
						</div>

						<div class="container-itens-carrinho">
							<div class="container-itens" v-for="plano in planos" :key="plano.id">
								<h3>{{plano.name}}</h3>
								<p>{{plano.cidade}} - {{plano.estado}}</p>
								<h4>
									{{ boleto ? (plano.valor_boleto / 100).toFixed(2).replace(".",",") :
										(plano.price / 100).toFixed(2).replace(".",",") }}
									<span>/ mês</span>
								</h4>
							</div>
						</div>

						<div class="sub-total" id="subtotal">
							<div class="container-text-sub-total">
								<p>Total de taxas</p>
								<span>R$ {{ ( (taxa) / 100).toFixed(2).replace(".",",") }}</span>
							</div>
						</div>

						<div class="sub-total" id="subtotal">
							<div class="container-text-sub-total">
								<p>Subtotal
									<i
										class="fa fa-info-circle"
										style="font-size: 17px"
											title="Valor da Primeira mensalidade com a taxa de adesão do plano"
									></i>
								</p>
								<span>R$ {{ ((total+taxa) / 100).toFixed(2).replace(".",",") }}</span>
							</div>
						</div>

						<div class="valor-total">
							<h3>Total</h3>

							<p>
								R$ {{ ( (total) / 100).toFixed(2).replace(".",",") }}
								<span>/mês</span>
							</p>
						</div>
					</div>
</template>
<script>
export default {
		name: 'sub_carrinho',
		props: {
			desconto: { 
				type: Number,
				default: 0,
				required: false
			},
			boleto: {
				type: Boolean,
				default: false,
				required: false,
			}
		},
		data(){
				return{
						planos: this.$store.getters.getCarrinho,
						total: 0,
						toggleCarrinho: false,
						taxa: 0,
				}
		},

		watch: {
			boleto (newValue, oldValue) { 
				this.calculaTotal();
			},
		},

		mounted(){
			this.calculaTotal();
		},

		methods: {
			calculaTotal() {
				if(this.boleto) {

					this.total = this.planos.reduce(function(total, item) {
						return total + parseFloat(item.valor_boleto) * item.qtd;
					}, 0);

					this.taxa = this.planos.reduce(function(total, item) {
						return total + parseFloat(item.setup_fee) * item.qtd;
					}, 0);

				} else {

					this.total = this.planos.reduce(function(total, item) {
						return total + parseFloat(item.price) * item.qtd;
					}, 0);

					this.taxa = this.planos.reduce(function(total, item) {
						return total + parseFloat(item.setup_fee) * item.qtd;
					}, 0);
				}
			}
		}
}
</script>