<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <NavBarPayment v-if="!ja_preencheu" cadastro="true" dependentes="true" />
    <NavBarPayment v-else cadastro="true" dependentes="true" pagamento="true" />
    <div id="cadastro-dependente">
      <section class="cadastro-dependente">
        <div class="container">
          <div class="container-forms">
             <h2 style="margin-bottom: 15px">{{dependents.length > 1 ? 'Quem participará dos planos?': 'Quem participará do plano?'}} </h2>
              <p class="subtitle">Preencha os campos abaixo com os dados de cada participante. Cada participante deve estar <br/> vinculado a um plano, para  maiores informações entre em contato conosco atráves do nosso chat. </p>
                         <hr class="hr-dependentes" style="margin: 10px 0px;" />

            <div v-for="(dependente, index) in dependents" :key="dependente.id">
             <h3>Participante {{index + 1}}</h3>
             
              <div v-if="ja_checkou_usar_meus_dados && dependente.useInfoUser || !ja_checkou_usar_meus_dados && !dependente.useInfoUser" class="container-meus-dados">
                <input
                  v-model="dependente.useInfoUser"
                  @change="infoDep(dependente, index)"
                  type="checkbox"
                  name
                  id
                />
                <span>Usar meus dados de Cadastro</span>
              </div>

              <form v-if="renderComponent">
                <div class="container-cadastro">
                  <div class="form">
                    <div class="form-group">
                      <label for="email">Nome</label>
                      <input
                        type="text"
                        :class="errors[index].name"
                        id="email"
                        v-model="dependente.name"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].name"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">Email</label>
                      <input
                        type="text"
                        id="email"
                        :class="errors[index].email"
                        v-model="dependents[index].email"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].email"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">CPF</label>
                      <input
                        type="tel"
                        id="email"
                        :class="errors[index].cpf"
                        v-mask="'###.###.###-##'"
                        v-model="dependents[index].cpf"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].cpf"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">Data de Nascimento</label>
                      <input
                        type="tel"
                        id="email"
                        :class="errors[index].birth_date"
                        v-model="dependents[index].birth_date"
                        v-mask="'##/##/####'"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].birth_date"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">RG</label>
                      <input
                        type="tel"
                        id="email"
                        v-model="dependents[index].rg"
                        :class="errors[index].rg"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].rg"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">
                        CNS
                        <i class="nao-obrigatorio">(Não obrigátorio)</i>
                        <a
                          target="_blank"
                          href="http://www.ans.gov.br/index.php/a-ans/sala-de-noticias-ans/consumidor/1819-cartao-nacional-de-saude-uma-realidade-para-todos-os-brasileiros"
                        >O que é cns?</a>
                      </label>
                      <input
                        type="tel"
                        id="email"
                        v-mask="'### #### #### ####'"
                        v-model="dependents[index].cns"
                        :class="errors[index].cns"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].cns"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group mt-5">
                      <label for="email">Nome da Mãe</label>
                      <input
                        type="text"
                        id="email"
                        v-model="dependents[index].nome_mae"
                        :class="errors[index].nome_mae"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].nome_mae"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>
                    <!-- <div class="form-group" v-if="planos.find(item => item.id == dependents[index].plan_id ).provider_id == 13"> -->
                    <div class="form-group" v-if="planos[0].provider_id == 13">
                      <label for="email">Naturalidade 
                        <i class="nao-obrigatorio">(Cidade onde nasceu)</i>
                      </label>
                      <input
                        type="text"
                        id="email"
                        v-model="dependents[index].naturalidade"
                        :class="errors[index].nome_mae"
                      />
                      <span
                        class="msgErro"
                        v-if="errors[index].naturalidade"
                      >*Verifique se o campo está preechido com dados válidos!</span>
                    </div>

                    <div class="form-group">
                      <label for="email">Planos</label>
                      <select
                        v-model="dependents[index].plan_id"
                        :class="'list-dependente '+ errors[index].plan_id"
                      >
                        <option value selected="selected" disabled>Selecione o plano</option>
                        <option
                          v-for="plano in planos"
                          :key="plano.id"
                          :value="plano.id"
                        >{{plano.name}}</option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label for="email">Parentesco</label>
                      <select
                        :class="'list-dependente '+ errors[index].parentesco"
                        v-model="dependents[index].parentesco"
                      >
                        <option value selected="selected" disabled>Selecione o grau de parentesco</option>
                        <option
                          value="Nenhum, sou responsável financeiro"
                        >Nenhum, sou responsável financeiro</option>
                        <option value="Conjuguê">Cônjugue</option>
                        <option
                          value="Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial"
                        >Convivente, sem eventual concorrência com o cônjuge, salvo por decisão judicial</option>
                        <option
                          value="Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino"
                        >Filhos solteiro, até 21 anos incompletos, ou até 24 anos incompletos, desde que cursando graduação em nível superior de ensino</option>
                        <option
                          value="Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos"
                        >Enteado, o menor sob a guarda por força de decisão judicial e o menor tutelado, que ficam equiparados aos filhos</option>
                        <option
                          value="Filhos comprovadamente inválidos, independente de idade"
                        >Filhos comprovadamente inválidos, independente de idade</option>
                        <option
                          value="Netos menores de 18 anos e solteiros"
                        >Netos menores de 18 anos e solteiros</option>
                        <option value="Irmãos menores de 18 anos">Irmãos menores de 18 anos</option>
                        <option value="Pais">Pais</option>
                        <option value="Sogros">Sogros</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              <hr class="hr-dependentes" />
            </div>
            <div class="btn-cadastro" @click="cadastrarDependentes">
              <button>
                Cadastrar
                <img src="../assets/img/cadastro/cadastro-02.png" alt />
              </button>
            </div>
          </div>
          <SubCarrinho />
          <modal-editado name="modal-editado" height="auto" width="80%" :scrollable="true">
            <div class="modalTitular">
              <h2 class="maze">Complete os dados do responsável</h2>
              <p
                class="maze mb-3"
              >Verificamos que os dependentes são menores de idade. Com isso precisamos de mais algumas informações do responsável. Complete-as abaixo:</p>
              <form v-if="renderComponent">
                <div class="form-group">
                  <label for="email">Nome</label>
                  <input type="text" id="email" v-model="titular.name" :class="errorsTitular.name" />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.name"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                <div class="form-group">
                  <label for="email">CPF</label>
                  <input
                    type="text"
                    id="email"
                    v-model="titular.cpf"
                    v-mask="'###.###.###-##'"
                    :class="errorsTitular.cpf"
                  />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.cpf"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                <div class="form-group">
                  <label for="email">E-mail</label>
                  <input
                    type="text"
                    id="email"
                    v-model="titular.email"
                    :class="errorsTitular.email"
                  />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.email"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                <div class="form-group">
                  <label for="email">Aniversário</label>
                  <input
                    type="text"
                    id="email"
                    v-mask="'##/##/####'"
                    v-model="titular.birth_date"
                    :class="errorsTitular.birth_date"
                  />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.birth_date"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                <div class="form-group">
                  <label for="email">RG</label>
                  <input type="text" id="email" v-model="titular.rg" :class="errorsTitular.rg" />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.rg"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                <div class="form-group">
                  <label for="email">Nome da Mãe</label>
                  <input
                    type="text"
                    id="email"
                    v-model="titular.nome_mae"
                    :class="errorsTitular.nome_mae"
                  />
                  <span
                    class="msgErro"
                    v-if="errorsTitular.nome_mae"
                  >*Verifique se o campo está preechido com dados válidos!</span>
                </div>
                    <div class="form-group">
                      <label>Parentesco com os dependentes</label>
                      <select
                        :class="'list-dependente '+ errorsTitular.parentesco"
                        v-model="titular.parentesco"
                      >
                        <option value selected="selected" disabled>Selecione o grau de parentesco</option>
                        <option value="Pais">Pai</option>
                        <option value="Pais">Mãe</option>
                        <option value="Pais">Nenhum, sou tutelar do(s) dependente(s)</option>
                      </select>
                    </div>

                <div class="btn-cadastro" @click="validaTitular">
                  <button type="button">
                    Efetivar Cadastro
                    <img src="../assets/img/cadastro/cadastro-02.png" alt />
                  </button>
                </div>
              </form>
            </div>
          </modal-editado>
          <!-- <div :class="toggleCarrinho ? 'container-carrinho active': 'container-carrinho'">
            <div class="title-carrinho" @click="toggleCarrinho = !toggleCarrinho">
              <img src="../assets/img/carrinho/carrinho-08.png" alt />

              <div class="count-item">
                <img src="../assets/img/carrinho/carrinho-10.png" alt />
                <p>{{planos.length}}</p>
              </div>

              <p>Meu Carrinho</p>
            </div>

            <div class="valor-total">
              <h3>Total</h3>

              <p>
                R$ {{(total / 100).toFixed(2).replace(".",",")}}
                <span>/mês</span>
              </p>
            </div>

            <div class="container-itens-carrinho">
              <div class="container-itens" v-for="plano in planos" :key="plano.id">
                <h3>{{plano.name}}</h3>
                <p>{{plano.cidade}} - {{plano.estado}}</p>
                <h4>
                  {{ (plano.price / 100).toFixed(2).replace(".",",") }}
                  <span>/ mês</span>
                </h4>
              </div>
            </div>

            <div class="sub-total" id="subtotal">
              <div class="container-text-sub-total">
                <p>Subtotal</p>
                <span>R$ {{ (total / 100).toFixed(2).replace(".",",") }}</span>
              </div>
            </div>

            <div class="valor-total">
              <h3>Total + Taxa</h3>

              <p>
                R$ {{ ( (total + total_taxa) / 100).toFixed(2).replace(".",",") }}
                <span>/mês</span>
              </p>
            </div>
          </div>-->
        </div>
      </section>
    </div>
    <FooterPayment />
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import moment from "moment";
import { validate as validateCPF } from "gerador-validador-cpf";
var jwtDecode = require("jwt-decode");
import Bugsnag from '@bugsnag/js'
import axios from 'axios'

export default {
  name: "cadastro_completo",
  mixins: [myFunction],
  data() {
    return {
      planos: this.$store.getters.getCarrinho,
      total: 0,
      isLoading: false,
      total_taxa: 0,
      user: {},
      lead: {},
      objLead: {},
      dependents: [],
      ja_preencheu: false,
      ja_checkou_usar_meus_dados: false,
      titular: {
        name: "",
        birth_date: "",
        cpf: "",
        rg: "",
        email: "",
        parentesco: "",
        nome_mae: ""
      },
      errorsTitular: {
        name: "",
        birth_date: "",
        cpf: "",
        rg: "",
        parentesco: "",
        email: "",
        nome_mae: ""
      },
      toggleCarrinho: false,
      errors: [],
      renderComponent: true,
      isVerified: null,
      id_lead: null
    };
  },

  async created() {
    let self = this;

    let dados = self.$store.getters.getCarrinho;
    dados = [].map.call(self.$store.getters.getCarrinho, item => {
      return { id: item.id, qtd: item.qtd };
    });

    self.isLoading = true;

    let token = JSON.parse(localStorage.getItem("t"));
    let decoded;

    if(token) {
      decoded = jwtDecode(token)
    }

    self.$http
      .post("verificaandamentov3", { plans: dados })
      .then((res) => res.json())
      .then(
        (response) => {
          self.isLoading = false;
          self.abrangencia = response.abrangencia;
          self.arquivos = response.arquivos;
          if(response.rota != 'dependentes' && response.rota != '/minha-area') {
            self.ja_preencheu = true;
            self.$router.replace(
              self.$route.query.redirect || "/finalizar-compra/" + response.rota
            );
          } else if(response.rota == '/minha-area') {
            self.$router.replace("/minha-area");
          }
      })
      .catch(err => {
        Bugsnag.notify(err)
        self.isLoading = false;
        self.$store.commit("setCarrinho", "");
        localStorage.removeItem("planos");
        if(err.status == 400) {
          self.showAlert(
              "Abrangência Restrita",
              "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
            );
            self.$router.replace(self.$route.query.redirect || "/");
        }
        else if(err.status == 403) {
          self.showAlert("Usuário não autenticado", "É necessário confirmar o email para prosseguir!");
          self.$router.replace(
              self.$route.query.redirect || '/verifica-email'
            );
        }
        else {
          self.showAlert("Erro ao verificar o cadastro", "Não foi possível identificar a origem do erro. Por favor, tente novamente")
          self.$router.replace(self.$route.query.redirect || "/");
        }
      })

    // self.$http
    //   .post("verificaandamentov3", { plans: dados })
    //   .then(res => res.json())
    //   .then(
    //     response => {
    //       self.abrangencia = response.abrangencia;
    //       self.arquivos = response.arquivos;
    //       self.isLoading = false;
    //       if (response.rota != "dependentes") {
    //         self.ja_preencheu = true;
    //       }
    //     },
    //     /*eslint-disable */
    //     err => {
    //       Bugsnag.notify(err)
    //       self.isLoading = false;
    //       self.$router.replace(self.$route.query.redirect || "/");
    //       self.$store.commit("setCarrinho", "");
    //       localStorage.removeItem("planos");
    //       if (err.status == 400) {
    //         self.showAlert(
    //           "Abrangência Restrita",
    //           "Verificamos que você adicionou ao seu carrinho dois planos de diferentes localidades. Devido à abrangência isso não é possível! Escolha o plano que atenda sua região."
    //         );
    //       } else if (err.status == 403) {
    //         localStorage.removeItem("t");
    //         self.showAlert(
    //           "Usuário não autenticado!",
    //           "Faça login para prosseguir com a ação!"
    //         );
    //         self.$router.replace(
    //           self.$route.query.redirect || "/" + err.data.rota
    //         );
    //       } else {
    //         self.showAlert(
    //           "Erro ao verificar cadastro",
    //           "Não foi possível identificar o motivo do erro. Tente novamente por favor"
    //         );
    //       }
    //     }
    //   );

    self.total_taxa = self.planos.reduce(function(total, item) {
      return total + parseFloat(item.setup_fee) * item.qtd;
    }, 0);

    self.total = self.planos.reduce(function(total, item) {
      return total + parseFloat(item.price) * item.qtd;
    }, 0);
    /*eslint-disable */
    let quantidade = 0;
    let qtdPai = self.planos.length;
    for (let i = 0; i < qtdPai; i++) {
      let qtdFilho = self.planos[i].qtd;
      for (let key = 0; key < qtdFilho; key++) {
        quantidade++;
        self.dependents.push({
          useInfoUser: false,
          plan_id: "",
          name: "",
          birth_date: "",
          cpf: "",
          rg: "",
          cns: "",
          email: "",
          parentesco: "",
          nome_mae: "",
          plan_img: ""
        });
        self.errors.push({
          useInfoUser: false,
          plan_id: "",
          name: "",
          birth_date: "",
          cpf: "",
          rg: "",
          cns: "",
          email: "",
          parentesco: "",
          nome_mae: "",
          plan_img: ""
        });
      }
    }

    if(self.ja_preencheu) {
      self.$http.get(`perfil/${decoded.sub}`)
      .then(response => response.json() .then(res => {
        let dependentes_data = res.subscriptions[0].dependentes
        for(let i=0; i<quantidade; i++) {
          self.dependents[i].name = dependentes_data[i].nome_dependente
          self.dependents[i].email = dependentes_data[i].email
          self.dependents[i].cpf = dependentes_data[i].cpf
          self.dependents[i].birth_date = self.padronizaDataBR(dependentes_data[i].birth_date)
          self.dependents[i].rg = dependentes_data[i].rg
          self.dependents[i].cns = dependentes_data[i].cns ? dependentes_data[i].cns : ''
          self.dependents[i].nome_mae = dependentes_data[i].nome_mae
          self.dependents[i].plan_id = dependentes_data[i].plan_id
          self.dependents[i].parentesco = dependentes_data[i].parentesco
          self.dependents[i].naturalidade = dependentes_data[i].naturalidade
        }
      }))
    }

    if (self.planos.length == 1) {
      self.dependents[0].plan_id = self.planos[0].id;
    }
  },
  mounted() {
    let self = this;

    let dados = self.$store.getters.getCarrinho;
    dados = [].map.call(self.$store.getters.getCarrinho, (item) => {
      return { id: item.id, qtd: item.qtd };
    });

    let toggleSelect = Array.from(document.querySelectorAll(".toggle-list"));
    let itens = Array.from(document.querySelectorAll(".list-dependente li"));

    toggleSelect.forEach(function(toggle) {
      toggle.addEventListener("click", function() {
        toggle.querySelector(".list-dependente").classList.toggle("active");
      });
    });

    itens.forEach(function(item) {
      item.addEventListener("click", function() {
        let text = item.querySelector("p").innerHTML;
        let value = item.dataset.value;

        item.parentElement.parentElement.querySelector(
          ".text-form p"
        ).innerHTML = text;

        item.parentElement.parentElement.querySelector("input").value = value;

        item.parentElement.parentElement.classList.toggle("active");
      });
    });

    let token = JSON.parse(localStorage.getItem("t"));
    if (token) {
      var decoded = jwtDecode(token);
      self.$http
        .get("infos_subscriber/"+decoded.sub)
        .then(res => res.json())
        .then(
          response => {
            self.isLoading = false;
            self.titular.name = response.name;
            self.titular.email = response.email;
            self.titular.cpf = response.cpf;
            self.user = response;
          },
          err => {
          Bugsnag.notify(err)
            self.isLoading = false;
            self.showAlert(
              "Erro!",
              "Desculpe, não foi possível buscar seu cadastro."
            );
          }
        );
      self.$http
        .get("usuarios/"+decoded.sub)
        .then(res => res.json())
        .then(
          response => {
            self.isVerified = response.verified_at;
            self.isLoading = false;
            self.isVerified === null && self.$router.replace("/verifica-email");
            self.lead = response;

            self.objLead = {
              nome: `${self.lead.name} ${self.lead.last_name}`,
              email: self.lead.email,
              telefone: self.lead.phones[0].number,
              planos: dados
            }
            
            self.id_lead = JSON.parse(localStorage.getItem("id_lead"));
            if(!self.id_lead){
              self.$http
                .post("leads", self.objLead)
                .then((res) => res.json())
                .then((response) => {
                  localStorage.setItem("id_lead", response.id);
                  self.id_lead = response.id;
                })
                .catch((e) => {
                  self.showAlert("Erro!", e.body.message);
                });
            } else {
              self.$http
                .put(`leads/${self.id_lead}`, {planos: dados, deleted_at: null})
                .then((res) => res.json())
                .then((response) => {
                  localStorage.setItem("id_lead", response.id);
                  self.id_lead = response.id;
                })
                .catch((e) => {
                  self.showAlert("Erro!", e.body.message);
                });
            }
          },
          err => {
          Bugsnag.notify(err)
            self.isLoading = false;
            self.showAlert(
              "Erro!",
              "Desculpe, não foi possível buscar seu cadastro."
            );
          }
        );
    } else {
      localStorage.removeItem("t");
      self.showAlert(
        "Atenção!",
        "Parece que você não tem permissão para acessar essa página!"
      );
      self.$router.replace(self.$route.query.redirect || "/login");
    }
  },
  methods: {
    padronizaDataBR(data) {
      return data ? moment(data).format("DD-MM-YYYY") : "";
    },
    isDate18orMoreYearsOld(day, month, year) {
      return new Date(year+18, month-1, day) <= new Date();
    },
    cadastrarDependentes() {
      let self = this;
      self.dependents.forEach(dependente => {
        dependente.cpf = dependente.cpf.replace(/\D/g, "");
        dependente.rg = dependente.rg.replace(/\D/g, "");
        dependente.cns = dependente.cns.replace(/\D/g, "");
        delete dependente.useInfoUser;
      });

      let pass = [];
      for (let key in self.dependents) {
        if (
          self.dependents[key].name &&
          self.dependents[key].email &&
          self.dependents[key].birth_date &&
          validateCPF(self.dependents[key].cpf) &&
          self.dependents[key].rg &&
          self.dependents[key].plan_id &&
          self.dependents[key].nome_mae &&
          self.dependents[key].parentesco
        ) {
          pass.push(true);
        } else {
          pass.push(false);
          self.dependents.map((element, key, value) => {
            let keys = Object.keys(element);
            keys.forEach(el => {
              if (el != "cns") {
                if (element[el] == "" || element[el] == undefined) {
                  self.errors[key][el] = "error-class";
                }else if(el == 'cpf' && !validateCPF(element[el])){
                  self.errors[key][el] = "error-class";
                } else {
                  self.errors[key][el] = "";
                }
              }
            });
          });
        }
      }
      if (pass.every(item => item == true)) {
        let idade = 0;
        let temMaior = false;
        self.dependents.map((element, key) => {
          var ano = parseInt(element.birth_date.split("/")[2]);
          var mes = parseInt(element.birth_date.split("/")[1]);
          var dia = parseInt(element.birth_date.split("/")[0]);
          if (self.isDate18orMoreYearsOld(dia,mes,ano)) temMaior = true;
        });

        if (!temMaior) {
          self.$modal.show("modal-editado");
        } else {
          self.cadastrarDependentesComTitular(false);
        }
      } else {
        self.showAlert(
          "Erro!",
          "Opa! Verifique se os campos estão preenchidos."
        );
      }
    },
    padronizaDataTimeStamp(data) {
      return data ? moment(data).format("YYYY-MM-DD") : "";
    },
    validaTitular() {
      let self = this;
      if (
        self.titular.name &&
        self.titular.email &&
        self.titular.birth_date &&
        validateCPF(self.titular.cpf) &&
        self.titular.rg &&
        self.titular.parentesco &&
        self.titular.nome_mae
      ) {
        self.cadastrarDependentesComTitular(true);
      } else {
        let keys = Object.keys(self.titular);
        keys.forEach(el => {
          if (self.titular[el] == "" || self.titular[el] == undefined) {
            self.errorsTitular[el] = "error-class";
          } else {
            self.errorsTitular[el] = "";
          }
        });
      }
    },
    cadastrarDependentesComTitular(pegarTitular) {
      let self = this;
      self.isLoading = true;
      var objParam = {}
      if(pegarTitular){
        let titularTemp = Object.assign({}, self.titular);
        titularTemp.birth_date = self.padronizaDataTimeStamp(
          titularTemp.birth_date
        );
        titularTemp.cpf = titularTemp.cpf.replace(/\D/g, "");
        titularTemp.rg = titularTemp.rg.replace(/\D/g, "");
        objParam = {
          dependents: self.dependents,
          titular: titularTemp
        };
      }else{
        objParam = {
          dependents: self.dependents,
          titular: null
        };
      }
      self.$http
        .post("assinantes/dependentes", objParam)
        .then(res => res.json())
        .then(
          response => {
            self.isLoading = false;
            self.$router.replace(
              self.$route.query.redirect || "/finalizar-compra/pagamento/"
            );
          },
          err => {
          Bugsnag.notify(err)
            self.isLoading = false;
            if(pegarTitular)
              self.$modal.hide("modal-editado");
            self.showAlert(
              "Erro!",
              "Desculpe, ocorreu um erro ao cadastrar os dependentes."
            );
          }
        );
    },
    removeClass(index, pos) {
      let self = this;
      self.errors[index][pos] = "";
      self.forceRerender();
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    infoDep(dependente, index) {
      let self = this;
      this.ja_checkou_usar_meus_dados = true

      self.dependents.map((element, key) => {
        if (key == index) {
          element.name = `${self.user.name} ${self.user.last_name}`
          element.email = self.user.email;
          element.cpf = self.user.cpf;
          element.parentesco = "Nenhum, sou responsável financeiro";
        } else {
          element.useInfoUser = false;
          element.name = "";
          element.email = "";
          element.cpf = "";
          element.plan_id = "";
          element.parentesco = "";
        }
      });

      if(self.dependents.every(item => item.useInfoUser == false)){
        this.ja_checkou_usar_meus_dados = false
      }
    },
    setDependentType(value, index) {
      this.dependents[index].parentesco = value;
    },
    setDependentPlan(id, index) {
      this.dependents[index].plan_id = id;
    }
  }
};
</script>

<style scoped>
.subtitle{
    color: #000000;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}
</style>