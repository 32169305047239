<template>
  <div v-if="modal.visible">
    <div class="background">
      <div class="_modal animate__bounceIn">
        <div class="header">
          <span class="title">{{modal.title}}</span>
          <i class="fa fa-times" @click="close"></i>
        </div>

        <div class="message">
            <div v-if="modal.message" v-html="modal.message">
          </div>
        </div>

        <div class="btnContainer"> 
          <button v-if="modal.cancelTitle" 
          @click="modal.cancelFunction"
          class="_btn cancel">{{modal.cancelTitle}}</button>
          <button v-if="modal.confirmTitle" 
          class="_btn success"
          @click="modal.confirmFunction">{{modal.confirmTitle}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data: () => ({
    modal: {
        visible: false,
    },
  }),
    computed: {
    state() {
      return this.$store.getters.modal;
    },
  },
  watch: {
    state(value) {
      this.modal = value;
    },
  },
  methods:{
      close(){
          this.modal.visible = false;
          this.modal.closeEvent();
      }
  }
};
</script>

<style lang="css" scoped>
.background {
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ease 2s;
}

._modal {
  background-color: white;
  width: 90%;
  max-width: 90%;
  height: 60vh;
  z-index: 1;
  color: #50596c;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.26);
  position: relative;
  border-radius: 5px;
}

.header {
  background-color: #c90c53;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  border-radius: 0 5px 0 5px;
  padding: 0px 20px;
}


.header i {
  cursor: pointer;
}

.header .title {
  font-family: "Poppins";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
}

.message p{
  /* color: black; */
  font-size: 16px;
  text-align: justify;

}

.message{
    font-family: "Poppins";
    font-size: 18px;
    text-align: justify;
    margin: 5em 1em 0 1em;
    line-height: 1.3;
}

.btn-modal{
   border: none;
    padding: 15px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    position: absolute;
    font-family: "Poppins";
    text-transform: capitalize;
    bottom: 20px;
    outline: 0;
    background-color: red;
}

.btnContainer{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.btnContainer ._btn:first-child{
  margin: 4em auto 1.5em auto;
}


._btn{
    border: none;
    padding: 15px 20px;
    width: 90%;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    font-family: "Poppins";
    text-transform: capitalize;
    outline: 0;
}

.btnContainer ._btn:first{
  margin-top: 2em;
}

.success{
    right: 20px;
    background-color: #0cc961;
}

.cancel{
    left: 20px;
    background-color: #c90c0c;
}

@media screen and (min-width: 500px) {
  .btnContainer{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin-top: 8em;
  }

  .btnContainer ._btn:first-child{
    margin: 0;
    margin-left: 1.25em;
  }
  .btnContainer ._btn:nth-child(2){
    margin-right: 1.25em;
  }

  ._modal {
    width: 80%;
  }

  ._btn{
    width: 40%;
  }

  ._modal {
    width: 60%;
    max-width: 60%;
    height: 40vh;
  }
}
</style>