<template>
	<div>
		<header class="pagamento">
			<div class="container">
				<div>
					<img class="logo-nav" @click="$router.push({ path: '/' })" src="../assets/img/cadastro/cadastro-01.png" alt="">
				</div>

				<div class="etapas">

						<div @click="irPara(1, 'cadastro')" v-bind:class="cadastro ? 'etapa active' : 'etapa'">
							<span>01</span>
							<p>cadastro</p>
						</div>

						<div @click="irPara(2, 'dependentes')" v-bind:class="dependentes ? 'etapa active' : 'etapa'">
							<span>02</span>
							<p>participantes</p>
						</div>

						<div @click="irPara(3, 'pagamento')" v-bind:class="pagamento ? 'etapa active' : 'etapa'">
							<span>03</span>
							<p>pagamento</p>
						</div>

						<div @click="irPara(4, 'concluido')" v-bind:class="concluido ? 'etapa active' : 'etapa'">
							<span>04</span>
							<p>Concluído</p>
						</div>

					</div>
				</div>
			</header>
		</div>
</template>
<script>

	import myFunction from '../assets/js/my_functions';
	export default {
		name: 'navbar_paymente',
		props: ['cadastro', 'dependentes', 'pagamento', 'concluido'],
		mixins: [myFunction],
		data() {
			return {
				identificador: ''
			}
		},

		created() {
			let self = this;

			if(self.cadastro) self.identificador = 'cadastro'
			if(self.dependentes) self.identificador = 'dependentes'
			if(self.pagamento) self.identificador = 'pagamento';
			if(self.concluido) self.identificador = 'concluido';
		},

		methods: {
			irPara(id, destino) {
				let self = this;

				if(self.identificador != destino){

					if(destino == 'cadastro') {
						self.showAlert("Atenção", "O cadastro poderá ser alterado em 'Minha Área'")
					}

					if(destino == 'dependentes') {
						if(self.dependentes) self.$router.push({path:`/finalizar-compra/${destino}`})
						else self.showAlert("Atenção", "Você ainda não preencheu os formulários necessários para avançar")
					}

					if(destino == 'pagamento') {
						if(self.pagamento) self.$router.push({path:`/finalizar-compra/${destino}`})
						else self.showAlert("Atenção", "Você ainda não preencheu os formulários necessários para avançar")
					}

					if(destino == 'concluido') {
						self.showAlert("Atenção", "Você ainda não preencheu os formulários necessários para avançar")
					}
				}

			}
		}
	}
</script>
<style scoped>
.logo-nav{
			height: 30px !important;
			width: 100%;
}

@media only screen and (max-width: 800px) {
.logo-nav{
			display: none;
}
}
</style>