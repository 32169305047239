<template>
	<div>
    	<loading :active.sync="isLoading" color="#C90C53"></loading>
		<div class="login">
			<div class="container">
				<!-- <div class="voltar">
					<a @click="!esqueceu_senha ? $router.back() : esqueceu_senha = false">
						<img src="../assets/img/login/login-03.png" alt /> Voltar
					</a>
				</div> -->
				<div class="formulario">
					<div class="container-logo">
						<img src="../assets/img/login/login-01.png" alt />
					</div>
					<div class="container-cadastro">
						<p class="texto">
							Seu email foi confirmado com sucesso.
                            Clique no botão abaixo e conclua a compra do seu plano!
						</p>
					</div>

					<div class="btn-login" >
						<button @click="concluir"> Concluir compra </button>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
var jwtDecode = require("jwt-decode");

export default {
  name: "ConfirmaEmail",
  mixins: [myFunction],
  data() {
		return {
			isLoading: false,
			errors: [],
            user: {}
		}
	},

	methods: {
        concluir() {
            let self = this;
            self.$router.replace("/finalizar-compra/dependentes");
        }

	},
    mounted() {
        let self = this;
        self.isLoading = true;
        let url = self.$route.path.split('/')[2].toString();
        self.$http
            .get(`confirmar_email/${url}`)
            .then((res) => res.json())
            .then((response) => {
                localStorage.setItem("t", JSON.stringify(response.token));
                localStorage.removeItem("email_notVerified_comprodonto");
                self.isLoading = false;
            })
            .catch((e) => {
                self.isLoading = false;
                self.showAlert("Erro!", "Não foi possível confirmar seu email. Tente novamente mais tarde!");
                self.$router.replace('/verifica-email');
            });
        
    }
};
</script>

<style>

.botao-login {
    background-color: transparent;
    border: none;
    color: #c70851;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login .container .formulario .container-logo {
	margin-bottom: 60px;
}

.login .container .formulario {
		width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.formulario .form-group {
		position: relative;
    margin-bottom: 28px;
}

.login .container .formulario .form-group label {
    background-color: #c70851;
    color: #ff96db;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.formulario .form-group label {
    color: #4b4b4b;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    position: absolute;
    top: -9px;
    left: 15px;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 10px;
}

.login .container .formulario .form-group input {
    background-color: rgb(232, 240, 254) !important;
    border: 1px solid #ff96db;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.formulario .form-group input {
    height: 42px;
    border: 1px solid #cecece;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 12px;
    font-weight: 700;
    outline: none;
}

.login .container .formulario a {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.texto, .texto-reenvio{
	font-size: 20px;
	color: #fff;
	text-align: justify;
	line-height: 1.1;
}

.texto-reenvio{
	margin: 1em 0 2em 0;
}

.texto span{
	font-weight: 600;
}

@media (max-width: 480px) {
	.login .container .formulario .container-logo img {
    width: 100%;
	}
}

@media (max-width: 480px) {
	.login .container .formulario {
    width: 100%;
	}
}

</style>