<template>
    <div>
        <Navbar night="true"/>

      <div id="detalhe-noticia">
            <section class="detalhe-noticia">
                  <div class="container">
                        <div class="container-noticia">
                              <h1>Diretor Presidente da Uniodonto Uberlândia é reconduzido para novo mandato.</h1>

                              <div class="data-social">
                                    <span class="data">12.09.2019</span>


                                    <div class="social">
                                          <p>Compartilhar</p>

                                          <div class="social-icons">
                                                <a href=""><img src="../assets/img/noticia/noticia-02.png" alt=""></a>
                                                <a href=""><img src="../assets/img/noticia/noticia-03.png" alt=""></a>
                                                <a href=""><img src="../assets/img/noticia/noticia-04.png" alt=""></a>
                                                <a href=""><img src="../assets/img/noticia/noticia-05.png" alt=""></a>
                                          </div>
                                    </div>
                              </div>


                              <div class="text-img">

                                    <div class="img-noticia">
                                          <img src="../assets/img/noticia/noticia-01.png" alt="">
                                    </div>

                                    <div class="text-noticia">
                                          <p>Em Assembleia Geral Ordinária realizada no dia 8 de março, a Uniodonto
                                                Uberlândia elegeu a diretoria que vai administrar a cooperativa pelos
                                                próximos quatro anos. O Diretor Presidente, Dr. Ricardo Manoel Lourenço,
                                                foi reconduzido ao cargo, além de ocupar a presidência da Federação das
                                                Uniodontos de Minas Gerais.</p>

                                          <p>“Mostrar resultado em uma empresa em que muitos são donos é uma
                                                responsabilidade muito grande. Nesses últimos 15 anos na administração
                                                desenvolvemos e implementamos vários projetos para atender às
                                                necessidades dos variados departamentos da cooperativa. Adquirimos um
                                                novo sistema operacional, com a ferramenta de BI (Bussines
                                                Intelligence), aplicativos integrados, para controles mais efetivos e
                                                disponibilização de informações diversas aos clientes, além de fazer um
                                                trabalho voltado para a missão, visão e valores da cooperativa, que é o
                                                que nos norteia”, comentou o Dr. Ricardo Lourenço.</p>

                                          <p>A Uniodonto Uberlândia conta, atualmente, com cerca de 90 mil beneficiários
                                                – aumento de 260% nos últimos 15 anos, e o objetivo da nova diretoria é
                                                continuar expandindo o número de clientes.</p>

                                          <p>“Esse crescimento é fruto do comprometimento e envolvimento dos cooperados,
                                                diretores e colaboradores. Para os próximos quatro anos, vamos continuar
                                                trabalhando para trazer cada vez mais crescimento para a Uniodonto”,
                                                afirmou o Diretor Presidente.</p>

                                          <p>Em Assembleia Geral Ordinária realizada no dia 8 de março, a Uniodonto
                                                Uberlândia elegeu a diretoria que vai administrar a cooperativa pelos
                                                próximos quatro anos. O Diretor Presidente, Dr. Ricardo Manoel Lourenço,
                                                foi reconduzido ao cargo, além de ocupar a presidência da Federação das
                                                Uniodontos de Minas Gerais.</p>

                                          <p>“Mostrar resultado em uma empresa em que muitos são donos é uma
                                                responsabilidade muito grande. Nesses últimos 15 anos na administração
                                                desenvolvemos e implementamos vários projetos para atender às
                                                necessidades dos variados departamentos da cooperativa. Adquirimos um
                                                novo sistema operacional, com a ferramenta de BI (Bussines
                                                Intelligence), aplicativos integrados, para controles mais efetivos e
                                                disponibilização de informações diversas aos clientes, além de fazer um
                                                trabalho voltado para a missão, visão e valores da cooperativa, que é o
                                                que nos norteia”, comentou o Dr. Ricardo Lourenço.</p>

                                          <p>A Uniodonto Uberlândia conta, atualmente, com cerca de 90 mil beneficiários
                                                – aumento de 260% nos últimos 15 anos, e o objetivo da nova diretoria é
                                                continuar expandindo o número de clientes.</p>

                                          <p>“Esse crescimento é fruto do comprometimento e envolvimento dos cooperados,
                                                diretores e colaboradores. Para os próximos quatro anos, vamos continuar
                                                trabalhando para trazer cada vez mais crescimento para a Uniodonto”,
                                                afirmou o Diretor Presidente.</p>

                                          <p>Em Assembleia Geral Ordinária realizada no dia 8 de março, a Uniodonto
                                                Uberlândia elegeu a diretoria que vai administrar a cooperativa pelos
                                                próximos quatro anos. O Diretor Presidente, Dr. Ricardo Manoel Lourenço,
                                                foi reconduzido ao cargo, além de ocupar a presidência da Federação das
                                                Uniodontos de Minas Gerais.</p>

                                          <p>“Mostrar resultado em uma empresa em que muitos são donos é uma
                                                responsabilidade muito grande. Nesses últimos 15 anos na administração
                                                desenvolvemos e implementamos vários projetos para atender às
                                                necessidades dos variados departamentos da cooperativa. Adquirimos um
                                                novo sistema operacional, com a ferramenta de BI (Bussines
                                                Intelligence), aplicativos integrados, para controles mais efetivos e
                                                disponibilização de informações diversas aos clientes, além de fazer um
                                                trabalho voltado para a missão, visão e valores da cooperativa, que é o
                                                que nos norteia”, comentou o Dr. Ricardo Lourenço.</p>

                                          <p>A Uniodonto Uberlândia conta, atualmente, com cerca de 90 mil beneficiários
                                                – aumento de 260% nos últimos 15 anos, e o objetivo da nova diretoria é
                                                continuar expandindo o número de clientes.</p>

                                          <p>“Esse crescimento é fruto do comprometimento e envolvimento dos cooperados,
                                                diretores e colaboradores. Para os próximos quatro anos, vamos continuar
                                                trabalhando para trazer cada vez mais crescimento para a Uniodonto”,
                                                afirmou o Diretor Presidente.</p>

                                          <p>Em Assembleia Geral Ordinária realizada no dia 8 de março, a Uniodonto
                                                Uberlândia elegeu a diretoria que vai administrar a cooperativa pelos
                                                próximos quatro anos. O Diretor Presidente, Dr. Ricardo Manoel Lourenço,
                                                foi reconduzido ao cargo, além de ocupar a presidência da Federação das
                                                Uniodontos de Minas Gerais.</p>

                                          <p>“Mostrar resultado em uma empresa em que muitos são donos é uma
                                                responsabilidade muito grande. Nesses últimos 15 anos na administração
                                                desenvolvemos e implementamos vários projetos para atender às
                                                necessidades dos variados departamentos da cooperativa. Adquirimos um
                                                novo sistema operacional, com a ferramenta de BI (Bussines
                                                Intelligence), aplicativos integrados, para controles mais efetivos e
                                                disponibilização de informações diversas aos clientes, além de fazer um
                                                trabalho voltado para a missão, visão e valores da cooperativa, que é o
                                                que nos norteia”, comentou o Dr. Ricardo Lourenço.</p>

                                          <p>A Uniodonto Uberlândia conta, atualmente, com cerca de 90 mil beneficiários
                                                – aumento de 260% nos últimos 15 anos, e o objetivo da nova diretoria é
                                                continuar expandindo o número de clientes.</p>

                                          <p>“Esse crescimento é fruto do comprometimento e envolvimento dos cooperados,
                                                diretores e colaboradores. Para os próximos quatro anos, vamos continuar
                                                trabalhando para trazer cada vez mais crescimento para a Uniodonto”,
                                                afirmou o Diretor Presidente.</p>

                                    </div>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
        <Footer />
    </div>
</template>
<script>
export default {
    name: 'Detalhe_Noticia'
}
</script>