<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <Navbar night="true" :total="qtd" />
    <Carrinho
      :active="isActive ? true : false"
      @state="closeCarrinho"
      @blur="isActive = false"
      @atualizaTotal="changeTotal"
    />

    <div id="detalhe-plano">
      <section class="detalhe-plano">
        <div class="container">
          <div class="btn-voltar">
            <a @click="voltarPlanos" href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="16"
                viewBox="0 0 10 16"
              >
                <g>
                  <g>
                    <path
                      d="M6.448 15.174c.445.445.89.445 1.334.445.445 0 .89-.223 1.334-.445a1.745 1.745 0 0 0 0-2.446l-4.892-4.89 4.892-4.892a1.745 1.745 0 0 0 0-2.446A1.745 1.745 0 0 0 6.67.5L.445 6.725C.222 6.948 0 7.392 0 8.06c0 .445.222.89.445 1.334z"
                    />
                  </g>
                </g>
              </svg>
              Voltar aos planos
            </a>
          </div>
          <div class="container-detalhe-plano">
            <div class="banner-plano">
              <img :src="plano.foto_plano" alt />
            </div>

            <div class="detalhe-plano-text">
              <a @click="$router.back()" class="texto" href="#">
                <h3>{{ plano.name }}</h3>
                <span>Plano para região {{ operadora.end_cidade }}</span>
              </a>

              <div class="carencia-plano">
                <p>
                  <img
                    src="../assets/img/detalhe-plano/detalhe-plano-07.png"
                    alt
                  />
                  {{
                    plano.carencia
                      ? `Carência mínima de ${plano.carencia} ${
                          plano.carencia > 1 ? "Dias" : "Dia"
                        }`
                      : "Carência zero"
                  }}
                </p>

                <!-- <span v-if="plano.valor_boleto">
                  {{`Valor no boleto: ${(plano.valor_boleto / 100).toFixed(2).replace(".",",")}`}}
                </span> -->
              </div>

           
              <div class="cards d-flex justify-content-center">
              <div class="valor-plano d-flex flex-column justify-content-start">
                <label class="type">No cartão</label>
                <div class="valor">
                  <p>R$</p>
                  <h3>
                    {{ (plano.price / 100).toFixed(2).replace(".", ",") }}
                  </h3>
                  <span>/mês</span>
                </div>

                <div class="taxa">
                  <span>
                    Taxa de Adesão -
                    <strong>{{
                      plano.setup_fee == "0"
                        ? "Gratuita"
                        : "R$ " +
                          (plano.setup_fee / 100).toFixed(2).replace(".", ",")
                    }}</strong>
                  </span>
                </div>
              </div>


              <div class="valor-plano d-flex flex-column justify-content-start" v-if="plano.valor_boleto">
                <label class="type">No Boleto</label>
                <div class="valor">
                  <p>R$</p>
                  <h3>
                    {{
                        `${(plano.valor_boleto / 100)
                          .toFixed(2)
                          .replace(".", ",")}`
                      }}
                  </h3>
                  <span>/mês</span>
                </div>

                <div class="taxa">
                  <span>
                    Taxa de Adesão -
                    <strong>{{
                      plano.setup_fee == "0"
                        ? "Gratuita"
                        : "R$ " +
                          (plano.setup_fee / 100).toFixed(2).replace(".", ",")
                    }}</strong>
                  </span>
                </div>
              </div>

              </div>


            </div>

            <div class="deatlhe-plano-uniodonto">
              <div class="text-img">
                <img src="../assets/img/detalhe-plano/detalhe-09.png" alt />
                <span>Plano para região {{ operadora.end_cidade }}</span>
              </div>

              <div class="btn-add-carrinho" @click="openCarrinho">
                <a>
                  Adicionar ao Carrinho
                  <img src="../assets/img/planos/planos-03.png" alt />
                </a>
              </div>

              <div class="container-documentacao">
                <div class="btn-documentacao">
                  <a>
                    Documentação e contrato
                    <img
                      src="../assets/img/detalhe-plano/detalhe-plano-10.png"
                      alt
                    />
                  </a>
                </div>

                <div class="documentacao-compra">
                  <ul>
                    <li v-for="arquivo in arquivos" :key="arquivo.id">
                      <a :href="arquivo.path" target="_blank">{{
                        arquivo.nome
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="list-planos">
        <div class="tab-nav">
          <ul>
            <li class="iten-nav active" data-id="informacao" @click="verificaClick('botao_informacoes')">
              <p>Informações gerais</p>
            </li>
            <li class="iten-nav" data-id="detalhe"  @click="verificaClick('botao_detalhe')">
              <p>Cobertura</p>
            </li>
            <!--   <li class="iten-nav" data-id="plano">
              <p>Serviços</p>
            </li>-->

            <li class="iten-nav" data-id="area" @click="verificaClick('botao_area')">
              <p>Área de abrangência</p>
            </li>
            <li class="iten-nav" data-id="duvidas" @click="verificaClick('botao_faq')">
              <p>Dúvidas Frequentes</p>
            </li>
          </ul>
        </div>

        <div class="container">
          <div class="container-planos">
            <div id="informacao" class="planos-uni active">
              <div class="container-planos-uni">
                <h1>Informações Gerais</h1>
                <div class="description" v-html="plano.descricao_vendas"></div>
              </div>
            </div>

            <div id="detalhe" class="planos-uni">
              <h1 class="title-plano-resp">Detalhes do Plano</h1>
              <div class="container-planos-uni">
                <h1>{{ plano.name }}</h1>
                <div class="description" v-html="plano.cobertura"></div>
              </div>
              <div class="container-planos-uni"></div>
            </div>
            <div id="area" class="planos-uni">
              <div class="container-planos-uni">
                <h1>Cidades</h1>
                <ul>
                  <li v-for="item in abrangencia" :key="item.id">
                    <p>{{ item.nome }}</p>
                  </li>
                </ul>
              </div>
              <div class="container-planos-uni"></div>
            </div>

            <div id="duvidas" class="planos-uni">
              <div class="container-planos-uni">
                <h1>Dúvidas Frequentes</h1>
                <div class="description" v-html="plano.duvidas"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>


  <script v-html="schema"type="application/ld+json"></script>

<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from "@bugsnag/js";

export default {
  name: "detalhe",
  mixins: [myFunction],
  data() {
    return {
      plano: {},
      operadora: {},
      content: "",
      arquivos: [],
      abrangencia: [],
      servicos: [],
      isActive: false,
      isLoading: false,
      qtd: 0,
      schema: {},
    };
  },
  async created() {
    let self = this;
    self.isLoading = true;
    let id;
    await self.$http
      .get(`detalhes/plano/${self.$route.params.id}`)
      .then((res) => res.json())
      .then(
        (response) => {
          self.isLoading = false;
          self.plano = response.plano;
          id = response.operadora.id;
          self.operadora = response.operadora;
          self.arquivos = response.arquivos;
          self.abrangencia = response.abrangencia;
          self.servicos = response.servicos;

          this.schema = {
            "@context": "https://schema.org",
            "@type": "Product", //tipo do schema, podem ter listas, ranking e avaliações, podemos consultar os demais tipos em https://schema.org,
            image: [this.plano.foto_plano], //Imagem referente ao produto,
            name: this.plano.name,
            description: `Plano odontologico ${this.operadora.end_cidade}`,
            brand: {
              "@type": "Brand",
              name: "Uniodonto", //Marca detentora do produto
            },
            offers: {
              "@type": "Offer",
              url: window.location.href,
              itemCondition: "http://schema.org/NewCondition",
              availability: "http://schema.org/InStock",
              price: this.plano.price,
              priceCurrency: "BRL",
            },
            review: {
              "@type": "Review",
              reviewRating: {
                "@type": "Rating",
                ratingValue: "5",
                bestRating: "5",
              },
              author: {
                "@type": "Person",
                name: "Matheus",
              },
            },
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.4",
              reviewCount: "89",
            },
          };

          /*eslint-disable */
        },
        (err) => {
          Bugsnag.notify(err);
          self.isLoading = false;
          self.showAlert(
            "Erro!",
            "Desculpe, não foi possivel buscar informções sobre determnado plano!"
          );
          self.$router.back();
        }
      );

    await self.$http
      .get(`operadoras/${id}/marketing`)
      .then((res) => res.json())
      .then(
        (response) => {
          self.isLoading = false;
          this.content = response.popup_texto;
          if (response.popup_habilitado && !localStorage.getItem("Assinou")) {
            window.addEventListener("mouseout", self.listenerMouseOut, false);
          }
        },
        (err) => {
          Bugsnag.notify(err);
          self.showAlert(
            "Erro!",
            "Desculpe, ocorreu um erro ao buscar por marketing!"
          );
          self.isLoading = false;
        }
      );
  },
  mounted() {
    this.changeTotal();
    let toggle = document.querySelector(".toggle-mobile");
    let menu = document.querySelector(".modal-menu");
    let closeMenu = document.querySelector(".close-menu");

    let documentacao = document.querySelector(".leitura");
    if (documentacao) {
      let listDocumentacao = document.querySelector(".list-doc");

      documentacao.addEventListener("click", function () {
        listDocumentacao.classList.toggle("active");
      });
    }

    /** nav bar */
    let itensNav = Array.from(document.querySelectorAll(".iten-nav"));
    let listaPlanos = Array.from(document.querySelectorAll(".planos-uni"));

    function removePlanos(item) {
      if (item) {
        itensNav.forEach(function (itemNav) {
          if (item != itemNav) {
            itemNav.classList.remove("active");
          }
        });
      }

      if (listaPlanos) {
        listaPlanos.forEach(function (item) {
          item.classList.remove("active");
        });
      }
    }
    function activePlanos(planos) {
      let itens = Array.from(planos);

      if (itens) {
        itens.forEach(function (item) {
          item.classList.add("active");
        });
      }
    }

    itensNav.forEach(function (item) {
      item.addEventListener("click", function (e) {
        let id = item.dataset.id;

        item.classList.add("active");

        let itens = document.querySelectorAll("#" + id);

        if (document.documentElement.clientWidth <= 600) {
          if (itens) {
            itens.forEach(function (item) {
              item.classList.toggle("active");
            });
          }
        } else {
          removePlanos(item);

          activePlanos(itens);
        }
      });
    });

    if (document.documentElement.clientWidth <= 600) {
      let planosResp = Array.from(document.querySelectorAll(".planos-uni"));

      if (planosResp) {
        planosResp.forEach(function (item) {
          item.classList.remove("active");
        });
      }
    }

    /* DocumentaÃ§Ã£o */
    let btnDocumentacao = document.querySelector(".btn-documentacao");
    let documento = document.querySelector(".documentacao-compra");

    btnDocumentacao.addEventListener("click", () =>
      documento.classList.toggle("active")
    );

    menu.addEventListener("click", function (e) {
      if (e.target.classList[0] == "modal-menu") {
        menu.classList.toggle("active");
      }
    });

    toggle.addEventListener("click", function () {
      menu.classList.toggle("active");
    });

    closeMenu.addEventListener("click", function () {
      menu.classList.toggle("active");
    });
  },

  destroyed() {
    window.removeEventListener("mouseout", this.listenerMouseOut, false);
  },

  methods: {
    closeMenu(e) {
      e.srcElement.parentElement.classList.remove("active");
    },
    listenerMouseOut(e) {
      let self = this;
      let event = e ? e : window.event;
      event.preventDefault();
      var from = event.relatedTarget || event.toElement;
      if ((!from || from.nodeName == "HTML") && event.clientY <= 500) {
        self.$store.commit("modal", {
          visible: true,
          message: self.content,
          closeEvent: window.removeEventListener(
            "mouseout",
            self.listenerMouseOut,
            false
          ),
        });
      }
    },
    changeTotal() {
      let carrinho = this.$store.getters.getCarrinho;
      let quantidade = 0;

      for (let item of carrinho) {
        quantidade += item.qtd;
      }

      this.qtd = quantidade;
    },
    openCarrinho() {
      let self = this;
      self.verificaClick('botao_add_carinho')
      self.my_plans = self.$store.getters.getCarrinho;

      let indexPlano = self.my_plans.findIndex((x) => x.id == self.plano.id);
      if (indexPlano >= 0) {
        self.my_plans[indexPlano].qtd = self.my_plans[indexPlano].qtd + 1;
      } else {
        self.my_plans.push(self.plano);
      }
      self.$store.commit("setCarrinho", self.my_plans);

      self.changeTotal();

      self.isActive = !self.isActive;

      // if (self.$ga.ecommerce) {
      //   self.$ga.ecommerce.addItem({
      //     id: self.plano.id, // Transaction ID. Required.
      //     name: self.plano.name, // Product name. Required.
      //     price: self.plano.price, // Unit price.
      //     quantity: "1", // Quantity.
      //   });
      // }
    },
    closeCarrinho() {
      let self = this;
      self.isActive = false;
    },

    verificaClick(local){
      let self = this;
      let id_lead = JSON.parse(localStorage.getItem("id_lead"));
      const objEvento = {
        acao: 'CLICK',
        leads_id: id_lead,
        identificacao: local
      }
      self.$http.post("eventos_sites", objEvento)
        .then((res) => {
        }).catch((e) => {
        })
    },
    voltarPlanos(){
      let self = this;
      const arrayURL = self.$router.history.current.fullPath.split("/detalhes");
      const newURL = arrayURL[0].replace(/uniodonto/i, 'planos')
      self.$router.push({ path: newURL });
      //self.$router.back();
    }
  },
};
</script>
<style scoped>
.valor-plano {
  border: solid 1px rgb(211, 211, 211);
  padding: 20px;
  position: relative;
  margin: 0px 5px;
}

.valor-plano .type{
  font-family: 'Poppins','sans-serif';
  font-weight: bold;
  font-size: 14px;
  height: 20px;
  padding: 0px 10px;
  background-color: white;
  position: absolute;
  top: -8px;
}

.detalhe-plano .container .container-detalhe-plano .detalhe-plano-text .valor-plano{
  align-items: flex-start;
  margin-top: 20px;
}

.detalhe-plano .container .container-detalhe-plano .detalhe-plano-text{
  margin: 33px;
}



.detalhe-plano .container .container-detalhe-plano .detalhe-plano-text .valor-plano span {
  margin-left: 0;
}

.detalhe-plano .container .container-detalhe-plano .detalhe-plano-text .taxa {
  margin-top: 10px;
}

.detalhe-plano .container .container-detalhe-plano .detalhe-plano-text .taxa span{
  font-size: 10px;
}

.detalhe-plano {
  height: 100%;
  padding: 50px 0px;
}

@media (max-width: 600px)
{
  .cards
   {
    display: flex !important;
    flex-direction: column !important;
   }
}
</style>