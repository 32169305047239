import Vue from 'vue'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

export default {
    methods:{
        async ajax(metodo, url, pacote, headers = null){
            let info;
            await axios[metodo](url, pacote,headers)
            .then(response => {
                info = response.data
            })
            return info
        },
        showAlert(title, message) {
            let options = {title: title, size: 'lg'}
            this.$dialogs.alert(message, options);
        },
        limpaCarrinho(){
            this.$store.getters.getCarrinho = []
            localStorage.removeItem("planos");
        },
    } 
}