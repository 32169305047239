<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <NavBarPayment cadastro="true" dependentes="true" opcoes="true" />
    <div class="container">
      <div class="cards">
        <div @click="type = 'cartao'" :class="`${type == 'cartao' ? 'card selected' : 'card'} `">
          <img src="../assets/img/opcoes/cartao-de-credito.svg" />
          <span>Cartão de Crédito</span>
        </div>
        <span>OU</span>
        <div @click="type = 'boleto'" :class="`${type == 'boleto' ? 'card selected' : 'card'} `">
          <img src="../assets/img/opcoes/fatura.svg" />
          <span>Boleto</span>
        </div>
      </div>

      <div @click="handlePaymentOption" class="btn-cadastro w-40 mb-5 float-right">
        <button type="button">
          Confirmar meio de pagamento
          <img src="../assets/img/cadastro/cadastro-02.png" alt />
        </button>
      </div>
    </div>
    <div class="footer">
      <FooterPayment />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: false,
      type: "boleto",
      token: ""
    };
  },
  mounted() {},
  methods: {
    // handlePaymentOption() {
    //   if (this.type == "boleto") {
    //     PagSeguroDirectPayment.onSenderHashReady(function(response) {
    //       return false;
    //       if (response.status == "error") {
    //         return false;
    //       }
    //       var hash = response.senderHash; //Hash estará disponível nesta variável.
    //     });
    //   }
    // }
  }
};
</script>
<style  scoped>
.card {
  border: solid 3px rgb(211, 211, 211);
  border-radius: 10px;
  padding: 5rem;
  margin: 2rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cards span {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: bold;
}

.cards .card span {
  margin-top: 2rem;
}

.float-right {
  float: right;
}

.footer {
  margin-top: 15rem;
  width: 100%;

  position: absolute;
}

.w-40 {
  width: 60em;
}

.purple {
  color: #c90c53 !important;
}

.selected {
  border: solid 3px #c90c53;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  flex-wrap: wrap;
}
</style>