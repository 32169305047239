<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <Navbar night="true" />
    <div id="planos">
      <Modal />
      <section class="planos">
        <div class="container">
          <div v-if="detalhes.foto_capa" class="foto-container">
            <img
              v-if="isMobile"
              class="foto-capa"
              :src="detalhes.foto_capa_mobile"
            />
            <img v-else class="foto-capa" :src="detalhes.foto_capa" />
          </div>
          <div class="plano-text">
            <h1>Planos Uniodonto</h1>
            <span>
              {{
                $store.getters.getCidade
                  ? $store.getters.getCidade
                  : detalhes.end_cidade
              }}
              <!-- <img src="../assets/img/planos/planos-01.png" alt=""> -->
            </span>
          </div>

          <div class="cards-planos">
            <div
             
              class="card"
              v-for="plano in planos"
              :key="plano.id"
            >
              <div @click="openDetail(plano.id)" class="container-img">
                <img :src="plano.foto_plano" alt />
              </div>

              <div class="container-text">
                <h3>{{ plano.name }}</h3>

                <p>{{ detalhes.name }} - {{ detalhes.end_estado }}</p>

                <div class="container-valor">
                  <img src="../assets/img/planos/plano-05.png" alt />
                  <p>
                    R$ {{ (plano.price / 100).toFixed(2).replace(".", ",") }} /
                    <span>mês</span>
                  </p>
                </div>
              </div>

              <div class="btn-planos"  @click="openDetail(plano.id)">
                <a>
                  Ver Detalhes
                  <img src="../assets/img/planos/plano-06.png" alt />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from "@bugsnag/js";

export default {
  name: "planos",
  mixins: [myFunction],
  data() {
    return {
      planos: [],
      isMobile: false,
      detalhes: {},
      my_plans: [],
      isActive: false,
      isLoading: false,
      showModal: false,
      content: '',
      qtd: 0,
      estado: this.$route.params.estado,
      cidade: this.$route.params.cidade,
    };
  },
  async created() {
    let self = this;
    
 
    self.isLoading = true;
    let id; 
    await self.$http.get(`operadoras/${self.$route.params.estado}/${self.$route.params.cidade}/detalhes`)
      .then((res) => res.json())
      .then(
        (response) => {
          self.isLoading = false;
          self.planos = response.planos;
          self.detalhes = response.detalhes;
          id = response.detalhes.id;
          let tamanhoString, inicio, fim;
          if(self.detalhes.foto_capa) {
            tamanhoString = self.detalhes.foto_capa.length;
            inicio = self.detalhes.foto_capa.substr(0, tamanhoString - 4);
            fim = self.detalhes.foto_capa.substr(tamanhoString - 4, 4);
          }
          self.detalhes.foto_capa_mobile = `${inicio}-mobile${fim}`;

          self.verificaTamanho();
          window.addEventListener("resize", () => self.verificaTamanho());

          
          
        },
        (err) => {
          Bugsnag.notify(err);
          self.showAlert(
            "Erro!",
            "Desculpe, ocorreu um erro ao buscar determinado plano!"
          );
          self.isLoading = false;
        }
      );

      await self.$http.get(`operadoras/${id}/marketing`)
      .then((res) => res.json())
      .then(
        (response) => {
          self.isLoading = false;
          this.content = response.popup_texto;
           if(response.popup_habilitado && !localStorage.getItem("Assinou" && false)){    
            window.addEventListener("mouseout", self.listenerMouseOut, false)
          }
        },
        (err) => {
          Bugsnag.notify(err);
          self.showAlert(
            "Erro!",
            "Desculpe, ocorreu um erro ao buscar por marketing!"
          );
          self.isLoading = false;
        }
      );
  }, 

  mounted() {
    let self = this;
    this.qtd = this.$store.getters.getCarrinho.length;
    let toggle = document.querySelector(".toggle-mobile");
    let menu = document.querySelector(".modal-menu");
    let closeMenu = document.querySelector(".close-menu");

    menu.addEventListener("click", function (e) {
      if (e.target.classList[0] == "modal-menu") {
        menu.classList.toggle("active");
      }
    });

    toggle.addEventListener("click", function () {
      menu.classList.toggle("active");
    });

    closeMenu.addEventListener("click", function () {
      menu.classList.toggle("active");
    });

    // select home

    let select = document.querySelector(".select");
    let itensSelect = Array.from(document.querySelectorAll(".list li"));

    if (select) {
      select.addEventListener("click", function () {
        select.querySelector(".container-list").classList.toggle("active");
      });

      itensSelect.forEach(function (iten) {
        iten.addEventListener("click", function () {
          //capturando data id
          let id = parseInt(iten.dataset.id);

          //set valor no input
          document.getElementById("cidade").value = id;

          //set valor no select
          select.querySelector("span").innerHTML = iten.querySelector(
            "p"
          ).innerHTML;
        });
      });
    }

    let closeModa = document.querySelector(".modal-carrinho");
    let openModalItens = Array.from(
      document.querySelectorAll(".cards-planos .card")
    );
    let body = document.querySelector("body");

    if (closeModa) {
      openModalItens.forEach(function (iten) {
        iten.addEventListener("click", function () {
          body.classList.toggle("active");
          document.querySelector(".modal-carrinho").classList.toggle("active");
        });
      });

      closeModa.addEventListener("click", function (e) {
        let click = e.target.classList[0];
        if (click == "modal-carrinho" || click == "close-carrinho") {
          body.classList.toggle("active");
          closeModa.classList.toggle("active");
        }
      });

      let qtdItens = document.querySelectorAll(".qtd-item .contador");
      // let total = document.querySelector('.valor-total-item');

      qtdItens.forEach(function (item) {
        item.addEventListener("click", function () {
          let total = item.parentElement.querySelector(".valor-total-item");
          if (item.classList.value.includes("soma")) {
            if (total.innerHTML != "") {
              if (parseInt(total.innerHTML) < 9) {
                total.innerHTML = `0${parseInt(total.innerHTML) + 1}`;
              } else {
                total.innerHTML = parseInt(total.innerHTML) + 1;
              }
            }
          } else {
            if (total.innerHTML != "") {
              if (1 < parseInt(total.innerHTML)) {
                if (parseInt(total.innerHTML) <= 10) {
                  total.innerHTML = `0${parseInt(total.innerHTML) - 1}`;
                } else {
                  total.innerHTML = parseInt(total.innerHTML) - 1;
                }
              }
            }
          }
        });
      });
    }
  },

  destroyed() {
    window.removeEventListener('mouseout', this.listenerMouseOut, false);
    window.removeEventListener("resize", self.verificaTamanho, false);

  },
  methods: {
    listenerMouseOut(e){
      let self = this;
      let event = e ? e : window.event;
      event.preventDefault();
      var from = event.relatedTarget || event.toElement;
      if ((!from || from.nodeName == "HTML") && event.clientY <= 500) {
        self.$store.commit("modal", { visible: true, message: self.content, closeEvent: window.removeEventListener('mouseout', self.listenerMouseOut, false) });
      }
    },
    verificaTamanho() {
      window.screen.width <= 770
        ? (this.isMobile = true)
        : (this.isMobile = false);
    },
    openDetail(id) {
      let self = this;
      self.$router.push(
        `/uniodonto/${self.estado}/${self.cidade}/detalhes/planos/${id}`
      );
    },
    /*  openCarrinho(plano) {
      let self = this;
      
      self.my_plans = self.$store.getters.getCarrinho;

      let indexPlano = self.my_plans.findIndex(x => x.id == plano.id);
      if (indexPlano >= 0) {
        self.my_plans[indexPlano].qtd = self.my_plans[indexPlano].qtd + 1;
      } else {
        self.my_plans.push(plano);

      }
      self.$store.commit("setCarrinho", self.my_plans);


      self.$ga.ecommerce.addItem({
        id: plano.id, // Transaction ID. Required.
        name: plano.name, // Product name. Required.
        price: plano.price, // Unit price.
        quantity: "1" // Quantity.
      });

      self.isActive = !self.isActive;
    }, */
    /* closeCarrinho() {
      let self = this;
      self.isActive = false;
    } */
  },
};
</script>