<template>
  <div>
    <loading :active.sync="isLoading" color="#C90C53"></loading>
    <Navbar night="true" />
    <div id="contato">
      <section class="atendimento-contato">
        <div class="container">
          <div class="container-atendimento">
            <div class="text-form">
              <h1>Contato</h1>

              <h3>
                Preencha os campos abaixo e nos envie sua mensagem, estamos a disposição para te ajudar. Será um prazer atendê-lo.
              </h3>

              <!-- <h2>Envie uma mensagem</h2>
                                    <p>Envie sua mensagem utilizando os campos abaixo, responderemos o mais breve
              possível.</p>-->
            </div>

            <form v-if="renderComponent">
              <div class="container-form contato">
                <input
                  type="text"
                  :class="errors[0]"
                  
                  placeholder="Nome"
                  v-model="nome"
                />
              </div>
              <div class="container-form contato">
                <input
                  type="text"
                  :class="errors[1]"
                  
                  placeholder="Email"
                  v-model="email"
                />
              </div>
              <div class="container-form contato">
                <input
                  type="tel"
                  v-mask="'(##) # ####-####'"
                  :class="errors[2]"
                  
                  placeholder="Telefone"
                  v-model="telefone"
                />
              </div>

              <div class="container-form contato">
                <textarea
                  name
                  id
                  :class="errors[3]"
                  
                  cols="30"
                  rows="10"
                  v-model="mensagem"
                  placeholder="Mensagem"
                ></textarea>
              </div>

              <div @click="sendContact" class="btn-contato">
                <button>Enviar</button>
                <div class="container-btn-img">
                  <img src="../assets/img/contato/contato-03.png" alt />
                </div>
              </div>
            </form>
          </div>

          <div class="container-contato">
            <div class="container-tel">
              <img src="../assets/img/contato/contato-01.png" alt />

              <div class="tel">
                <p>+55 (35) 9 3300-7935</p>
              </div>

              <div class="email">
                <p>contato@comprodonto.com.br</p>
              </div>

              <div class="siga">
                <p>siga-nos</p>
                <a href="https://www.instagram.com/comprodontooficial/" target="_blank">
                  <img z src="../assets/img/contato/contato-04.png" alt />
                </a>
                <a href="https://www.facebook.com/Comprodonto-2276895712632985/" target="_blank">
                  <img src="../assets/img/contato/contato-05.png" alt />
                </a>
              </div>
            </div>

            <!--    <div class="container-endereco">
                                    <img src="../assets/img/contato/contato-02.png" alt="">

                                    <div class="endereco">
                                          <p>Rua Tutóia, 51, Jardim dos Estados
                                                Poços de Caldas (MG)</p>
                                    </div>

            </div>-->
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from '@bugsnag/js'

export default {
  name: "contato",
  mixins: [myFunction],
  data() {
    return {
      nome: "",
      email: "",
      telefone: "",
      mensagem: "",
      errors: [],
      isLoading: false,
      renderComponent: true
    };
  },
  methods: {
    removeClass(pos) {
      let self = this;
      self.errors[pos] = "";
      self.forceRerender();
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    sendContact() {
      let self = this;
      self.errors = []
      self.nome == "" ? self.errors.push("error-class") : self.errors.push("");
      self.email == "" ? self.errors.push("error-class") : self.errors.push("");
      self.telefone == ""
        ? self.errors.push("error-class")
        : self.errors.push("");
      self.mensagem == ""
        ? self.errors.push("error-class")
        : self.errors.push("");

      if (self.nome && self.email && self.telefone && self.mensagem) {
        self.isLoading = true;

        self.$http
          .post("contato", {
            nome: self.nome,
            email: self.email,
            telefone: self.telefone,
            mensagem: self.mensagem
          })
          .then(res => res.json())
          .then(
            response => {
              if (response) {
                self.isLoading = false;
                self.showAlert(
                  "Sucesso!",
                  "Sua mensagem foi enviada com sucesso, em breve entraremos em contato."
                );
                self.nome = ""
                self.email = ""
                self.telefone = ""
                self.mensagem = ""
              }
            },
            /*eslint-disable */
            err => {
          Bugsnag.notify(err)
              self.isLoading = false;
              self.showAlert("Erro!", "Desculpe, ocorreu um erro ao enviar sua mensagem.");
              /*eslint-enable */
            }
          );
      } else {
        self.showAlert(
          "Atenção!",
          "Preencha os campos marcados para que possamos enviar sua mensagem."
        );
      }
    }
  }
};
</script>