<template>
  <div :class="active ? 'modal-menu active': 'modal-menu'">
    <div class="container-menu">
      <div class="logo-close">
        <img src="../assets/img/header/dark-header-01.png" alt />
        <img
          @click="updateModalState"
          src="../assets/img/header/header-05.png"
          class="close-menu"
          alt
        />
      </div>

      <div class="list-menu">
        <ul>
          <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="$router.replace({ path: '/sobre'})">Sobre nós</a>
          </li>
          <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="$router.replace({ path: '/duvidas'})">Dúvidas</a>
          </li>
          <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="$router.replace({ path: '/contato'})">Contato</a>
          </li>
          <li @click="verificaClick('blog')">
            <img src="../assets/img/header/header-06.png" alt />
            <a target="_blank" href="https://blog.comprodonto.com.br">Blog</a>
          </li>
          <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="gotoProfile">Minha Área</a>
          </li>
           <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="showTerms">Termos de uso</a>
          </li>
           <li>
            <img src="../assets/img/header/header-06.png" alt />
            <a @click="showPrivacePolicies">Política de privacidade</a>
          </li>  
        </ul>
      </div>

      <div class="contato-menu">
        <div class="tel-menu">
          <img src="../assets/img/header/header-07.png" alt />
          <p>+55 (35) 9 3300-7935</p>
        </div>

        <div class="contato-email">
          <img src="../assets/img/header/header-08.png" alt />
          <p>contato@comprodonto.com.br</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myFunctions from '../assets/js/my_functions'
export default {
  name: "modal-lateral",
  props: ["active"],
  mixins: [myFunctions],
  data() {
    return {
      state: ""
    };
  },
  methods: {
    verificaClick(local){
      let self = this;
      let id_lead = JSON.parse(localStorage.getItem("id_lead"));
      const objEvento = {
        acao: 'CLICK',
        leads_id: id_lead,
        identificacao: local
      }
      self.$http.post("eventos_sites", objEvento)
        .then((res) => {
        }).catch((e) => {
        })
    },
    updateModalState: function() {
      this.$emit("state");
    },
     showTerms() {
      let self = this;
      let options = {
        title: "Termos de uso",
        okLabel: "Fechar",
        cancelLabel: ""
      };
      self.$dialogs
        .confirm(
          ` <h1>Bem-vindo à COMPRODONTO!</h1>
            <h2>O que é a COMPRODONTO?</h2>
            A COMPRODONTO consiste em um ambiente virtual disponibilizado através do site
            www.comprodonto.com.br. No ambiente virtual da COMPRODONTO, os clientes
            terão acesso à publicidade de planos odontológicos oferecidos pelos parceiros da
            COMPRODONTO (“Parceiros”), podendo o cliente fazer a comparação de preços
            entre os planos odontológicos ofertados de acordo com sua geolocalização e podendo
            contratá-los diretamente dos próprios Parceiros através da plataforma digital da
            COMPRODONTO. Destacamos que os pagamentos dos produtos adquiridos por
            intermédio do site da COMPRODONTO deverão ser pagos obrigatoriamente através
            do intermediador de pagamento disponibilizado pela COMPRODONTO. A
            COMPRODONTO é opção ideal para facilitar a contratação de planos odontológicos
            pelos clientes com as melhores opções de planos de acordo com a localização dos
            clientes.
            Para a utilização dos serviços oferecidos pela COMPRODONTO é necessário que
            você leia e concorde integralmente com os termos de uso abaixo especificados. A
            utilização da COMPRODONTO pressupõe sua aceitação dos respectivo TERMO DE
            USO. Em caso de dúvidas, consulte-nos por meio da página eletrônica
            www.comprodonto.com.br ou via e-mail contato@comprodonto.com.br.

            <h2>TERMOS E CONDIÇÕES DE USO DA “COMPRODONTO”</h2>

            Esses Termos e Condições de Uso da COMPRODONTO (“Termo”) aplicam-se aos
            usuários dos serviços oferecidos pela empresa MAZZEAPPS
            DESENVOLVIMENTO DE SOFTWARE LTDA, inscrito no CNPJ/MF sob o nº. 25.243.724/0001-97, com endereço na Rua
            Oscar Marcondes de Paula, nº. 40, Jardim Vitória, Poços de Caldas/MG, CEP 37701-
            289, e-mail: contato@mazeapps.com.br, por meio do site www.comprodonto.com.br.
            Qualquer pessoa (adiante usuário), que utilize os serviços da COMPRODONTO
            deverá ter lido, entendido e aceitado estes Termos e Condições de Uso e a Política de
            Privacidade da COMPRODONTO.

            <h2>1. Definições</h2>
            1.1. Para fins de interpretação do presente documento, os termos e expressões abaixo
            terão os seguintes significados:
            a. COMPRODONTO: o marketplace de planos odontológicos operado pela
            empresa MAZZEAPPS DESENVOLVIMENTO DE SOFTWARE LTDA, inscrito no CNPJ/MF sob o nº.
            25.243.724/0001-97, com endereço na Rua Oscar Marcondes de Paula, nº. 40,
            Jardim Vitória, Poços de Caldas/MG, CEP 37701-289, e-mail:
            contato@mazeapps.com.br.
            b. Usuários: todas as pessoas que se cadastram no site COMPRODONTO e
            recebam uma Conta de Acesso individual e exclusiva, Clientes ou não.

            c. Clientes: todas as pessoas que adquiriram os produtos ou serviços oferecidos no
            site da COMPRODONTO pelos Parceiros.
            d. Conta de Acesso: credencial digital formada pela combinação de login e senha,
            única e intransferível para cada Usuário do site da COMPRODONTO, que dá
            permissão ao Usuário/Cliente ingressar na área restrita do site da
            COMPRODONTO, onde é possível verificar a situação dos pedidos, precisão
            dos dados cadastrais e situação de solicitações de suporte.
            e. Parceiros: pessoas jurídicas que oferecem seus próprios produtos e serviços
            utilizando o site da COMPRODONTO, sendo responsáveis pelas
            características, coberturas, preço, envio de documentações e as demais
            condições de sua oferta, sem interferência da COMPRODONTO.
            f. Informações pessoais: qualquer informação que possa ser usada para identificar
            um indivíduo, incluindo, mas não se limitado ao seu nome, qualificação pessoal,
            inscrição no CPF/MF ou CNPJ/MF, endereço físico ou de e-mail, número de
            telefone, demais dados de contato, dados de cobranças, ou quaisquer outros
            dados que possam ser suficientes para serem vinculados a essas informações.
            g. Informações de acesso: qualquer informação que possa ser usada para
            identificar o Internauta, sua solicitação e atividades na Internet, a interação com
            um serviço, as mensagens enviadas ou divulgadas, o Endereço de Protocolo de
            Internet (&amp;quot;IP&amp;quot;) e horário de conexão, as URLs do site de origem e do site que o
            usuário pode visitar em seguida, o tipo e o idioma do navegador, um ou mais
            Cookies que possam identificar o seu navegador ou a sua conta, informações
            gerais e específicas sobre o hardware do seu computador, telefone móvel ou
            outro dispositivo, conexão à Internet, sistema operacional e número da versão
            utilizada.
            h. Cookie: arquivo de texto enviado pelo servidor do site da COMPRODONTO
            para o computador do internauta, com a finalidade de identificar o seu
            computador, personalizar os acessos e obter dados de navegação, como páginas
            visitadas ou links clicados. Um Cookie é atribuído individualmente a cada
            computador; ele não pode ser usado para executar programas, tampouco infectar
            computadores com códigos maliciosos de qualquer espécie, tais como vírus,
            trojans etc., e pode ser lido apenas pelo servidor que o enviou.
            i. Criptografia: nome dado ao processo de codificação de informações. As
            informações são codificadas (embaralhadas) na origem e decodificadas no
            destino, dificultando, dessa forma, que sejam decifradas durante o tráfego na
            internet.
            j. Firewall: nome dado ao dispositivo físico e/ou lógico de uma rede de
            computadores que tem por objetivo aplicar uma política de segurança a um
            determinado ponto de controle da rede. Sua função consiste em regular o tráfego
            de dados entre redes distintas e impedir a transmissão e/ou recepção de acessos
            nocivos ou não autorizados de uma rede para outra.
            k. Descompilação: é o ato de atuar inversamente a uma compilação, ou seja, é a
            transformação de código objeto em código fonte;

            <h2>2. Objeto</h2>
            2.1. O objeto deste Termo é estabelecer as regras e condições para a utilização da
            COMPRODONTO pelos usuários/clientes através do site da COMPRODONTO.
            2.2. A COMPRODONTO não autoriza Parceiros a divulgarem seus produtos e/ou
            serviços no seu site sem prévia análise e a celebração do instrumento contratual
            competente.

            <h2>3. Serviços oferecidos aos usuários</h2>
            3.1. A COMPRODONTO, através do seu site, possibilita a escolha, pelos usuários, de
            planos odontológicos oferecidos pelas operadoras de planos odontológicos, previamente
            cadastrados, a efetivar solicitações para contratação de planos odontológicos fornecidos
            pelos respectivos Parceiros, de acordo com a oferta de cada um para a área de
            geolocalização informada pelo Usuário/Cliente.
            3.2. A intermediação de pagamento será realizada através do gateway disponível no
            carrinho de compras do site. A COMPRODONTO não intermediará vendas fora de seu
            site ou com pagamento não intermediado pelo intermediador de pagamento disponível
            em seu site.
            3.3. O usuário está ciente de que os serviços oferecidos pela COMPRODONTO são
            meramente de intermediação entre os Clientes e os Parceiros, não sendo atribuição da
            COMPRODONTO o envio de documentações, contratos e quaisquer outros
            documentos referentes aos planos odontológicos eventualmente contratados através do
            site da COMPRODONTO, que serão de responsabilidade exclusiva dos Parceiros.
            3.4. Eventuais reclamações decorrentes de falhas na prestação de serviços pelos
            Parceiros, deverão ser feitas pelo Cliente diretamente à COMPRODONTO através
            dos canais de atendimento que serão disponibilizados pela COMPRODONTO em seu
            site, ficando esta responsável pela intermediação entre o Cliente e o Parceiro.
            3.5. Todos os planos odontológicos oferecidos na COMPRODONTO respeitam, ao
            menos, as coberturas mínimas previstas no Anexo I da resolução Normativa nº. 428 de 7
            de novembro de 2017 da ANS, sendo vedada a comercialização de qualquer plano
            odontológico que não atenda aos requisitos legais vigentes.
            <h2>4. Cadastro dos usuários</h2>
            4.1. Para utilização dos serviços descritos acima, o usuário deverá se cadastrar no site da
            COMPRODONTO. O usuário deverá ter capacidade jurídica para atos da vida civil e
            deverá prestar todas as informações com veracidade e exatidão, assumindo a
            responsabilidade pela sua inexatidão, bem como se compromete a mantê-los
            devidamente atualizadas. A COMPRODONTO não assume qualquer responsabilidade
            em caso de danos causados pela inexatidão dos dados informados.
            4.2. O Usuário que se cadastrar no site da COMPRODONTO utilizando dados falsos
            ou de terceiros poderá incorrer nos crimes de falsidade ideológica, estelionato ou outros
            delitos mais graves, podendo responder civil ou criminalmente por tal conduta, nos
            termos da lei.
            4.3. Em caso de informações incorretas, inverídicas ou não confirmadas, bem assim na
            hipótese da negativa em corrigi-las ou enviar documentação que comprove a correção, a
            COMPRODONTO se reserva o direito de não concluir o cadastramento em curso ou,
            ainda, de bloquear o cadastro já existente, impedindo o usuário de utilizar os serviços
            on-line até que, a critério da COMPRODONTO, a situação de anomalia esteja
            regularizada. A COMPRODONTO se reserva o direito de impedir, a seu critério,
            novos cadastros, ou cancelar os já efetuados, em caso de ser detectada anomalia que, em
            sua análise, seja revestida de gravidade ou demonstre tentativa deliberada de burlar as
            regras aqui descritas, obrigatórias para todos os usuários. Também agirá a
            COMPRODONTO de tal forma caso verifique descumprimento, pelo usuário, de
            qualquer obrigação prevista no presente Termo.
            4.4. Efetuado, com sucesso, o cadastro, o usuário terá acesso aos serviços por meio de
            login e senha, dados esses que se compromete a não divulgar a terceiros, ficando sob
            sua exclusiva responsabilidade qualquer solicitação de serviço que seja feita com o uso
            de login e senha de sua titularidade.

            4.5. O usuário será automaticamente descadastrado e ficará impedido de realizar novo
            cadastro no site da COMPRODONTO quando for detectada a utilização de qualquer
            mecanismo engenhoso tendentes a descompilação do site da COMPRODONTO,
            objetivando a captura de informações na base de dados ou levantamento de protocolos
            de comunicação da COMPRODONTO.
            <h2>5. Obrigações dos usuários</h2>
            5.1. Para realizar transações no site da COMPRODONTO, o usuário deverá ler,
            compreender e aceitar expressamente o presente Termo e Condições de Uso da
            COMPRODONTO, bem como os demais documentos neste relacionados,
            especialmente a Política de Privacidade.
            5.2. Efetuado com sucesso o cadastro do usuário, este se obriga a não divulgar a
            terceiros login e senha de acesso, nem permitir o uso de tais informações por terceiros,
            responsabilizando-se pelas consequências do uso de login e senha de sua titularidade.
            5.3. O Cliente declara estar ciente que o site da COMPRODONTO presta apenas o
            serviço de intermediação entre os Clientes e os Parceiros, reconhecendo que ao
            contratar um plano odontológico diretamente de um Parceiro, a COMPRODONTO
            não possuirá qualquer responsabilidade pelos serviços prestados, coberturas e envio de
            documentações de responsabilidade do Parceiro, cabendo à COMPRODONTO
            apenas promover a aproximação entre os Clientes e os Parceiros, por meio de seu site.
            5.4. Ao realizar transações no site da COMPRODONTO, o Cliente autoriza,
            expressamente, que a COMPRODONTO disponibilize seus dados ao Parceiro do qual
            será contratado o plano odontológico pelo Cliente, para que este concretize a transação.
            A COMPRODONTO disponibilizará aos Parceiros apenas os dados pessoais
            necessários para a celebração do respectivo contrato, faturamento e emissão de
            documentos fiscais, não sendo disponibilizados outros dados pessoais ou histórico de
            contratações dentro do site da COMPRODONTO.
            5.5. O Usuário deverá cumprir com suas próprias obrigações tributárias, em
            cumprimento à legislação em vigor, não podendo imputar à COMPRODONTO
            quaisquer ônus em relação ao cumprimento destas obrigações.
            5.6. O usuário se obriga, também, a pagar integralmente o preço das parcelas
            contratadas junto ao Parceiro.
            5.7. O usuário que seja menor de 18 (dezoito) anos de idade está ciente de que não
            poderá contratar os planos odontológicos oferecidos no site da COMPRODONTO,
            devendo a mesma ser realizada por seu responsável legal.
            <h2>6. Obrigações da COMPRODONTO</h2>
            6.1. A COMPRODONTO será responsável pelo desenvolvimento do site da
            COMPRODONTO, incluindo, mas não se limitando a, toda estrutura física e
            tecnológica para a manutenção do serviço prestado para efetivação de pedidos de
            contratação dos planos odontológicos anunciados pelos Parceiros.
            6.2. O site COMPRODONTO poderá oferecer atendimento pós-venda básico, com os
            seguintes serviços: informações de pedido, status do pedido e demais informações de
            contratação junto ao Parceiro.
            6.3. Quando o cancelamento da contratação for solicitado pelo usuário através do site da
            COMPRODONTO, a COMPRODONTO deverá endereçar referida solicitação ao
            respectivo Parceiro para que adote todas as providências necessárias para que o
            cancelamento seja feito em, no máximo, 48 (quarenta e oito) horas.

            6.4. A COMPRODONTO deverá proteger, por meio de armazenamento em servidores
            ou quaisquer outros meios magnéticos de alta segurança, a confidencialidade de todas as
            informações e cadastros relativos aos usuários, assim como valores atinentes às
            operações financeiras advindas da operacionalização dos serviços previstos no presente
            Termo. Contudo, não responderá pela reparação de prejuízos que possam ser derivados
            de apreensão e cooptação de dados por parte de terceiros que, rompendo os sistemas de
            segurança, consigam acessar essas informações.
            6.5. Obriga-se a COMPRODONTO a:
            (i) Prestar ao Parceiro as informações possíveis e relevantes para o cumprimento do
            objeto do presente Termo, incluindo informações adicionais sobre o desenvolvimento
            do funcionamento do site da COMPRODONTO;
            (ii) Manter seu site funcionando de forma que os produtos e/ou serviços oferecidos
            pelos Parceiros sejam disponibilizados publicamente a todos os usuários;
            (iii) Manter em seu site, por si ou por terceiros, ferramentas destinadas ao registro das
            informações dos usuários, especialmente em relação às informações cadastrais, registro
            das vendas e gerenciamento de pagamentos;
            (iv) Utilizará seus melhores esforços para divulgar o site da COMPRODONTO nos
            canais publicitários disponíveis.
            <h2>7. Obrigações dos Parceiros</h2>
            7.1. Os Parceiros somente poderão anunciar no site da COMPRODONTO mediante a
            celebração do competente contrato de prestação de serviços com a COMPRODONTO.
            7.2. O Parceiro é o único responsável pelos produtos e/ou serviços por ele oferecidos
            no site da COMPRODONTO, bem como pelo serviço de pós-venda relacionado ao
            atendimento aos clientes;
            7.3. A COMPRODONTO é a única responsável pelos danos causados a seus usuários
            por defeitos ou vícios relativos exclusivamente à prestação do serviço de Marketplace
            no site da COMPRODONTO, desde que a COMPRODONTO tenha dado causa aos
            referidos defeitos ou vícios.
            7.4. O Parceiro concorda em manter indene a COMPRODONTO, seus diretores,
            gerentes, empregados e agentes em relação a todas e quaisquer responsabilidades,
            obrigações, perdas, danos, penalidades, ações, decisões, processos, custos, despesas,
            multas, condenações, incluindo, mas não se limitando, honorários ou desembolsos de
            qualquer natureza decorrentes dos produtos e serviços oferecidos pelo Parceiro por
            meio do site da COMPRODONTO, e/ou pelo descumprimento das demais obrigações
            deste instrumento.
            7.5. Nenhuma das Partes terá qualquer tipo de responsabilidade, frente à outra Parte, por
            defeitos técnicos e/ou operacionais oriundos do sistema dos Clientes e/ou Usuários.
            7.6. As Partes reconhecem que não é de responsabilidade da COMPRODONTO
            qualquer dano, prejuízo ou perda sofridos pelo Parceiro em razão de falhas na internet,
            no sistema ou no servidor utilizado pelo Parceiro decorrentes de condutas de terceiros,
            vírus ou caso fortuito e/ou força maior.
            7.7. O Parceiro declara ser o único e exclusivo responsável pela existência, quantidade,
            qualidade, integridade ou legitimidade dos planos odontológicos por ele ofertados no
            site da COMPRODONTO, estando o usuário ciente e de acordo com o aqui
            estabelecido, devendo todos os planos oferecidos cumprir com as coberturas mínimas
            exigidas e ter sido sua comercialização autorizada pela ANS. As Partes isentam,
            portanto, a COMPRODONTO de toda e qualquer responsabilidade neste sentido.

            7.8. Os Parceiros são os responsáveis pelas coberturas dos planos odontológicos
            comercializados através do site da COMPRODONTO, devendo respeitar os prazos de
            carência eventualmente previstos.
            <h2>8. Direitos Autorais - Copyright </h2>
            8.1. O Usuário e os Parceiros reconhecem e concordam que todo o serviço, bem como
            quaisquer marcas, nomes comerciais e produtos apresentados no site da
            COMPRODONTO estão protegidos por todas as leis e tratados aplicáveis no Brasil
            e/ou exterior. Dessa forma, a COMPRODONTO é a legítima detentora dos respectivos
            direitos autorais (copyright), sendo, portanto, vedada à utilização do conteúdo do site da
            COMPRODONTO sem a expressa e prévia autorização por escrito pela
            COMPRODONTO.
            8.2. O Usuário e os Parceiros concordam com a utilização de suas informações
            pessoais obtidas através de comentários e/ou avaliações tanto do site da
            COMPRODONTO quanto dos Parceiros para divulgação do site COMPRODONTO
            junto aos meios de publicidade, não sendo devida qualquer compensação financeira pela
            COMPRODONTO ou pelos Parceiros aos usuários.
            <h2>9. Modificações deste Termo</h2>
            9.1. O presente Termo poderá, a qualquer tempo, ter seu conteúdo, ou parte dele,
            modificados para adequações e inserções, tudo com vistas ao aprimoramento dos
            serviços disponibilizados. O novo Termo entrará em vigor após 10 (dez) dias contados
            de sua publicação no site da COMPRODONTO.
            9.2 As novas condições entrarão em vigência assim que sua veiculada no site, sendo
            possível ao usuário manifestar oposição a quaisquer dos termos modificados, desde que
            o faça por escrito, através do site da COMPRODONTO, o que gerará o cancelamento
            de seu cadastro.
            9.2.1. A COMPRODONTO se compromete a comunicar os usuários sobre eventuais
            alterações desse Termo através do e-mail cadastrado no site da COMPRODONTO,
            sendo que a vigência do novo Termo respeitará o prazo previsto no item 9.1,
            independente da data de recebimento do e-mail pelo usuário.
            <h2>10. Lei aplicável e foro de eleição</h2>
            Estes Termos e Condições de Uso do site da COMPRODONTO são regidos pelas Leis
            do Brasil. Para todas as controvérsias e questões relacionadas e estes Termos e
            Condições as partes elegem o foro da Comarca de Poços de Caldas/MG.
            11. Dúvidas
            11.1. Qualquer dúvida ou eventuais problemas quanto a este Termo deverão ser
            encaminhadas diretamente em campo adequado disponível na página eletrônica
            www.comprodonto.com.br, através do e-mail: contato@comprodonto.com.br que serão
            respondidas de maneira objetiva a tentar sanar qualquer questão de usabilidade.

            <strong><Termos e Condições de Uso elaborado em 02 de janeiro de 2019</strong>`,
          options
        )
        .then(() => {
        });
    },
    showPrivacePolicies(){
      let self = this;
      let options = {
        title: "Políticas de Privacidade",
        okLabel: "Fechar",
        cancelLabel: ""
      };
      self.$dialogs
        .confirm(
          `<p>POL&Iacute;TICA DE PRIVACIDADE</p>
<p><br></p>
<p>A COMPRODONTO est&aacute; comprometida em resguardar a privacidade de seus</p>
<p>usu&aacute;rios. O objetivo dessa pol&iacute;tica &eacute; esclarecer quais dados e informa&ccedil;&otilde;es s&atilde;o coletadas</p>
<p>dos usu&aacute;rios atrav&eacute;s da utiliza&ccedil;&atilde;o do site da COMPRODONTO</p>
<p>(www.comprodonto.com.br), e de que forma essas informa&ccedil;&otilde;es s&atilde;o obtidas, usadas,</p>
<p>divulgadas, transferidas e/ou armazenadas. Essa pol&iacute;tica de privacidade &eacute; parte</p>
<p>integrante dos Termos de Uso do site da COMPRODONTO.</p>
<p>&Eacute; importante destacar que n&atilde;o fa&ccedil;a uso de nossos servi&ccedil;os caso n&atilde;o concorde com o</p>
<p>conte&uacute;do dessa pol&iacute;tica.</p>
<p>1. Coleta de dados e informa&ccedil;&otilde;es pessoais</p>
<p>1.1. A coleta de dados dos internautas e dos usu&aacute;rios, tais como endere&ccedil;o de IP (internet</p>
<p>protocol) ou ID de celulares, tipo de navegador, idioma, tempo de acesso e pesquisas</p>
<p>s&atilde;o automaticamente coletadas e gravadas, bem como as sugest&otilde;es, perguntas ou</p>
<p>reclama&ccedil;&otilde;es.</p>
<p>1.2. As informa&ccedil;&otilde;es pessoais ser&atilde;o coletadas para a efetiva&ccedil;&atilde;o do cadastro dos usu&aacute;rios</p>
<p>nos servidores do site da COMPRODONTO, sendo consideradas como informa&ccedil;&otilde;es</p>
<p>pessoais qualquer dado que possa ser utilizado para identificar individualmente ou</p>
<p>entrar em contato com uma pessoa em espec&iacute;fico, n&atilde;o se limitando a nome, e-mail e/ou</p>
<p>n&uacute;mero de documento.</p>
<p>1.3. O usu&aacute;rio poder&aacute; ser solicitado a fornecer suas informa&ccedil;&otilde;es pessoais a qualquer</p>
<p>momento, enquanto estiver em contato com o site da COMPRODONTO ou com os</p>
<p>Parceiros. A COMPRODONTO e os Parceiros poder&atilde;o compartilhar essas</p>
<p>informa&ccedil;&otilde;es pessoais e utiliz&aacute;-las de acordo com esta Pol&iacute;tica de Privacidade. A</p>
<p>COMPRODONTO e os Parceiros poder&atilde;o, ainda, combinar essas informa&ccedil;&otilde;es</p>
<p>pessoais com outras informa&ccedil;&otilde;es para fornecer e aperfei&ccedil;oar nossos produtos, servi&ccedil;os,</p>
<p>conte&uacute;do e publicidade.</p>
<p>2. Utiliza&ccedil;&atilde;o dos dados pessoais coletados</p>
<p>2.1. As informa&ccedil;&otilde;es pessoais ser&atilde;o mantidas sob o mais completo sigilo pela</p>
<p>COMPRODONTO, vinculando-se unicamente ao seu registro e utilizando-as</p>
<p>unicamente para:</p>
<p>a. Realizar a entrega de documenta&ccedil;&atilde;o e a cobran&ccedil;a dos planos odontol&oacute;gicos</p>
<p>contratados atrav&eacute;s do site da COMPRODONTO, incluindo os produtos e servi&ccedil;os</p>
<p>ofertados pelos Parceiros, bem como a identifica&ccedil;&atilde;o do respectivo destinat&aacute;rio;</p>
<p>b. Cumprir obriga&ccedil;&otilde;es legais de qualquer natureza;</p>
<p>c. Obter estat&iacute;sticas gen&eacute;ricas para identifica&ccedil;&atilde;o do perfil dos nossos usu&aacute;rios e</p>
<p>desenvolvimento de nossas campanhas;</p>
<p>d. Manuten&ccedil;&atilde;o de dados e a&ccedil;&otilde;es de relacionamento com o usu&aacute;rio, bem como a</p>
<p>melhoria cont&iacute;nua do servi&ccedil;o prestado pela COMPRODONTO;</p>
<p>e. Resolu&ccedil;&atilde;o de eventuais quest&otilde;es legais relacionadas ao site da</p>
<p>COMPRODONTO;</p>
<p><br></p>
<p>f. Melhoria da experi&ecirc;ncia do usu&aacute;rio no site da COMPRODONTO, por meio de</p>
<p>ofertas espec&iacute;ficas e direcionadas, al&eacute;m da pr&aacute;tica de marketing direcionado, de</p>
<p>acordo com o seu perfil;</p>
<p>g. Fornecer, manter, proteger, desenvolver e melhorar o site da COMPRODONTO e</p>
<p>sua seguran&ccedil;a;</p>
<p>h. Enviar comunicados aos usu&aacute;rios sobre ofertas, novidades ou novos servi&ccedil;os</p>
<p>pr&oacute;prios e de terceiros da COMPRODONTO;</p>
<p>i. Manter atualizados os cadastros dos usu&aacute;rios para fins de contato telef&ocirc;nico, por</p>
<p>correio eletr&ocirc;nico, via mala direta, SMS ou outros meios de comunica&ccedil;&atilde;o;</p>
<p>j. Aperfei&ccedil;oar a usabilidade e a experi&ecirc;ncia interativa durante a navega&ccedil;&atilde;o do usu&aacute;rio</p>
<p>no Portal;</p>
<p>k. Elaborar estat&iacute;sticas gerais, sem que haja identifica&ccedil;&atilde;o dos usu&aacute;rios;</p>
<p>l. Garantir a seguran&ccedil;a dos Internautas;</p>
<p>m. Responder a suas eventuais d&uacute;vidas ou solicita&ccedil;&otilde;es;</p>
<p>n. Armazenar as prefer&ecirc;ncias dos usu&aacute;rios;</p>
<p>o. Constituir, defender ou exercer regularmente direitos em &acirc;mbito judicial ou</p>
<p>administrativo;</p>
<p>p. Realizar pesquisas e campanhas de comunica&ccedil;&atilde;o e marketing de relacionamento;</p>
<p>q. Prestar todos os servi&ccedil;os ofertados em seu site, nas condi&ccedil;&otilde;es previstas nos Termos</p>
<p>e Condi&ccedil;&otilde;es de Uso do site da COMPRODONTO.</p>
<p>2.2. As informa&ccedil;&otilde;es solicitadas aos usu&aacute;rios s&atilde;o necess&aacute;rias para melhorar e</p>
<p>personalizar a experi&ecirc;ncia de compra junto ao site da COMPRODONTO. Essas</p>
<p>informa&ccedil;&otilde;es n&atilde;o ser&atilde;o utilizadas al&eacute;m das hip&oacute;teses previstas neste documento e</p>
<p>qualquer uso estar&aacute; sempre vinculado com as diretrizes tra&ccedil;adas pela</p>
<p>COMPRODONTO nesta Pol&iacute;tica de Privacidade.</p>
<p>2.3. O usu&aacute;rio garante a veracidade e exatid&atilde;o das informa&ccedil;&otilde;es e dados que fornecer ao</p>
<p>site da COMPRODONTO, assumindo a correspondente responsabilidade pela sua</p>
<p>inexatid&atilde;o, bem como se compromete a mant&ecirc;-los devidamente atualizadas. O site da</p>
<p>COMPRODONTO n&atilde;o assume qualquer responsabilidade em caso de danos causados</p>
<p>pela inexatid&atilde;o dos dados informados.</p>
<p>2.4. O usu&aacute;rio que se cadastrar no site da COMPRODONTO utilizando dados falsos</p>
<p>ou de terceiros poder&aacute; incorrer nos crimes de falsidade ideol&oacute;gica, estelionato ou outros</p>
<p>delitos mais graves, podendo responder civil ou criminalmente por tal conduta, nos</p>
<p>termos da lei.</p>
<p>2.5. O usu&aacute;rio poder&aacute;, a qualquer momento, solicitar altera&ccedil;&atilde;o, retifica&ccedil;&atilde;o e/ou exclus&atilde;o</p>
<p>de seus dados, bastando para tanto, utilizar sua Conta de Acesso no site da</p>
<p>COMPRODONTO ou atrav&eacute;s dos canais de contatos informados nessa Pol&iacute;tica de</p>
<p>Privacidade.</p>
<p>2.6. A COMPRODONTO poder&aacute; compartilhar com terceiros as informa&ccedil;&otilde;es pessoais</p>
<p>e informa&ccedil;&otilde;es de acesso para presta&ccedil;&atilde;o dos servi&ccedil;os de Marketplace da</p>
<p>COMPRODONTO. Para tanto, a COMPRODONTO compartilhar&aacute; as informa&ccedil;&otilde;es</p>
<p>de seus usu&aacute;rios com Parceiros na medida necess&aacute;ria para que estes terceiros possam</p>
<p>cumprir integralmente com suas obriga&ccedil;&otilde;es decorrentes de Lei e dos Termos e</p>
<p>Condi&ccedil;&otilde;es de Uso do site da COMPRODONTO.</p>
<p>2.7. O usu&aacute;rio tem ci&ecirc;ncia e concorda que para fins de presta&ccedil;&atilde;o de servi&ccedil;os e entrega</p>
<p>dos produtos e servi&ccedil;os ofertados pelos Parceiros e/ou pelo site da</p>
<p>COMPRODONTO, pode ser necess&aacute;ria a contrata&ccedil;&atilde;o de terceiros, que ter&atilde;o acesso &agrave;s</p>
<p>informa&ccedil;&otilde;es e dados do usu&aacute;rio exclusivamente para os fins necess&aacute;rios.</p>
<p><br></p>
<p>2.8. A COMPRODONTO poder&aacute; tamb&eacute;m compartilhar com terceiros as informa&ccedil;&otilde;es</p>
<p>pessoais e informa&ccedil;&otilde;es de acesso dos usu&aacute;rios para presta&ccedil;&atilde;o dos servi&ccedil;os oferecidos</p>
<p>pela COMPRODONTO.</p>
<p>2.9. A COMPRODONTO tamb&eacute;m poder&aacute; compartilhar com terceiros as informa&ccedil;&otilde;es</p>
<p>pessoais e informa&ccedil;&otilde;es de acesso para cumprir qualquer lei, regulamenta&ccedil;&atilde;o,</p>
<p>determina&ccedil;&atilde;o judicial, solicita&ccedil;&atilde;o governamental, ou para detectar, impedir ou abordar</p>
<p>de qualquer forma quest&otilde;es t&eacute;cnicas, de fraude ou de seguran&ccedil;a, proteger os direitos, as</p>
<p>propriedades ou a seguran&ccedil;a da COMPRODONTO, do site da COMPRODONTO,</p>
<p>seus usu&aacute;rios, parceiros ou quaisquer terceiros.</p>
<p>2.9.1. Nesse sentido, a COMPRODONTO informa que est&aacute; legalmente obrigado a</p>
<p>divulgar os dados cadastrais que informem qualifica&ccedil;&atilde;o pessoal, filia&ccedil;&atilde;o e endere&ccedil;o &agrave;s</p>
<p>autoridades administrativas legalmente habilitadas, tais como os Delegados da Pol&iacute;cia</p>
<p>Civil e o Minist&eacute;rio P&uacute;blico, nos termos do artigo 10, &sect; 3&ordm;, da Lei 12.965/14.</p>
<p>3. Prote&ccedil;&atilde;o e privacidade das informa&ccedil;&otilde;es coletadas</p>
<p>3.1. A COMPRODONTO far&aacute; todo o poss&iacute;vel para manter a privacidade das</p>
<p>informa&ccedil;&otilde;es pessoais e informa&ccedil;&otilde;es de acesso armazenados em seus servidores,</p>
<p>comprometendo-se a utilizar tecnologia que seja suficientemente adequada para a</p>
<p>prote&ccedil;&atilde;o de tais dados, procurando manter o ambiente seguro, com uso de ferramentas</p>
<p>apropriadas e controles eficientes de seguran&ccedil;a das informa&ccedil;&otilde;es coletadas, sempre</p>
<p>observando o estado da t&eacute;cnica dispon&iacute;vel. Contudo, considerando que nenhum sistema</p>
<p>de seguran&ccedil;a &eacute; absolutamente seguro, COMPRODONTO se exime de quaisquer</p>
<p>responsabilidades por eventuais danos e/ou preju&iacute;zos decorrentes de invas&otilde;es no site da</p>
<p>COMPRODONTO, em seus servidores e demais falhas relacionadas, salvo se houver</p>
<p>dolo ou culpa da COMPRODONTO.</p>
<p>3.2. Os profissionais da COMPRODONTO t&ecirc;m conhecimento da presente Pol&iacute;tica de</p>
<p>Privacidade e apenas o pessoal qualificado e autorizado tem permiss&atilde;o para acessar as</p>
<p>informa&ccedil;&otilde;es coletadas pela COMPRODONTO, sob pena de sofrerem san&ccedil;&atilde;o</p>
<p>disciplinar em caso de viola&ccedil;&atilde;o das normas aqui previstas.</p>
<p>3.3. As empresas parceiras e prestadores de servi&ccedil;os da COMPRODONTO tem ci&ecirc;ncia</p>
<p>e est&atilde;o comprometidas em respeitar a prote&ccedil;&atilde;o e privacidade de dados assegurados</p>
<p>nesta pol&iacute;tica, quando houver necessidade de compartilhamento destes dados, nos</p>
<p>termos desta pol&iacute;tica.</p>
<p>3.4. O usu&aacute;rio reconhece e concorda que a COMPRODONTO pode disponibilizar</p>
<p>links para outros sites que, por sua vez, podem coletar informa&ccedil;&otilde;es pessoalmente</p>
<p>identific&aacute;veis do usu&aacute;rio. O usu&aacute;rio reconhece e concorda que a COMPRODONTO</p>
<p>n&atilde;o &eacute; respons&aacute;vel pelas pr&aacute;ticas de privacidade de outros sites; e &eacute; recomendado ao</p>
<p>Internauta ler atentamente as declara&ccedil;&otilde;es de privacidade de todo e qualquer site que</p>
<p>colete informa&ccedil;&otilde;es pessoalmente identific&aacute;veis. Esta Pol&iacute;tica de Privacidade se aplica</p>
<p>somente &agrave;s informa&ccedil;&otilde;es coletadas e processadas pela COMPRODONTO e pelo site da</p>
<p>COMPRODONTO.</p>
<p>3.5. A COMPRODONTO declara que respeita toda e qualquer legisla&ccedil;&atilde;o vigente sobre</p>
<p>prote&ccedil;&atilde;o de dados e privacidade dos nossos usu&aacute;rios</p>
<p>3.6. A COMPRODONTO possui sistema moderno de seguran&ccedil;a de decodifica&ccedil;&atilde;o</p>
<p>atrav&eacute;s de criptografia devidamente validado pelas autoridades certificadoras e tamb&eacute;m</p>
<p>trabalha com um firewall com o objetivo de impedir o acesso a seus servidores por</p>
<p>pessoas n&atilde;o autorizadas.</p>
<p>4. Envio de comunicados</p>
<p><br></p>
<p>4.1. A seu exclusivo crit&eacute;rio, a COMPRODONTO poder&aacute; enviar comunicados e</p>
<p>mensagens publicit&aacute;rias diariamente aos usu&aacute;rios cadastrados no site da</p>
<p>COMPRODONTO, ou Internautas em geral, fazendo uso de todos os tipos de</p>
<p>tecnologias e meios de comunica&ccedil;&atilde;o dispon&iacute;veis, seja por e-mail, SMS, MMS, mala-</p>
<p>direta ou outros.</p>
<p>4.2. Os comunicados, newsletters, avisos de ofertas e mensagens publicit&aacute;rias enviadas</p>
<p>por e-mail trar&atilde;o, obrigatoriamente, op&ccedil;&atilde;o de cancelamento (opt-out) do envio daquele</p>
<p>tipo de mensagem por parte do site da COMPRODONTO. O cancelamento do envio</p>
<p>tamb&eacute;m pode ser solicitado pelos canais de atendimento dispon&iacute;veis no site da</p>
<p>COMPRODONTO.</p>
<p>4.2.1. A COMPRODONTO se esfor&ccedil;ar&aacute; para atender a solicita&ccedil;&atilde;o no menor tempo</p>
<p>poss&iacute;vel. No entanto, tendo em vista o procedimento envolve atualiza&ccedil;&atilde;o das bases de</p>
<p>dados da COMPRODONTO e eventualmente de empresas terceiras, o usu&aacute;rio</p>
<p>compreende que a solicita&ccedil;&atilde;o poder&aacute; demorar alguns dias at&eacute; ser concretizada.</p>
<p>4.3. O processo de cancelamento de envio &eacute; vinculado ao e-mail do usu&aacute;rio. Portanto</p>
<p>usu&aacute;rios que possuam mais de um e-mail cadastrado poder&atilde;o continuar a receber os</p>
<p>comunicados ou as mensagens publicit&aacute;rias no e-mail que n&atilde;o foi descadastrado.</p>
<p>4.4. Os servi&ccedil;os de envio de e-mails podem ser realizados pela COMPRODONTO ou</p>
<p>por empresa contratada, que utilizar&aacute; seus pr&oacute;prios servidores para realizar o envio.</p>
<p>4.5. A empresa contratada n&atilde;o armazena nem utiliza de nenhuma forma os e-mails de</p>
<p>cadastro do site da COMPRODONTO para qualquer outro fim que n&atilde;o o envio das</p>
<p>mensagens, de acordo com as prefer&ecirc;ncias de cada usu&aacute;rio e as disposi&ccedil;&otilde;es previstas na</p>
<p>presente Pol&iacute;tica de Privacidade e nos Termos e Condi&ccedil;&otilde;es de Uso do site da</p>
<p>COMPRODONTO.</p>
<p>5. Cookies e informa&ccedil;&otilde;es sobre localiza&ccedil;&atilde;o</p>
<p>5.1. Ao visitar o site da COMPRODONTO, podemos enviar cookies para seu</p>
<p>computador objetivando facilitar nosso site ou dar acesso a recursos adicionais e</p>
<p>acompanhamento de atividade de marketing. Os cookies nos permitem coletarmos suas</p>
<p>atividades online, tais como a visita&ccedil;&atilde;o de p&aacute;ginas virtuais, sendo de responsabilidade</p>
<p>dos usu&aacute;rios desativarem os cookies.</p>
<p>5.2. Ao come&ccedil;ar a utilizar o site da COMPRODONTO, podemos pedir sua autoriza&ccedil;&atilde;o</p>
<p>para ter acesso a sua localiza&ccedil;&atilde;o, que ser&aacute; obtida pelo site em caso de anu&ecirc;ncia.</p>
<p>Usaremos essas informa&ccedil;&otilde;es para poder tornar as funcionalidades de acordo com a sua</p>
<p>localiza&ccedil;&atilde;o, informando sobre eventuais informa&ccedil;&otilde;es sobre produtos e/ou servi&ccedil;os</p>
<p>pr&oacute;ximos &agrave; sua localiza&ccedil;&atilde;o. Podemos armazenar esses dados, por&eacute;m n&atilde;o ser&aacute; associado</p>
<p>a sua pessoa, permanecendo o usu&aacute;rio an&ocirc;nimo.</p>
<p>6. Modifica&ccedil;&otilde;es dessa Pol&iacute;tica de Privacidade</p>
<p>6.1. A presente Pol&iacute;tica de Privacidade est&aacute; sujeita a constante melhoria e</p>
<p>aprimoramento para garantir mais transpar&ecirc;ncia e seguran&ccedil;a aos Internautas. Assim, a</p>
<p>COMPRODONTO se reserva o direito de modificar a qualquer momento, de forma</p>
<p>unilateral, o presente documento. A nova Pol&iacute;tica de Privacidade entrar&aacute; em vigor ap&oacute;s</p>
<p>10 (dez) dias contados de sua publica&ccedil;&atilde;o no site da COMPRODONTO.</p>
<p>6.1.1. A COMPRODONTO se compromete a comunicar os usu&aacute;rios sobre eventuais</p>
<p>altera&ccedil;&otilde;es dessa Pol&iacute;tica de Privacidade atrav&eacute;s do e-mail cadastrado no site da</p>
<p><br></p>
<p>COMPRODONTO, sendo que a vig&ecirc;ncia da nova pol&iacute;tica respeitar&aacute; o prazo previsto</p>
<p>no item 6.1, independente da data de recebimento do e-mail pelo usu&aacute;rio.</p>
<p>6.2. Ao continuar acessando o site da COMPRODONTO ap&oacute;s a entrada em vigor das</p>
<p>modifica&ccedil;&otilde;es, o usu&aacute;rio automaticamente concorda e aceita o teor das modifica&ccedil;&otilde;es</p>
<p>realizadas. Portanto, entende que deve verificar regularmente eventuais mudan&ccedil;as ao</p>
<p>presente documento.</p>
<p>7. Lei aplic&aacute;vel</p>
<p>7.1. A presente Pol&iacute;tica de Privacidade &eacute; regida e interpretada segundo as leis da</p>
<p>Rep&uacute;blica Federativa do Brasil.</p>
<p>8. D&uacute;vidas</p>
<p>8.1. Qualquer d&uacute;vida ou eventuais problemas quanto a esta pol&iacute;tica de privacidade</p>
<p>dever&atilde;o ser encaminhadas diretamente em campo adequado dispon&iacute;vel na p&aacute;gina</p>
<p>eletr&ocirc;nica www.comprodonto.com.br, atrav&eacute;s do e-mail: contato@comprodonto.com.br</p>
<p>que ser&atilde;o respondidas de maneira objetiva a tentar sanar qualquer quest&atilde;o de</p>
<p>usabilidade.</p>
<p>8.2. Os Parceiros possuem canais e hor&aacute;rios de atendimento pr&oacute;prios que s&atilde;o</p>
<p>divulgados aos usu&aacute;rios antes da finaliza&ccedil;&atilde;o da aquisi&ccedil;&atilde;o do produto ou servi&ccedil;o.</p>
<p>Pol&iacute;tica de Privacidade elaborada em 02 de janeiro de 2019.</p>`,
          options
        )
        .then(() => {
        });
    },
    gotoProfile() {
      let self = this;
      if (!localStorage.getItem("t")) {
        self.showAlert("Atenção!", "Faça login para ver seu perfil.");
        self.$router.push({ path: "/login" });
      } else {
        self.$router.push({ path: "/minha-area" });
      }
    }
  }
};
</script>