import Vue from 'vue'
import Router from 'vue-router';
import Home from './views/home';
import Area from './views/minha-area'
import Detalhe from './views/detalhe_plano'
import Sobre from './views/sobre'
import Planos from './views/planos'
import Pagamento from './views/pagamento'
import Noticia from './views/noticia'
import Duvidas from './views/duvidas'
import DetalheNoticia from './views/detalhe_noticia'
import Dependentes from './views/dependentes'
import Contato from './views/contato'
import Finalizado from './views/finalizado'
import Login from './views/login'
import Opcoes from './views/opcoes_pagamento'
import NotFound from './views/not_found'
import Cadastro from './views/cadastro.vue';
import VerificaEmail from './views/verifica_email.vue';
import ConfirmaEmail from './views/confirma_email.vue';

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes:[
        { path: '*', name: 'NotFound', component: NotFound },
        { path: '/', name: 'Home', component: Home },
        { path: '/minha-area', name: 'Area', component: Area },
        { path: '/login', name: 'Login', component: Login },
        { path: '/sobre', name: 'Sobre', component: Sobre },
        { path: '/planos/:estado/:cidade', name: 'Planos', component: Planos },
        { path: '/uniodonto/:id_estado/:id_cidade/detalhes/planos/:id', name: 'Detalhe', component: Detalhe},
        { path: '/finalizar-compra/pagamento', name: 'Pagamento', component: Pagamento },
        { path: '/noticia', name: 'Noticia', component: Noticia },
        { path: '/duvidas', name: 'Duvidas', component: Duvidas },
        { path: '/detalhe-noticia/:id', name: 'DetalheNoticia', component: DetalheNoticia },
        { path: '/finalizar-compra/dependentes', name: 'Dependentes', component: Dependentes },
        { path: '/contato', name: 'Contato', component: Contato },
        { path: '/finalizar-compra/opcoes-pagamento', name: 'Opcoes', component: Opcoes },
        { path: '/finalizar-compra/finalizado/:tipo/:protocolo', name: 'Finalizado', component: Finalizado },
        { path: '/finalizar-compra/cadastro', name: 'Cadastro', component: Cadastro},
        { path: '/verifica-email', name: 'VerificaEmail', component: VerificaEmail},
        { path: '/confirmar_email/:token', name: 'ConfirmaEmail', component: ConfirmaEmail},
        
    ],
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    },
})