<template>
    <div>
        <Navbar night="true"/>
           

      <div id="s-noticia">

            <section class="s-noticias">
                  <div class="container">
                        <div class="topo-title">
                              <div class="title">
                                    <h2>Blog</h2>
                              </div>
                        </div>

                        <div class="area-posts">
                              <div class="s-post-esq">
                                    <div class="item">
                                          <a href="">
                                                <h3>Uniodonto está entre as marcas mais lembradas e preferidas dos
                                                      gaúchos.</h3>
                                                <span>12.09.2019</span>
                                                <p>Compromisso de eliminar intermediários na assistência odontológica.
                                                </p>
                                          </a>
                                    </div>
                                    <div class="item">
                                          <a href="">
                                                <h3>Uniodonto está entre as marcas mais lembradas e preferidas dos
                                                      gaúchos.</h3>
                                                <span>12.09.2019</span>
                                                <p>Compromisso de eliminar intermediários na assistência odontológica.
                                                </p>
                                          </a>
                                    </div>
                                    <div class="item">
                                          <a href="">
                                                <h3>Uniodonto está entre as marcas mais lembradas e preferidas dos
                                                      gaúchos.</h3>
                                                <span>12.09.2019</span>
                                                <p>Compromisso de eliminar intermediários na assistência odontológica.
                                                </p>
                                          </a>
                                    </div>
                              </div>
                              <div class="s-post-dir">
                                    <a href="">
                                          <div class="foto">
                                                <img src="../assets/img/noticia/noticia-07.png" alt="">
                                          </div>
                                          <div class="info">
                                                <h3>Diretor Presidente da Uniodonto Paulista participa de reunião sobre
                                                      a Intercooperação</h3>
                                                <span>12.09.2019</span>
                                                <p>Compromisso de eliminar intermediários na assistência odontológica.
                                                </p>
                                          </div>
                                    </a>
                              </div>
                        </div>
                  </div>

            </section>

            <section class="card-detalhe">
                  <div class="container">

                        <div class="container-cards">
                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>

                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>

                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>

                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>

                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>

                              <div class="card">
                                    <div class="card-img">
                                          <img src="../assets/img/noticia/noticia-08.png" alt="">
                                    </div>

                                    <h2>Diretor Presidente da Uniodonto Paulista participa de reunião sobre a
                                          Intercooperação</h2>

                                    <span class="data">12.09.2019</span>

                                    <p>Compromisso de eliminar intermediários na assistência odontológica promisso de
                                          eliminar intermediários na assistência odontológica.</p>
                              </div>
                        </div>
                  </div>
            </section>
      </div>
        <Footer />
    </div>
</template>
<script>
export default {
    name: 'noticia'
}
</script>