<template>
	<div>
		
    <loading :active.sync="isLoading" color="#C90C53"></loading>
		<div class="login">
			<div class="container">
				<div class="voltar">
					<a @click="!esqueceu_senha ? $router.back() : esqueceu_senha = false">
						<img src="../assets/img/login/login-03.png" alt /> Voltar
					</a>
				</div>
				<div class="formulario">
					<div class="container-logo">
						<img src="../assets/img/login/login-01.png" alt />
					</div>
					<div v-if="!esqueceu_senha" class="container-cadastro">
						<div class="form">
							<div class="form-group">
								<label>E-Mail</label>
								<input type="text" :class="errors[0]" v-model="info.login" id="email" />
								<span
                  class="msgErro text-login"
                  v-if="errors[0]"
                >*Verifique se o campo está preechido com dados válidos!</span>
							</div>
							<div class="form-group">
								<label>Senha</label>
								<input type="password" :class="errors[1]" v-model="info.senha" id="senha" />
								<span
                  class="msgErro text-login"
                  v-if="errors[1]"
                >*Verifique se o campo está preechido com dados válidos!</span>
							</div>							
						</div>
					</div>

					<div v-else class="container-cadastro">
						<div class="form">
							<div class="form-group">
								<label>Insira seu E-mail</label>
								<input type="text" v-model="email_recuperacao">
							</div>
						</div>
					</div>

					<div class="btn-login" v-if="esqueceu_senha">
						<button  @click="recuperarSenha()"> Recuperar Senha <img src="../assets/img/login/login-02.png" alt /> </button>
					</div>

					<div class="btn-login" v-else >
						<button @click="logar()"> Avançar <img src="../assets/img/login/login-02.png" alt /> </button>
					</div>

           <a v-if="!esqueceu_senha" @click="esqueceu_senha = true" >Esqueci minha senha</a>

					<div class="contato">
						<h2>Contato</h2>
						<div class="tel">
							<img src="../assets/img/footer/footer-06.png" alt />
							<p>+55 (35) 9 3300-7935</p>
						</div>
						
						<div class="email">
							<img src="../assets/img/footer/footer-07.png" alt />
							<p>contato@comprodonto.com.br</p>
						</div>
					</div>

				</div>
			</div>
		</div>
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
import Bugsnag from '@bugsnag/js';
import axios from 'axios';
var jwtDecode = require("jwt-decode");

export default {
  name: "Login",
  mixins: [myFunction],
  data() {
		return {
			isLoading: false,
			errors: [],
			info: {
				login: '',
				senha: ''
			},
			email_recuperacao: '',
			esqueceu_senha: false,
			user_id: null,
			isVerified: null,
			email: ''
		}
	},

	mounted() {
		let self = this;

		if(localStorage.getItem("t")){
      self.$router.push({ path: '/minha-area' })
    }
	},

	methods: {
		logar() {
			let self = this;
      		self.info.login == "" ? self.errors.push("error-class") : self.errors.push("");
			self.info.senha == "" ? self.errors.push("error-class") : self.errors.push("");
			
			if(self.info.login && self.info.senha) {
				let loginResponse;
				self.isLoading = true;
				self.$http.post('loginv3', self.info).then(res => res.json()).then(response => {
					self.isLoading = false;
					loginResponse = response;
					localStorage.setItem("t", JSON.stringify(response.usuario.token));
					const token = response.usuario.token;
					let decoded = jwtDecode(token);
					self.user_id = decoded.sub;
					self.$http
						.get(`usuarios/${self.user_id}`)
						.then((res) => res.json())
						.then((response) => {
							self.isVerified = response.verified_at;
							self.email = response.email;
							if(self.isVerified !== null) {
								if(loginResponse.rota == '/minha-area') self.$router.push({ path: '/minha-area' })
								else if (loginResponse.rota != "") self.$router.push({ path: '/finalizar-compra'+loginResponse.rota })
								else self.$router.push({ path: '/minha-area' })
							} else {
								localStorage.setItem("email_notVerified_comprodonto", self.email);
								self.$router.push("/verifica-email");
							}
							localStorage.setItem("planos", JSON.stringify(loginResponse.carrinho));
							self.$store.commit("setCarrinho", loginResponse.carrinho);
							self.isLoading = false;
						})
						.catch((e) => {
							self.isLoading = false;
							self.showAlert("Erro!", e.body.message);
						});

				}, err => {
					Bugsnag.notify(err)
					self.isLoading = false;
					self.showAlert("Erro!", "Desculpe, ocorreu um erro ao efetuar o login, por favor verifique se as infomações estão corretas!");
				})
			}
		},

		recuperarSenha() {
			let self = this;

			self.isLoading = true;
			axios.get(`redefinir_senha/${self.email_recuperacao}`)
			.then(() => {
				self.isLoading = false;
				self.showAlert("Sucesso", "Nova senha foi enviada a seu E-Mail");
				self.esqueceu_senha = false;
			}).catch( () => {
				self.isLoading = false;
				self.show("Erro", "Ocorreu um erro ao requisitar uma nova senha a esse e-mail tente novamente mais tarde")
			})
		}
	}
};
</script>

<style>

.botao-login {
    background-color: transparent;
    border: none;
    color: #c70851;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login .container .formulario .container-logo {
	margin-bottom: 60px;
}

.login .container .formulario {
		width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.formulario .form-group {
		position: relative;
    margin-bottom: 28px;
}

.login .container .formulario .form-group label {
    background-color: #c70851;
    color: #ff96db;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.formulario .form-group label {
    color: #4b4b4b;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    position: absolute;
    top: -9px;
    left: 15px;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 10px;
}

.login .container .formulario .form-group input {
    background-color: rgb(232, 240, 254) !important;
    border: 1px solid #ff96db;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.formulario .form-group input {
    height: 42px;
    border: 1px solid #cecece;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 12px;
    font-weight: 700;
    outline: none;
}

.login .container .formulario a {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

@media (max-width: 480px) {
	.login .container .formulario .container-logo img {
    width: 100%;
	}
}

@media (max-width: 480px) {
	.login .container .formulario {
    width: 100%;
	}
}

</style>