import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: false,
    state: {
        carrinho: [],
        arquivos: [],
        cidade: '',
        user: {},
        modal: {},
    },
    getters: {
        getCarrinho: (state) => {
            return  state.carrinho;
        },     
        getArquivos: (state) => {
            return  state.arquivos;
        },
        getCidade: (state) => {
            return  state.cidade;
        },
        getUser: (state) => {
            return state.user
        },
        modal: ({modal}) => modal,
    },
    mutations: {
        setCarrinho: (state, value) => {
            state.carrinho = value;
        },
        modal: (state, value) => state.modal = value,
        setArquivos: (state, value) => {
            state.arquivos = value;
        },
        setCidade: (state, value) => {
            state.cidade = value;
        },
        setUser: (state, value) => {
            state.user = value;
        }
    }
});