<template>
  <div :class="active ? 'modal-carrinho active' : 'modal-carrinho'">
    <div class="container-carrinho modal">
      <div class="title-carrinho" @click="updateModalState">
        <div class="container-img-total">
          <img src="../assets/img/carrinho/carrinho-09.png" alt />
          <span>{{(planos.length > 10 ) ? planos.length : '0'+planos.length}}</span>
        </div>
        <p>Meu Carrinho</p>
        <img
          @click="updateModalState"
          src="../assets/img/header/header-05.png"
          class="close-menu close-carrinho"
          alt
        />
      </div>

      <div class="valor-total">
        <h3>Total</h3>

        <p>
          R$ {{ (total / 100).toFixed(2).replace(".",",") }}
          <span>/mês</span>
        </p>
      </div>

      <div class="container-itens-carrinho">
        <div class="container-itens" v-for="(plano, index) in planos" :key="plano.id">
          <h3>{{plano.name}}</h3>
          <p>{{plano.cidade}} - {{plano.estado}}</p>
          <div class="container-qtd">
            <h4>
              {{ (plano.price / 100).toFixed(2).replace(".",",") }}
              <span>/ mês</span>
            </h4>

            <div class="qtd-item">
              <span
                v-if="planos[index].qtd > 1"
                class="contador subtracao"
                @click="planos[index].qtd -= 1"
              >-</span>
              <span v-else @click="removePlan(index)" class="contador subtracao">-</span>
              <span class="valor-total-item">{{ planos[index].qtd }}</span>
              <span class="contador soma" @click="planos[index].qtd += 1">+</span>
            </div>
          </div>
        </div>
      </div>

      <div class="sub-total" id="subtotal">
        <div class="container-text-sub-total">
          <p>Taxa de adesão</p>
          <span>R$ {{ (total_setup_fee / 100).toFixed(2).replace(".",",") }}</span>
        </div>
      </div>


      <div class="sub-total" id="subtotal">
        <div class="container-text-sub-total">
          <p>Subtotal  
            <i
             class="fa fa-info-circle"
             style="font-size: 17px"
              title="Valor da Primeira mensalidade com a taxa de adesão do plano"
            ></i></p>
          <span>R$ {{ ((total + total_taxa) / 100).toFixed(2).replace(".",",") }}</span>
        </div>
      </div>

      <!-- <div class="sub-total">
          <div class="container-text-sub-total">
            <p>Subtotal</p>
            <span>R$52,90</span>
          </div>
      </div>-->

      <div class="valor-total">
        <h3>Total</h3>

        <p>
          R$ {{ ( parseInt(total) / 100).toFixed(2).replace(".",",") }}
          <span>/mês</span>
        </p>
      </div>

      <div class="btn-cadastro" @click="contratarPlano">
        <a>
          Contratar Plano
          <img src="../assets/img/cadastro/cadastro-02.png" alt />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";

export default {
  name: "carrinho",
  mixins: [myFunction],
  props: ["active"],
  data() {
    return {
      total: 0,
      total_setup_fee: 0,
      total_taxa: 0,
      dialog: true
    };
  },
  computed: {
    planos() {
      return this.$store.getters.getCarrinho
    }
  },
  // watch: {
  //   planos(val) {
  //     localStorage.setItem("planos", [JSON.stringify(val)]);
  //   }
  // },
  mounted() {
    window.addEventListener("beforeunload", this.beforeRouteLeave);
    window.addEventListener("onbeforeunload", this.beforeRouteLeave);
  },

  updated() {
    let self = this;
    self.total_taxa = self.planos.reduce(function(total, item) {
      return total + parseFloat(item.setup_fee) * item.qtd;
    }, 0);

    self.total = self.planos.reduce(function(total, item) {
      return total + parseFloat(item.price) * item.qtd;
    }, 0);

    self.total_setup_fee = self.planos.reduce(function(total, item) {
      return total + parseFloat(item.setup_fee) * item.qtd;
    }, 0);

    self.$emit('atualizaTotal')
  },
  destroyed() {
    localStorage.setItem("planos", [JSON.stringify(this.planos)]);
  },
  methods: {
    updateModalState: function() {
      this.$emit("state");
    },
    beforeRouteLeave(to, from, next) {
      if (this.planos.length > 0) {
        localStorage.setItem("planos", [JSON.stringify(this.$store.getters.getCarrinho)]);
      }
      next();
    },
    contratarPlano() {
      let self = this;
      let id_lead = JSON.parse(localStorage.getItem("id_lead"));
      const objEvento = {
        acao: 'CLICK',
        leads_id: id_lead,
        identificacao: 'contratar_plano'
      }
      self.$http.post("eventos_sites", objEvento)
      if (this.planos.length > 0) {
        this.$router.replace({ path: "/finalizar-compra/cadastro" });
      } else {
        this.showAlert("Atenção!", "Adicione itens ao seu carrinho para realizar uma compra.");
      }
    },
    removePlan(index) {
      let self = this;
      let options = { title: "Atenção!", okLabel: "Sim", cancelLabel: "Não" };
      self.$dialogs
        .confirm(
          "Se você realizar essa ação um plano será removido do carrinho, deseja continuar?",
          options
        )
        .then(res => {
          if (res.ok) {
            self.planos.splice(index, 1);
            self.$store.commit("setCarrinho", this.planos);
            self.$emit('atualizaTotal')
            localStorage.setItem("planos", JSON.stringify(this.planos));
          }
        });
    }
  }
};
</script>
<style scoped>
.container-carrinho {
        height: 100vh !important;
    }

    .container-carrinho.modal .container-itens-carrinho {
        height: 70vh !important;
    }
</style>