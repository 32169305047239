<template>
	<div>
    	<loading :active.sync="isLoading" color="#C90C53"></loading>
		<div :class="`verificaEmail ${!changeEmail ? 'height' : ''}`" >
			<div class="container">
				<!-- <div class="voltar">
					<a @click="!esqueceu_senha ? $router.back() : esqueceu_senha = false">
						<img src="../assets/img/login/login-03.png" alt /> Voltar
					</a>
				</div> -->
				<div class="formulario">
					<div class="container-logo">
						<img src="../assets/img/login/login-01.png" alt />
					</div>
					<div class="container-cadastro">
						<p class="texto">
                            Para adiquirir o(s) plano(s), você precisa confirmar seu email 
                            para que seja possível enviar contratos, documentos e mantermos uma comunicação.
                            Enviamos um email de confirmação para 
                            <span> {{ email_cadastrado }} </span> 
                            lembre-se de confirmar a caixa de spam.
                            <span v-if="!changeEmail">
                                <button class="botao-login" @click="(event) => reenviarLink(event.target.textContent)"> Enviar link novamente </button>
                            </span> 
                            <span>
                                <button class="btn-changeEmail" @click="changeEmailHandler"> {{ textBtn }} </button>
                            </span> 
						</p>
					</div>

                    <div v-if="changeEmail" class="container-cadastro">
                        <p class="texto-reenvio">
                                Informou o email errado? Você ainda pode corrigir, informando o email 
                                correto no campo abaixo:
                            </p>
                            <div class="form">
                                <div class="form-group">
                                    <label>E-Mail</label>
                                    <input type="text" :class="errors[0]" v-model="email_alterado" id="email" />
                                    <span
                                    class="msgErro text-login"
                                    v-if="errors[0]"
                                    >*Verifique se o campo está preechido com dados válidos!</span>
                                </div>
                            </div>

                        <div class="btn-login" >
                            <button @click="(event) => reenviarLink(event.target.textContent)"> Confirmar </button>
                        </div>
                    </div>
				</div>
			</div>
		</div>
  </div>
</template>
<script>
import myFunction from "../assets/js/my_functions";
var jwtDecode = require("jwt-decode");

export default {
  name: "VerificaEmail",
  mixins: [myFunction],
  data() {
		return {
            planos: this.$store.getters.getCarrinho,
			isLoading: false,
			errors: [],
			email_cadastrado: '',
			email_alterado: '',
            isVerified: null,
            user: {},
            textBtn: 'Trocar email',
            changeEmail: false,
		}
	},

	methods: {
        reenviarLink(text) {
            let self = this;
            self.isLoading = true;
            let obj = { email: '' };
            obj.email = text.includes('link') ? self.email_cadastrado : self.email_alterado;
            if(obj.email !== '') {
                self.$http
                    .put(`enviar_confirmacao_email/${self.user.id}`, obj)
                    .then((res) => res.json())
                    .then((response) => {
                        self.isLoading = false;
                        self.showAlert("Sucesso!" , `Um email foi encaminhado para ${obj.email}. 
                        Se não o encontrar na sua caixa de mensagem, verifique também a sua caixa de Spam!`);
                        if(self.changeEmail) {
                            self.changeEmail = !self.changeEmail;
                            self.textBtn = self.changeEmail ? 'Cancelar' : 'Trocar email';
                            self.email_alterado = '';
                            self.email_cadastrado = obj.email;
                        }
                    })
                    .catch((e) => {
                        self.isLoading = false;
                        self.showAlert("Erro!", "O email de verificação não pôde ser encaminhado. Tente novamente mais tarde!");
                    });
            } else {
                self.isLoading = false;
                self.showAlert("Erro!", "O email de verificação não pôde ser encaminhado.");
            }
        },

        changeEmailHandler() {
            let self = this;
            self.isLoading = true;
            self.changeEmail = !self.changeEmail;
            self.textBtn = self.changeEmail ? 'Cancelar' : 'Trocar email';
            self.isLoading = false;
        }

	},
    mounted() {
        let self = this;
        self.isLoading = true;
        let token = JSON.parse(localStorage.getItem("t"));
        let emailNotVerified = localStorage.getItem("email_notVerified_comprodonto");
        if (token && emailNotVerified) {
            let decoded = jwtDecode(token);
            self.user = decoded.usr;
            self.email_cadastrado = emailNotVerified;

            self.$http
                .get(`usuarios/${self.user.id}`)
                .then((res) => res.json())
                .then((response) => {
                    self.isLoading = false;
                })
                .catch((e) => {
                    self.isLoading = false;
                    if (e.body.message.includes("Token")) {
                       self.showAlert("Erro!", 'Acesso expirado ou inválido! Faça login novamente para continuar.');
                       localStorage.clear();
                       self.$router.replace('/login');
                    } else {
                       self.showAlert("Erro!", e.body.message);
                    }
                });
        } else {
            self.isLoading = false;
            self.showAlert(
                "Erro!",
                "Acesso expirado ou inválido! Faça login novamente para continuar."
            );
            localStorage.clear();
            self.$router.replace(self.$route.query.redirect || "/login");
        }
    }
};
</script>

<style scoped>

.botao-login {
    background-color: #00fff5;;
    border: none;
    color: #c70851;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 55px;
    margin-top: 1.5em;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-around;
}
.btn-changeEmail {
    background-color: transparent;;
    border: 2px solid #00fff5;
    color: #00fff5;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 55px;
    margin-top: 1.5em;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.formulario{
	position: relative;
	top: -80px;
}

.verificaEmail {
    background-color: #c70851;
    width: 100%;
    padding-top: 151px;
}

.height{
    height: 100vh;
}

.verificaEmail .container .formulario .container-logo {
	margin-bottom: 60px;
}

.verificaEmail .container .formulario {
	width: 352px;
    margin-left: auto;
    margin-right: auto;
}

.formulario .form-group {
	position: relative;
    margin-bottom: 28px;
}

.verificaEmail .container .formulario .form-group label {
    background-color: #c70851;
    color: #ff96db;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.formulario .form-group label {
    color: #4b4b4b;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    position: absolute;
    top: -9px;
    left: 15px;
    background-color: #fff;
    padding-right: 15px;
    padding-left: 10px;
}

.verificaEmail .container .formulario .form-group input {
    background-color: rgb(232, 240, 254) !important;
    border: 1px solid #ff96db;
    color: #000;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.btn-login {
  width: 100%;
  height: 55px;
  background-color: #00fff5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 5px;
  margin-top: 40px;
}

.formulario .form-group input {
    height: 42px;
    border: 1px solid #cecece;
    width: 100%;
    border-radius: 5px;
    padding-left: 15px;
    font-size: 12px;
    font-weight: 700;
    outline: none;
}

.verificaEmail .container .formulario a {
    color: #ffffff;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 700;
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

.texto, .texto-reenvio{
	font-size: 20px;
	color: #fff;
	text-align: justify;
	line-height: 1.1;
}

.texto-reenvio{
	margin: 1em 0 2em 0;
}

.texto span{
    display: block;
    margin: .5em 0;
    text-align: center;
	font-weight: 600;
}

@media (max-width: 480px) {
	.verificaEmail .container .formulario .container-logo img {
    width: 100%;
	}
}

@media (max-width: 480px) {
	.verificaEmail .container .formulario {
        width: 100%;
	}
}

</style>