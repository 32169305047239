<template>
  <div>
    <Navbar :night="scrolled"/>
    <div id="sobre">
      <section class="sobre">
        <div class="container">
          <div class="sobre-text">
            <h1>Olá nós somos a Comprodonto</h1>

            <div class="text">
              <p>A Comprodonto é um sistema exclusivo de venda de planos odontológicos da Cooperativa Uniodonto, o maior sistema deste segmento no Brasil.</p>
              <p>Comprar pela Comprodonto significa ter as melhores informações para contratar com conforto e segurança, o melhor serviço para você, sua família e sua empresa.</p>
              <br>
              <p>Sem carência e com cobertura bem mais ampla em relação a outros planos, a Uniodonto lidera o segmento de cooperativas odontológicas pelo número de usuários, pelo número de profissionais cooperados e pela diversidade dos planos oferecidos. São pacotes com soluções customizadas de acordo com suas necessidades. Tudo isso a um clique, com atendimento personalizado e garantia nos serviços prestados. A Comprodonto é a Uniodonto mais perto de você!</p>
            </div>
          </div>

          <div class="container-img-sobre">
            <img src="../assets/img/sobre/sobre-01.png" alt />
          </div>
        </div>
      </section>

      <section class="missao-empresa">
        <div class="container">
          <div class="visao text-empresa">
            <div class="text-img">
              <img src="../assets/img/sobre/sobre-02.png" alt />
              <h1>Missão</h1>
            </div>
            <p>Ser uma marketplae de planos odontologicos, oferecendo aos nossos usuários mais informações durante a escolha dos planos, buscando sempre o aperfeiçoamento e excelência no atendimento.</p>
          </div>

          <div class="missao text-empresa">
            <div class="text-img">
              <img src="../assets/img/sobre/sobre-03.png" alt />
              <h1>Visão</h1>
            </div>
            <p>Promover a saúde bucal por meio de propagação de informações, tratamento personalizado, segurança, tranquilidade e acima de tudo responsabilidade.</p>
          </div>

          <div class="valores text-empresa">
            <div class="text-img">
              <img src="../assets/img/sobre/sobre-04.png" alt />
              <h1>valores</h1>
            </div>
            <ul>
              <li>
                <p>– Respeito ao paciente;</p>
              </li>

              <li>
                <p>– Atendimento de qualidade;</p>
              </li>
              <li>
                <p>– Confiabilidade;</p>
              </li>
              <li>
                <p>– Excelência;</p>
              </li>
              <li>
                <p>– Modernidade;</p>
              </li>
              <li>
                <p>– Ética;</p>
              </li>
              <li>
                <p>– Transparência.</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
    <Footer />
  </div>
</template>
<script>
export default {
  name: "sobre",
  data() {
    return {
      scrolled: false,
      qtd: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted(){
      this.qtd = this.$store.getters.getCarrinho.length;
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 150) {
        this.scrolled = true;
      } else {
        this.scrolled = "";
      }
    }
  }
};
</script>